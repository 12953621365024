import type ITutorial from '@/components/content-app/interface/tutorials/ITutorial'
/**
 * Utilities for Tutorial components
 */
export default class TutorialUtilsService {
  /**
   * Returns class depending on tutorial difficulty level
   * @param level string
   * @returns string css class
   */
  getCssClassForLevel(level: string): string {
    switch (level) {
      case 'Beginner':
        return 'text-green-500'
      case 'Intermediate':
        return 'text-yellow-500'
      case 'Advanced':
        return 'text-red-500'
      default:
        return ''
    }
  }
  /**
   * Calculate total time of tutorials in a module
   * @param tutorialsArray array of ITutorial
   * @returns string time in minutes
   */
  getTotalTime(tutorialsArray: ITutorial[]): string {
    let totalMinutes = 0
    for (let i = 0; i < tutorialsArray.length; i++) {
      totalMinutes += tutorialsArray[i].attributes.MinutesToComplete
    }

    const hour = totalMinutes / 60 >= 1 ? Math.floor(totalMinutes / 60) : 0
    const minutes = totalMinutes - hour * 60

    let hrString = ''
    if (hour == 1) {
      hrString = `${hour} hr`
    } else if (hour > 1) {
      hrString = `${hour} hrs`
    }

    return `${hrString} ${minutes} min`
  }

  /**
   * Find the current tutorial's index
   * @param tutorialsInModule ITutorial[]
   * @param tutorialUrl Current tutorial's URL
   * @returns index number
   */
  getCurrentTutorialIndex = (
    tutorialsInModule: ITutorial[] | undefined,
    tutorialUrl: string | undefined
  ): number => {
    if (!tutorialsInModule || !tutorialUrl) return NaN

    const index = tutorialsInModule.findIndex(
      (tutorial: ITutorial) => tutorial.attributes.Url == tutorialUrl
    )

    if (index != undefined) return index

    return NaN
  }

  /**
   * Get data for next tutorial (if exists)
   * @param tutorialsInModule ITutorial[]
   * @param tutorialUrl Current tutorial's URL
   * @param currentTutorialIndex index of current tutorial
   * @returns ITutorial item or NULL
   */
  getNextTutorial = (
    tutorialsInModule: ITutorial[] | undefined,
    tutorialUrl: string | undefined,
    currentTutorialIndex: number
  ): ITutorial | null => {
    if (!tutorialsInModule || !tutorialUrl) return null

    if (currentTutorialIndex == tutorialsInModule?.length! - 1) return null

    return tutorialsInModule[currentTutorialIndex! + 1]!
  }

  /**
   * Get data for previous tutorial (if exists)
   * @param tutorialsInModule ITutorial[]
   * @param tutorialUrl Current tutorial's URL
   * @param currentTutorialIndex index of current tutorial
   * @returns ITutorial item or NULL
   */
  getPreviousTutorial = (
    tutorialsInModule: ITutorial[] | undefined,
    tutorialUrl: string | undefined,
    currentTutorialIndex: number
  ): ITutorial | null => {
    if (!tutorialsInModule || !tutorialUrl) return null

    if (currentTutorialIndex == 0) return null

    return tutorialsInModule[currentTutorialIndex! - 1]!
  }
}
