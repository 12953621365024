<script setup lang="ts">
import { type PropType, computed } from 'vue'
import type ILanguage from '@/components/content-app/interface/tutorials/ILanguage'

const props = defineProps({
  language: {
    type: Object as PropType<ILanguage>,
    required: true
  }
})

const language = computed(() => {
  return props.language
})
</script>

<template>
  <div class="col-span-1">
    <router-link
      :to="{ path: `tutorials/${language.attributes.Url}` }"
      class="section-tertiary group flex flex-col rounded-xl shadow-sm transition hover:shadow-md"
    >
      <div class="p-4 md:px-5 md:py-3">
        <div class="flex items-center justify-start">
          <img
            class="h-14 w-14 object-contain"
            :src="language.attributes.Thumbnail?.data.attributes.url"
            alt="Image Description"
          />
          <div class="ml-3">
            <span class="text-primary group-hover:text-brand mb-3 font-semibold">
              {{ language.attributes.Name }}
            </span>
            <!-- <p class="m-0 text-sm">{{ language.data.description }}</p> -->
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>
