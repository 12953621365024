import { onMounted, onUnmounted } from 'vue'

/**
 * A composable function to handle tooltip events globally.
 * @param tooltipSelector - The CSS selector for the tooltips.
 * @returns void
 */
export function useTooltipHandler(tooltipSelector: string) {
  /**
   * Handles the mouseover event on a tooltip.
   * @param event - The mouse event.
   */
  const handleMouseoverOnTooltip = (event: MouseEvent) => {
    const targetElement = event.target as HTMLElement
    const tooltipElement = targetElement?.closest?.(tooltipSelector) as HTMLElement

    if (tooltipElement) {
      const relatedTarget = event.relatedTarget as HTMLElement
      if (relatedTarget?.closest?.(tooltipSelector) === tooltipElement) return
      hideTooltip(tooltipElement)
    }
  }

  /**
   * Hides the tooltip.
   * @param tooltipElement - The tooltip element to hide.
   */
  const hideTooltip = (tooltipElement: HTMLElement) => {
    tooltipElement.classList.remove('show')
    tooltipElement.removeEventListener('mouseleave', handleMouseoverOnTooltip, true)
  }

  onMounted(() => {
    document.addEventListener('mouseleave', handleMouseoverOnTooltip, true)
  })

  onUnmounted(() => {
    document.removeEventListener('mouseleave', handleMouseoverOnTooltip, true)
  })

  return {
    handleMouseoverOnTooltip
  }
}
