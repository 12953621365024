<script setup lang="ts">
import type ITutorial from '@/components/content-app/interface/tutorials/ITutorial'
import TutorialUtilsService from '@/utils/content-app/tutorial.utils'
import { type PropType, reactive, type Ref, ref, watch, computed } from 'vue'
import DocSocialSharing from '@/components/content-app/docs/DocSocialSharing.vue'
import type ILanguage from '@/components/content-app/interface/tutorials/ILanguage'
const props = defineProps({
  currentLanguageData: {
    type: Object as PropType<ILanguage>,
    required: false
  },
  currentModuleUrl: {
    type: String,
    required: true
  },
  currentTutorialUrl: {
    type: String,
    required: true
  },
  tutorialsInModule: {
    type: Array as PropType<ITutorial[]>,
    required: true
  }
})

const _tutorialsUtilsService = new TutorialUtilsService()
const tutorialsInModule: Ref<ITutorial[] | undefined> = ref(props.tutorialsInModule)
const currentTutorialUrl: Ref<string | undefined> = ref(props.currentTutorialUrl)
const currentLanguage = computed(() => props.currentLanguageData)

const navigateTutorials = reactive({
  nextTutorial: null as null | ITutorial,
  currentTutorialIndex: NaN as number
})

watch(
  () => [props.tutorialsInModule, props.currentTutorialUrl],
  () => {
    tutorialsInModule.value = props.tutorialsInModule
    currentTutorialUrl.value = props.currentTutorialUrl

    //Current Tutorial
    navigateTutorials.currentTutorialIndex = _tutorialsUtilsService.getCurrentTutorialIndex(
      tutorialsInModule.value,
      currentTutorialUrl.value
    )

    //Determine Next Tutorial or Finished
    navigateTutorials.nextTutorial = _tutorialsUtilsService.getNextTutorial(
      tutorialsInModule.value,
      currentTutorialUrl.value,
      navigateTutorials.currentTutorialIndex
    )
  }
)
</script>

<template>
  <hr class="mb-8" />
  <!-- Show this footer if there is a Next -->
  <div class="mb-8 flex flex-col" v-if="navigateTutorials.nextTutorial">
    <h3 class="heading-small mb-2">
      Next Tutorial: {{ navigateTutorials.nextTutorial?.attributes.Title }}
    </h3>
    <span class="text-secondary mb-2 text-sm"
      >{{ navigateTutorials.nextTutorial?.attributes.MinutesToComplete }} Minutes</span
    >
    <RouterLink
      :to="`/tutorials/${currentLanguage?.attributes.Url}/${props.currentModuleUrl}/${navigateTutorials.nextTutorial?.attributes.Url}`"
      type="button"
      class="btn btn-primary mt-4 w-fit text-sm font-semibold"
    >
      Continue
    </RouterLink>
  </div>

  <!-- Show this footer is there is no Next -->
  <div class="mb-8 flex flex-col" v-if="!navigateTutorials.nextTutorial">
    <h3 class="heading-small mb-2">You've completed this module!</h3>
    <p class="text-md text-secondary mb-3">
      Continue learning via other JDoodle's professional
      {{ currentLanguage?.attributes.Name }} Tutorials!
    </p>
    <RouterLink
      :to="`/tutorials/${currentLanguage?.attributes.Url}/${props.currentModuleUrl}`"
      type="button"
      class="btn btn-primary mt-4 w-fit"
    >
      View Modules
    </RouterLink>
  </div>

  <div class="">
    <DocSocialSharing />
  </div>
</template>
