import type { ISubscriptionInitRes } from '@/components/shared/subscription/interface/ISubscriptionInitRes'
import type { TBillingCycle } from '@/components/shared/subscription/type/subscription.type'
import { useAuthStore } from '@/stores/auth.store'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { apiPlansData } from '../utils/sharedData/plans'

export const useSubscribeStore = defineStore('subscribe', () => {
  const isPaymentView = ref<boolean>(false)
  const redirectTo = ref('')
  const showSubscribeComponents = ref(false)

  const billingCycle = ref<TBillingCycle>('Monthly')

  const bundleInitData = ref(undefined as undefined | ISubscriptionInitRes)
  const apiInitData = ref(undefined as undefined | ISubscriptionInitRes)
  const showSubscribeOverlay = ref(false)

  const addonApiCredits = ref<boolean>(false)
  const apiCredits = ref<number>(0)

  const isFetchingSubscriptionInfo = ref<boolean>(false)

  const getMoreCreditsUpdated = ref<boolean>(false)
  const isMonthly = computed(() => {
    return billingCycle.value === 'Monthly'
  })

  const isYearly = computed(() => {
    return billingCycle.value === 'Yearly'
  })

  /**
   * Sets the Bundle subscription data using init response
   * @param response Resposne object from service
   */
  function initBundle(response: ISubscriptionInitRes) {
    bundleInitData.value = response
    useAuthStore().setIsPremium(response.plan || null)
  }

  /**
   *
   * @param url any
   */
  function setRedirectTo(url: any) {
    redirectTo.value = url
  }

  /**
   * Sets the API subscription data using init response
   * @param response Resposne object from service
   */
  function initApi(response: ISubscriptionInitRes) {
    apiInitData.value = response
  }

  /**
   * Set 'Free' as subscribed plan from FE
   */
  function setFakeSubscription() {
    if (apiInitData.value) {
      apiInitData.value.plan = apiPlansData[0].name
      apiInitData.value.credits = +apiPlansData[0].credits
      apiInitData.value.fake = true
    }
  }

  /**
   * Sets the showSubscribeComponents value to true
   */
  function SubscribeOverlayFalse() {
    showSubscribeOverlay.value = false
  }

  /**
   * Sets the showSubscribeComponents value to true
   * @param val boolean
   */
  function setIsFetchingSubscriptionInfo(val: boolean) {
    isFetchingSubscriptionInfo.value = val
  }

  /**
   * Sets the showSubscribeComponents value to true
   * @param value boolean
   */
  const setMoreCreditsUpdated = (value: boolean) => {
    getMoreCreditsUpdated.value = value
  }

  /**
   * @param value set billing cycle
   */
  const setBillingCycle = (value: TBillingCycle) => {
    billingCycle.value = value
  }

  /**
   * @param value set billing cycle
   */
  const setApiCredits = (value: number) => {
    apiCredits.value = value
  }

  /**
   * @param value add API credits toggle
   */
  const setAddonApiCredits = (value: boolean) => {
    addonApiCredits.value = value
  }

  /**
   * @param value to set current view
   */
  const setIsPaymentView = (value: boolean) => {
    isPaymentView.value = value
  }
  return {
    redirectTo,
    setRedirectTo,
    showSubscribeComponents,
    bundleInitData,
    apiInitData,
    initBundle,
    initApi,
    showSubscribeOverlay,
    SubscribeOverlayFalse,
    isFetchingSubscriptionInfo,
    setIsFetchingSubscriptionInfo,
    getMoreCreditsUpdated,
    setMoreCreditsUpdated,
    isPaymentView,
    setIsPaymentView,
    isMonthly,
    isYearly,
    setBillingCycle,
    setApiCredits,
    apiCredits,
    addonApiCredits,
    setAddonApiCredits,
    setFakeSubscription
  }
})
