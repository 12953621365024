import { USER_TYPE } from '@/components/organisation/enum/user.enums'
import type IAssociation from '@/components/organisation/interface/IAssociation'
import type IAssociationType from '@/components/organisation/interface/IAssociationType'
import { IAssociationCode } from '@/components/organisation/interface/IAssociationType'
import type { IAssociationAndInstituteOwnResponse } from '@/components/organisation/interface/IAssociationsAndInstituteOwn'
import type IInstitute from '@/components/organisation/interface/IInstitute'
import type IInstituteOwn from '@/components/organisation/interface/IInstituteOwn'
import { type IInstituteSubscriptionInfo } from '@/components/organisation/interface/IInstituteSubscriptionInfo'
import type { IInvitations } from '@/components/organisation/interface/IInvitations'
import type { IUser } from '@/components/organisation/interface/IUser'
import { UNLIMITED_LIMIT } from '@/utils/sharedData/plans'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useOrganisationStore = defineStore('organisation', () => {
  //Errors
  const orgErrorMessage = ref<string | null>(null)
  const errorMessage = computed(() => orgErrorMessage.value)

  // ProfileAtLogin
  const personalAccountId = ref<number | null>(null)
  const userId = ref<number | null>(null)
  const associations = ref<IAssociation[] | null>(null)
  const instituteOwn = ref<IInstituteOwn | null>(null)
  const guruInitialised = ref<boolean>(false)
  const currentOrgCode = ref<string | null>(null)
  const previousOrgCode = ref<string | null>(null)
  const currentOrgName = ref<string | null>('')
  const previousOrgName = ref<string | null>('')
  const currentAssociation = ref<IInstitute | undefined>(undefined)
  const currentAssociationFull = ref<IAssociation | undefined>(undefined)

  //Teams
  const isUserAdded_toStudentGroup_toAssessment_toCourse = ref<boolean>(false)

  //Permissions
  const activeAccountId = ref<number | null>(null)
  const activeAccountRoles = ref<IAssociationType[] | null>(null)
  const instituteSubscriptionInfo = ref<IInstituteSubscriptionInfo | null>(null)
  const organisationUsers = ref<IUser[] | null>(null)
  const userInvitations = ref<IInvitations[] | null>(null)
  const refetchAddedUsers = ref<boolean>(false)

  //users
  const currentUserStatus = ref<string>(USER_TYPE.ADD_USER)

  // ====== Roles ======
  const isIndividualAccount = computed(() => {
    return activeAccountRoles.value?.some(
      (asso: IAssociationType) => asso.code == IAssociationCode.INDIVIDUAL_ACCOUNT
    )
  })

  // === Org Roles ===
  const isOrganisationOwner = computed(() => {
    return activeAccountRoles.value?.some(
      (asso: IAssociationType) => asso.code == IAssociationCode.ORGANISATION_OWNER
    )
  })

  const isOrganisationAdmin = computed(() => {
    return activeAccountRoles.value?.some(
      (asso: IAssociationType) => asso.code == IAssociationCode.ORGANISATION_ADMIN
    )
  })

  // ==== API Roles ====
  const isApiAdmin = computed(() => {
    return activeAccountRoles.value?.some(
      (asso: IAssociationType) => asso.code == IAssociationCode.API_ADMIN
    )
  })

  const isApiViewer = computed(() => {
    return activeAccountRoles.value?.some(
      (asso: IAssociationType) => asso.code == IAssociationCode.API_VIEWER
    )
  })

  // ==== Embed Roles ====
  const isEmbedAdmin = computed(() => {
    return activeAccountRoles.value?.some(
      (asso: IAssociationType) => asso.code == IAssociationCode.EMBED_ADMIN
    )
  })

  const isEmbedViewer = computed(() => {
    return activeAccountRoles.value?.some(
      (asso: IAssociationType) => asso.code == IAssociationCode.EMBED_VIEWER
    )
  })

  // ==== Assessment Roles ====
  const isInstituteAdmin = computed(() => {
    return activeAccountRoles.value?.some(
      (asso: IAssociationType) => asso.code == IAssociationCode.INSTITUTE_ADMIN
    )
  })

  const isTeacher = computed(() => {
    return activeAccountRoles.value?.some(
      (asso: IAssociationType) => asso.code == IAssociationCode.TEACHER
    )
  })

  const isStudent = computed(() => {
    return activeAccountRoles.value?.some(
      (asso: IAssociationType) => asso.code == IAssociationCode.STUDENT
    )
  })

  const isAssociated = computed(() => {
    return isTeacher.value || isStudent.value
  })

  // old logic for editing user. Don't delete yet as still in use
  const highestAssessmentRole = computed<IAssociationCode>(() => {
    if (
      currentAssociationFull.value?.associationTypes.some(
        (asso: IAssociationType) => asso.code == IAssociationCode.ORGANISATION_OWNER
      )
    ) {
      return IAssociationCode.ORGANISATION_OWNER
    } else if (
      currentAssociationFull.value?.associationTypes.some(
        (asso: IAssociationType) => asso.code == IAssociationCode.INSTITUTE_ADMIN
      )
    ) {
      return IAssociationCode.INSTITUTE_ADMIN
    } else if (
      currentAssociationFull.value?.associationTypes.some(
        (asso: IAssociationType) => asso.code == IAssociationCode.TEACHER
      )
    ) {
      return IAssociationCode.TEACHER
    }

    return IAssociationCode.STUDENT
  })

  const isOwner = computed(() => {
    return isOrganisationOwner.value || isOrganisationAdmin.value || isIndividualAccount.value
  })

  // ==== IDE Roles ====

  const isFreeIde = computed(() => {
    return activeAccountRoles.value?.some(
      (asso: IAssociationType) => asso.code == IAssociationCode.FREE_IDE
    )
  })

  const isPremiumIde = computed(() => {
    return activeAccountRoles.value?.some(
      (asso: IAssociationType) => asso.code == IAssociationCode.PREMIUM_IDE
    )
  })

  // === Subscription Limits ===
  const studentUsersLimitReached = computed(() => {
    if (organisationUsers.value && instituteSubscriptionInfo.value) {
      if (instituteSubscriptionInfo.value.limit.users.STUDENT == null) return false

      const assessmentStudents = organisationUsers.value.filter((user: IUser) =>
        user.roles.some((asso: IAssociationType) => asso.code == IAssociationCode.STUDENT)
      )
      //Count teachers
      if (assessmentStudents) {
        if (instituteSubscriptionInfo.value?.limit.users.STUDENT === UNLIMITED_LIMIT) return false
        return assessmentStudents.length >= instituteSubscriptionInfo.value?.limit.users.STUDENT
      }
      return false
    }
    return false
  })

  const teacherUsersLimitReached = computed(() => {
    if (organisationUsers.value && instituteSubscriptionInfo.value) {
      if (instituteSubscriptionInfo.value.limit.users.TEACHER == null) return false

      const assessmentTeachers = organisationUsers.value.filter((user: IUser) =>
        user.roles.some((asso: IAssociationType) => asso.code == IAssociationCode.TEACHER)
      )
      //Count teachers
      if (assessmentTeachers) {
        if (instituteSubscriptionInfo.value?.limit.users.TEACHER === UNLIMITED_LIMIT) return false
        return assessmentTeachers.length >= instituteSubscriptionInfo.value?.limit.users.TEACHER
      }
      return false
    }
    return false
  })

  const adminUsersLimitReached = computed(() => {
    if (organisationUsers.value && instituteSubscriptionInfo.value) {
      if (instituteSubscriptionInfo.value.limit.users.INSTITUTE_ADMIN == null) return false

      const assessmentAdmins = organisationUsers.value.filter((user: IUser) =>
        user.roles.some((asso: IAssociationType) => asso.code == IAssociationCode.INSTITUTE_ADMIN)
      )
      //Count teachers
      if (assessmentAdmins) {
        if (instituteSubscriptionInfo.value?.limit.users.INSTITUTE_ADMIN === UNLIMITED_LIMIT)
          return false
        return (
          assessmentAdmins.length >= instituteSubscriptionInfo.value?.limit.users.INSTITUTE_ADMIN
        )
      }
      return false
    }
    return false
  })

  const apiAdminLimitReached = computed(() => {
    if (organisationUsers.value && instituteSubscriptionInfo.value) {
      if (instituteSubscriptionInfo.value.limit.users.API_ADMIN == null) return false

      const apiAdmins = organisationUsers.value.filter((user: IUser) =>
        user.roles.some((asso: IAssociationType) => asso.code == IAssociationCode.API_ADMIN)
      )
      //Count teachers
      if (apiAdmins) {
        if (instituteSubscriptionInfo.value?.limit.users.API_ADMIN === UNLIMITED_LIMIT) return false
        return apiAdmins.length >= instituteSubscriptionInfo.value?.limit.users.API_ADMIN
      }
      return false
    }
    return false
  })

  const apiViewerLimitReached = computed(() => {
    if (organisationUsers.value && instituteSubscriptionInfo.value) {
      if (instituteSubscriptionInfo.value.limit.users.API_VIEWER == null) return false

      const apiViewer = organisationUsers.value.filter((user: IUser) =>
        user.roles.some((asso: IAssociationType) => asso.code == IAssociationCode.API_VIEWER)
      )
      //Count teachers
      if (apiViewer) {
        if (instituteSubscriptionInfo.value?.limit.users.API_VIEWER === UNLIMITED_LIMIT)
          return false
        return apiViewer.length >= instituteSubscriptionInfo.value?.limit.users.API_VIEWER
      }
      return false
    }
    return false
  })

  const embedAdminLimitReached = computed(() => {
    if (organisationUsers.value && instituteSubscriptionInfo.value) {
      if (instituteSubscriptionInfo.value.limit.users.EMBED_ADMIN == null) return false

      const embedAdmin = organisationUsers.value.filter((user: IUser) =>
        user.roles.some((asso: IAssociationType) => asso.code == IAssociationCode.EMBED_ADMIN)
      )
      //Count teachers
      if (embedAdmin) {
        if (instituteSubscriptionInfo.value?.limit.users.EMBED_ADMIN === UNLIMITED_LIMIT)
          return false
        return embedAdmin.length >= instituteSubscriptionInfo.value?.limit.users.EMBED_ADMIN
      }
      return false
    }
    return false
  })

  const embedViewerLimitReached = computed(() => {
    if (organisationUsers.value && instituteSubscriptionInfo.value) {
      if (instituteSubscriptionInfo.value.limit.users.EMBED_VIEWER == null) return false

      const embedViewers = organisationUsers.value.filter((user: IUser) =>
        user.roles.some((asso: IAssociationType) => asso.code == IAssociationCode.EMBED_VIEWER)
      )
      //Count teachers
      if (embedViewers) {
        if (instituteSubscriptionInfo.value?.limit.users.EMBED_VIEWER === UNLIMITED_LIMIT)
          return false
        return embedViewers.length >= instituteSubscriptionInfo.value?.limit.users.EMBED_VIEWER
      }
      return false
    }
    return false
  })

  const freeIdeLimitReached = computed(() => {
    if (organisationUsers.value && instituteSubscriptionInfo.value) {
      if (instituteSubscriptionInfo.value.limit.users.FREE_IDE == null) return false

      const freeIdeUsers = organisationUsers.value.filter((user: IUser) =>
        user.roles.some((asso: IAssociationType) => asso.code == IAssociationCode.FREE_IDE)
      )
      //Count teachers
      if (freeIdeUsers) {
        if (instituteSubscriptionInfo.value?.limit.users.FREE_IDE === UNLIMITED_LIMIT) return false
        return freeIdeUsers.length >= instituteSubscriptionInfo.value?.limit.users.FREE_IDE
      }
      return false
    }
    return false
  })

  const premiumIdeLimitReached = computed(() => {
    if (organisationUsers.value && instituteSubscriptionInfo.value) {
      if (instituteSubscriptionInfo.value.limit.users.PREMIUM_IDE == null) return false

      const premiumIdeUsers = organisationUsers.value.filter((user: IUser) =>
        user.roles.some((asso: IAssociationType) => asso.code == IAssociationCode.PREMIUM_IDE)
      )
      //Count teachers
      if (premiumIdeUsers) {
        if (instituteSubscriptionInfo.value?.limit.users.PREMIUM_IDE === UNLIMITED_LIMIT)
          return false
        return premiumIdeUsers.length >= instituteSubscriptionInfo.value?.limit.users.PREMIUM_IDE
      }
      return false
    }
    return false
  })

  const totalUserCount = computed(() => {
    return organisationUsers.value?.length
  })

  const studentLimitMessage = computed(() => {
    if (instituteSubscriptionInfo.value?.limit.users.STUDENT != UNLIMITED_LIMIT) {
      return `You have reached the organisation's plan limit of ${instituteSubscriptionInfo.value?.limit.users.STUDENT} student users`
    } else {
      return ''
    }
  })

  const teacherLimitMessage = computed(() => {
    if (instituteSubscriptionInfo.value?.limit.users.TEACHER != UNLIMITED_LIMIT) {
      return `You have reached the organisation's plan limit of ${instituteSubscriptionInfo.value?.limit.users.TEACHER} teacher users`
    } else {
      return ''
    }
  })

  const adminLimitMessage = computed(() => {
    if (instituteSubscriptionInfo.value?.limit.users.INSTITUTE_ADMIN != UNLIMITED_LIMIT) {
      return `You have reached the organisation's plan limit of ${instituteSubscriptionInfo.value?.limit.users.INSTITUTE_ADMIN} admin user(s)`
    } else {
      return ''
    }
  })

  const apiAdminLimitMessage = computed(() => {
    if (instituteSubscriptionInfo.value?.limit.users.API_ADMIN != UNLIMITED_LIMIT) {
      return `You have reached the organisation's plan limit of ${instituteSubscriptionInfo.value?.limit.users.API_ADMIN} API admin user(s)`
    } else {
      return ''
    }
  })

  // Teams
  const editingUser = ref<null | IUser>(null)

  /**
   * Set stored subscription info from service
   * @param subscriptionInfo IInstituteSubscriptionInfo Object
   */
  function setSubscriptionInfo(subscriptionInfo: IInstituteSubscriptionInfo) {
    instituteSubscriptionInfo.value = subscriptionInfo
  }

  /**
   * Set stored users to compute limitations
   * @param users array of IUsers
   */
  function setUsers(users: IUser[]) {
    organisationUsers.value = users
  }

  /**
   * Sets current orgCode
   * @param orgCode String code or Null
   */
  const setOrgCode = (orgCode: string | null) => {
    if (currentOrgCode.value != null) {
      previousOrgCode.value = currentOrgCode.value
    }

    currentOrgCode.value = orgCode
  }

  /**
   * Sets active account Id
   * @param accountId number id
   */
  const setActiveAccountId = (accountId: number | null) => {
    activeAccountId.value = accountId
  }

  /**
   * Sets permissions after calling setActiveAccount endpoint
   * @param permissionResponse IPermission[]
   */
  const setactiveAccountRoles = (permissionResponse: IAssociationType[]) => {
    activeAccountRoles.value = permissionResponse
  }

  /**
   * Search associations and find current one
   * @param insCode Institute Code of association
   */
  const getAndSetCurrentAssociation = (insCode: string | null | undefined) => {
    if (guruInitialised.value && insCode) {
      const foundAsso = associations.value?.find(
        (association: IAssociation) => association.institute.code == insCode
      )
      currentAssociation.value = foundAsso?.institute
      currentAssociationFull.value = foundAsso
    } else {
      currentAssociation.value = undefined
    }
  }
  /**
   * Filter Associations and Ins Own
   *
   */
  const filterAssociationInstituteOwn = () => {
    const filteredAssociation: IAssociation[] = associations.value?.filter(
      (association: IAssociation) => {
        return association.accountId !== instituteOwn.value?.accountId
      }
    ) as IAssociation[]
    associations.value = filteredAssociation as IAssociation[]
    setInstituteOwn(null)
  }
  /**
   * Set Institute Own
   * @param res IInstituteOwn response object
   */
  const setInstituteOwn = (res: IInstituteOwn | null) => {
    instituteOwn.value = res || null
  }
  /**
   * Set Associations and Ins Own
   * @param res IAssociationAndInstituteOwn response object
   */
  const setAssociationAndInstituteOwn = (res: IAssociationAndInstituteOwnResponse) => {
    personalAccountId.value = res.accountId
    userId.value = res.userId

    if (res.associations) {
      associations.value = res.associations
    }

    if (res.instituteOwn) {
      setInstituteOwn(res.instituteOwn)
    }

    guruInitialised.value = true
  }

  /**
   * Sets error to display in Org
   * @param message IInstituteSubscriptionInfo Object
   */
  function setErrorResponse(message: string | null) {
    orgErrorMessage.value = message
  }

  /**
   * Sets current orgName
   * @param orgName String name or Null
   */
  const setOrgName = (orgName: string | null) => {
    previousOrgName.value = currentOrgName.value
    currentOrgName.value = orgName
  }
  /**
   * Sets the user invitations.
   * @param users - An array of user invitations.
   */
  const setUserInvitations = (users: IInvitations[]) => {
    userInvitations.value = users
  }
  /**
   * toggle refetchAddUsers to update the page with latest added user data
   * @param value  boolean state value
   */
  const setRefetchAddUsers = (value: boolean) => {
    refetchAddedUsers.value = value
  }

  /**
   * toggle add user type
   * @param value  add user value
   */
  const setCurrentUserStatus = (value: string) => {
    currentUserStatus.value = value
  }

  return {
    personalAccountId,
    userId,
    activeAccountId,
    setActiveAccountId,
    activeAccountRoles,
    setactiveAccountRoles,
    currentOrgCode,
    setOrgCode,
    editingUser,
    organisationUsers,
    associations,
    instituteOwn,
    guruInitialised,
    currentAssociation,
    currentAssociationFull,
    getAndSetCurrentAssociation,
    filterAssociationInstituteOwn,
    setAssociationAndInstituteOwn,
    isIndividualAccount,
    isOrganisationOwner,
    isOrganisationAdmin,
    isApiAdmin,
    isApiViewer,
    isEmbedAdmin,
    isEmbedViewer,
    isInstituteAdmin,
    isTeacher,
    isStudent,
    highestAssessmentRole,
    isOwner,
    isFreeIde,
    isPremiumIde,
    instituteSubscriptionInfo,
    setSubscriptionInfo,
    studentLimitMessage,
    teacherLimitMessage,
    adminLimitMessage,
    apiAdminLimitMessage,
    setUsers,
    studentUsersLimitReached,
    teacherUsersLimitReached,
    adminUsersLimitReached,
    apiAdminLimitReached,
    apiViewerLimitReached,
    embedAdminLimitReached,
    embedViewerLimitReached,
    freeIdeLimitReached,
    premiumIdeLimitReached,
    totalUserCount,
    isUserAdded_toStudentGroup_toAssessment_toCourse,
    setErrorResponse,
    errorMessage,
    previousOrgCode,
    previousOrgName,
    currentOrgName,
    setOrgName,
    userInvitations,
    setUserInvitations,
    refetchAddedUsers,
    setRefetchAddUsers,
    isAssociated,
    currentUserStatus,
    setCurrentUserStatus
  }
})
