<script setup lang="ts">
import type { ITutorialExerciseBanner } from '@/components/content-app/interface/tutorials/ITutorialExerciseBanner'
import TutorialsService from '@/services/content-app/tutorials.service'
import { onMounted, ref } from 'vue'

const _tutorialsService = new TutorialsService()

const bannerData = ref(null as null | ITutorialExerciseBanner)

onMounted(async () => {
  const data = await _tutorialsService.getTutorialExerciseBanner()
  // console.log('Tutorial Banner', data)
  bannerData.value = data
})
</script>

<template>
  <div
    class="body-max-width bg-gradient-to-r from-orange-500 via-orange-600 to-red-600 px-4 pb-12 pt-8"
  >
    <div class="text-white">
      <h2 class="heading-small mb-2">{{ bannerData?.attributes.Title }}</h2>

      <span class="mb-6 block">{{ bannerData?.attributes.Content }}</span>
      <a
        :href="bannerData?.attributes.ButtonLink"
        class="text-md rounded-full border border-white bg-black bg-transparent px-4 py-2 text-white hover:bg-white hover:text-red-500"
        >{{ bannerData?.attributes.ButtonText }}</a
      >
    </div>
  </div>
</template>
