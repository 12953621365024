export enum ACTION_SECTION {
  ASSESSMENTS = 'ASSESSMENTS'
}

export enum ORG_TYPE {
  BUSINESS = 'Business',
  SOFTWARE_DEVELOPMENT = 'Software Development',
  ACADEMIC = 'Academic',
  RECRUITMENT = 'Recruitment'
}

export enum ORGANISATION_ACTION {
  ADD = 'add',
  EDIT = 'edit',
  VIEW = 'view' //Not used?
}

export enum INVITE_RESPONSE {
  ACCEPT = 'accept',
  REJECT = 'reject'
}

export enum ORG_OR_ASSESSMENT_USER_ACTION {
  ADD = 'add',
  REMOVE = 'remove'
}

export enum ORG_SECTION {
  ASSESSMENTS = 'ASSESSMENTS',
  COURSES = 'COURSES',
  EXERCISES = 'EXERCISES'
}
