/* eslint-disable jsdoc/require-jsdoc */
import { META } from '@/utils/meta'

// import DocCategoryPage from '@/components/content-app/docs/DocCategoryPage.vue'
// import DocPage from '@/components/content-app/docs/DocPage/DocPage.vue'
// import DocsHome from '@/components/content-app/docs/DocsHome.vue'
// import DocsView from '@/views/DocsView.vue'
import { type RouteRecordRaw } from 'vue-router'

import LanguagePage from '@/components/content-app/tutorials/LanguagePage/LanguagePage.vue'
import ModulePage from '@/components/content-app/tutorials/ModulePage/ModulePage.vue'
import TutorialExercisePageVue from '@/components/content-app/tutorials/TutorialExercisePage/TutorialExercisePage.vue'
import TutorialsHome from '@/components/content-app/tutorials/TutorialsHome/TutorialsHome.vue'
// import TutorialsViewOld from '@/views/TutorialsView old.vue'
import TutorialsView from '@/views/TutorialsView.vue'

const contentAppRoutes: Array<RouteRecordRaw> = [
  // {
  //   path: '/docs',
  //   name: 'docs',
  //   component: DocsView,
  //   meta: META.default,
  //   children: [
  //     {
  //       path: '',
  //       name: 'docs',
  //       component: DocsHome
  //     },
  //     {
  //       path: ':categoryUrl',
  //       name: 'docs-category',
  //       component: DocCategoryPage
  //     },
  //     {
  //       path: ':categoryUrl/:docUrl',
  //       name: 'doc-page',
  //       component: DocPage
  //     }
  //   ]
  // },
  {
    path: '/tutorials',
    name: 'tutorials',
    component: TutorialsView,
    // component: TutorialsViewOld, //ComingSoon
    meta: META.tutorials,
    children: [
      {
        path: '',
        name: 'tutorials',
        component: TutorialsHome
      },
      {
        path: ':language',
        name: 'language-page',
        component: LanguagePage
      },
      {
        path: ':languageUrl/:moduleUrl',
        name: 'module-page',
        component: ModulePage
      },
      {
        path: ':languageUrl/:moduleUrl/:tutorialUrl',
        name: 'tutorial-page',
        component: TutorialExercisePageVue
      }
    ]
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('@/views/blog/BlogsView.vue'),
    meta: META.blogs,
    children: [
      {
        path: '',
        name: 'blog-home',
        component: () => import('@/views/blog/BlogsHomeView.vue')
      },
      {
        path: ':blogId',
        name: 'blog-item',
        component: () => import('@/views/blog/BlogItemView.vue')
      },
      {
        path: 'category/:categoryId',
        name: 'blog-category',
        component: () => import('@/views/blog/BlogCategoryView.vue')
      }
    ]
  },
  {
    path: '/docs',
    name: 'documentation',
    component: () => import('@/views/documentation/DocumentationView.vue'),
    meta: META.docs,
    children: [
      {
        path: '',
        name: 'documentation',
        component: () => import('@/views/documentation/DocumentationHomeView.vue')
      },
      {
        path: ':category/:doc',
        name: 'documentation-category-doc',
        component: () => import('@/views/documentation/DocumentationCategoryDocView.vue')
      },
      {
        path: ':category/:doc/:subCategory',
        name: 'documentation-subcategory-doc',
        component: () => import('@/views/documentation/DocumentationSubDocView.vue')
      }
    ]
  },
  {
    path: '/webinar',
    name: 'webinar',
    component: () => import('@/views/webinar/WebinarView.vue'),
    meta: META.webinar,
    children: [
      {
        path: '',
        name: 'webinar',
        component: () => import('@/views/webinar/WebinarHomeView.vue')
      },
      {
        path: ':item',
        name: 'webinar-item',
        component: () => import('@/views/webinar/WebinarItemView.vue')
      }
    ]
  }
]

export default contentAppRoutes
