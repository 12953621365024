import '@/assets/index.css'
import '@/utils/icons'
if (typeof window !== 'undefined') {
  window.addEventListener('load', () => {
    import('preline')
  })
  if (!window.$hsDropdownCollection) window.$hsDropdownCollection = []
}

import resetStore from '@/stores/plugin/reset.plugin'
import { createPinia } from 'pinia'
import { ViteSSG } from 'vite-ssg'

import App from '@/App.vue'
import { allRoute } from '@/router/index'

import gtmService from '@/services/gtm.service'
import markerIoService from '@/services/marker.io.service'
import posthogService from '@/services/posthog.service'
import routerService from '@/services/router.service'
import ssgService from '@/services/ssg.service'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueLazyLoad from 'vue3-lazyload'

export const createApp = ViteSSG(App, { routes: allRoute }, async ({ app, router }) => {
  const pinia = createPinia()
  pinia.use(resetStore)
  app.use(pinia)

  app.component('FontAwesomeIcon', FontAwesomeIcon)
  app.use(VueLazyLoad, {})

  if (process.env.NODE_ENV !== 'development' && !import.meta.env.SSR) {
    gtmService.initGtm()
    app.use(posthogService.posthogPlugin)
    markerIoService.loadMarkerConfig()
  }
  if (import.meta.env.SSR) {
    await ssgService.initSSG()
  } else {
    routerService.routerLogic(router)
  }
})
/**
 * Get the routes that are included in the paths
 * @returns the routes that are included in the paths
 */
export const includedRoutes = async () => {
  return await ssgService.getRoutesLinks()
}
