import axios, { HttpStatusCode, type AxiosResponse } from 'axios'

import { Question } from '@/components/organisation/class/QuestionObject'
import { ASSESSMENT_SUBMISSION_STATUS } from '@/components/organisation/enum/assessment.enums'
import { INVITE_RESPONSE } from '@/components/organisation/enum/organisation.enums'
import { USER_ACTIVATION, USER_STATUS, USER_TYPE } from '@/components/organisation/enum/user.enums'
import type IAssignmentDetailed from '@/components/organisation/interface/IAssignmentDetailed'
import type IAssignmentMini from '@/components/organisation/interface/IAssignmentMini'
import type { IAssignmentOfStudent } from '@/components/organisation/interface/IAssignmentOfStudent'
import type IAssociation from '@/components/organisation/interface/IAssociation'
import {
  IAccountType,
  IAssociationCode,
  type IGetAssociationsAndInstituteOwnResponse
} from '@/components/organisation/interface/IAssociationType'
import type { IAssociationAndInstituteOwnResponse } from '@/components/organisation/interface/IAssociationsAndInstituteOwn'
import type { IEvaluationData } from '@/components/organisation/interface/IEvaluationData'
import type IInstituteOwn from '@/components/organisation/interface/IInstituteOwn'
import type { IInstituteSubscriptionInfo } from '@/components/organisation/interface/IInstituteSubscriptionInfo'
import type { IQuestionType } from '@/components/organisation/interface/IQuestionType'
import type { IQuestion_ForAssignment } from '@/components/organisation/interface/IQuestion_ForAssignment'
import type { IStudentGroup } from '@/components/organisation/interface/IStudentGroup'
import type { ISubmission_Question } from '@/components/organisation/interface/ISubmission_Question'
import type { IUser } from '@/components/organisation/interface/IUser'
import type {
  IActivateAssignment_Request,
  IAddUserRequest,
  IAssignment_Users_AddRemoveRequest,
  ICreateAssignmentRequest,
  IMultipleChoiceQuestion_Request,
  IOpenEndedQuestion_Request,
  IProgramQuestion_Request,
  IQuestionRequest_ForAssignment,
  IQuestionTypesAll,
  IRegisterInstituteRequest,
  ISaveAnswer_Request,
  ISaveEvaluation_Request,
  IStudentGroup_AddRemoveRequest,
  IStudentGroup_Request,
  ITrueOrFalseQuestion_Request
} from '@/components/organisation/interface/requests.interface'
import { useOrganisationStore } from '@/stores/organisation.store'

// ====== Institute ======

/**
 * @param registerRequest The register request object
 * @returns The response object
 */
const registerInstitute = async (registerRequest: IRegisterInstituteRequest) => {
  return await axios
    .post('/api/guru-api/subscribe', registerRequest)
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

/**
 * Init and get teach data
 * @returns data object with associations and instituteOwn
 */
const getAssociationsAndInstituteOwn = async (): Promise<IAssociationAndInstituteOwnResponse> => {
  return await axios
    .post('/api/guru-api/init')
    .then((response: IGetAssociationsAndInstituteOwnResponse) => {
      const accountID = response.data.accountId
      const ownerFirstName = response.data.owner.firstName || ''
      const ownerLastName = response.data.owner.lastName || ''

      useOrganisationStore().setActiveAccountId(accountID)

      const data: IAssociationAndInstituteOwnResponse = {
        accountId: accountID, //personal account number
        userId: response.data.userId,
        associations: [],
        instituteOwn: null
      }

      if (response.data.type === IAccountType.OWNER) {
        const instituteOwn: IInstituteOwn = {
          accountId: accountID,
          name: `${ownerFirstName} ${ownerLastName}`,
          code: ownerFirstName,
          description: `${ownerFirstName} ${ownerLastName}'s team`,
          accountLimit: 5000,
          firstName: ownerFirstName,
          lastName: ownerLastName
        }

        data.instituteOwn = instituteOwn
      }
      const associations: IAssociation = {
        accountId: accountID,
        associationTypes: response?.data?.associationTypes,
        institute: {
          name: `${ownerFirstName} ${ownerLastName}`,
          code: ownerFirstName,
          description: `${ownerFirstName} ${ownerLastName}'s team`
        },
        status: USER_STATUS.ACTIVE,
        invitationCode: ''
      }
      data.associations.push(associations)
      return data
    })
}

/**
 * Fetches and sets user invitations.
 */
const getUserInvitation = async () => {
  await axios.post('/api/guru-api/init').then((response: any) => {
    useOrganisationStore().setUserInvitations(response.data?.invitations)
  })
}

/**
 * Init and get teach data
 * @returns data object with associations and instituteOwn
 */
const getSubscriptionInfo = async (): Promise<IInstituteSubscriptionInfo> => {
  return await axios.post('/api/guru-api/subscriptionInfo').then((response: any) => {
    return response.data
  })
}
/**
 * @param instituteCode any
 * @returns institute data
 */
const getInstituteData = async (instituteCode: string) => {
  return await axios
    .post('/api/guru-api/instituteDetailsForOwner', { instituteCode: instituteCode })
    .then((response) => {
      if (response.data.instituteOwn.code) return response.data.instituteOwn

      //Empty array
      return null
    })
    .catch((error) => {
      throw error
    })
}

/**
 * @param instituteCode institute code
 * @param userFilter enum for users to filter for
 * @returns array of users
 */
const getUsersInInstitute = async (
  instituteCode: string,
  userFilter: USER_TYPE
): Promise<IUser[]> => {
  return await axios
    .post('/api/guru-api/users', { instituteCode: instituteCode, filter: userFilter })
    .then((response) => {
      return response.data.users
    })
    .catch((error) => {
      throw error
    })
}

/**
 * @returns status
 * @param requestObject any
 */
const addOrRemoveUserFromAssignment = async (
  requestObject: IAssignment_Users_AddRemoveRequest
): Promise<HttpStatusCode> => {
  return await axios
    .post('/api/guru-api/assignment/updateAssignmentParticipants', requestObject)
    .then((response) => {
      return response.status
    })
    .catch((error) => {
      throw error
    })
}

/**
 * @param invitationToken token of the invite
 * @returns http response
 */
const checkInvitationToken = async (invitationToken: string): Promise<any> => {
  const reqObject = {
    token: invitationToken
  }

  return await axios
    .post('/api/guru-api/checkInvitation', reqObject)
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

/**
 * Respond to an invitation
 * @param response Enum for response
 * @param token A valid invitation token
 * @returns institute data
 */
const respondToInvite = async (response: INVITE_RESPONSE, token: string): Promise<any> => {
  const reqObject = {
    response: response,
    token: token
  }

  return await axios
    .post('/api/guru-api/responseToInvite', reqObject)
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

// /**
//  * Retrieve subscription info for each individual institute association
//  * @param insCode institute code
//  * @returns IInstituteSubscriptionInfo object
//  */
// const getInstituteSubscriptionInfo = async (
//   insCode: string
// ): Promise<IInstituteSubscriptionInfo> => {
//   return await axios
//     .post('/api/guru-api/subscriptionInfo', { instituteCode: insCode })
//     .then((response) => {
//       return response.data
//     })
//     .catch((error) => {
//       throw error
//     })
// }

// ====== Assignments ======

/**
 * @param instituteCode The institute code from URL param
 * @returns array of assignments
 */
const getAssignmentsOfStudent = async (instituteCode: string): Promise<IAssignmentOfStudent[]> => {
  return await axios
    .post('/api/guru-api/assignment/assignmentsOfStudent', { instituteCode: instituteCode })
    .then((response) => {
      return response.data.assignments
    })
    .catch((err) => {
      throw err
    })
}

/**
 * @param instituteCode institute code
 * @returns Array of assignments
 */
const getAssignments = async (instituteCode: string): Promise<IAssignmentMini[]> => {
  return await axios
    .post('/api/guru-api/assignment/assignments', {
      instituteCode: instituteCode
    })
    .then((response: any) => {
      return response.data
    })
    .catch((err: any) => {
      throw err
    })
}

/**
 * @param instituteCode instituteCode
 * @param assignmentId target assignment
 * @returns Assignment object
 */
const getOneAssignment = async (
  instituteCode: string,
  assignmentId: number
): Promise<IAssignmentDetailed> => {
  return await axios
    .post('/api/guru-api/assignment/getAssignment', {
      instituteCode: instituteCode,
      assignmentId: assignmentId
    })
    .then((response: any) => {
      return response.data.assignment
    })
    .catch((err: any) => {
      throw err
    })
}

/**
 * @param requestData Form data
 * @returns HttpStatusCode
 */
const createAssignment = async (requestData: ICreateAssignmentRequest): Promise<any> => {
  return await axios
    .post('/api/guru-api/assignment/addAssignment', requestData)
    .then((response: any) => {
      return response
    })
    .catch((err: any) => {
      throw err
    })
}

/**
 * @param requestData Form data
 * @returns HttpStatusCode
 */
const cloneAssignment = async (requestData: ICreateAssignmentRequest): Promise<any> => {
  return await axios
    .post('/api/guru-api/assignment/cloneAssignment', requestData)
    .then((response: any) => {
      return response
    })
    .catch((err: any) => {
      throw err
    })
}

/**
 * @param requestData Form data
 * @returns HttpStatusCode
 */
const editAssignment = async (requestData: ICreateAssignmentRequest): Promise<any> => {
  return await axios
    .post('/api/guru-api/assignment/editAssignment', requestData)
    .then((response: any) => {
      return response
    })
    .catch((err: any) => {
      throw err
    })
}

/**
 * @param instituteCode instituteCode
 * @param assignmentId target assignment
 * @returns HttpStatusCode
 */
const deleteAssignment = async (
  instituteCode: string,
  assignmentId: number
): Promise<HttpStatusCode> => {
  return await axios
    .post('/api/guru-api/assignment/deleteAssignment', {
      instituteCode: instituteCode,
      assignmentId: assignmentId
    })
    .then((response: any) => {
      return response.status
    })
    .catch((err: any) => {
      throw err
    })
}

/**
 * @param requestObject IActivateAssignment request object
 * @returns HttpStatusCode
 */
const activateAssignment = async (
  requestObject: IActivateAssignment_Request
): Promise<HttpStatusCode> => {
  return await axios
    .post('/api/guru-api/assignment/activateAssignment', requestObject)
    .then((response: any) => {
      return response.status
    })
    .catch((err: any) => {
      throw err
    })
}

/**
 * @param instituteCode instituteCode
 * @param assignmentId target assignment
 * @returns HttpStatusCode
 */
const onHoldAssignment = async (
  instituteCode: string,
  assignmentId: number
): Promise<HttpStatusCode> => {
  return await axios
    .post('/api/guru-api/assignment/onHoldAssignment', {
      instituteCode: instituteCode,
      assignmentId: assignmentId
    })
    .then((response: any) => {
      return response.status
    })
    .catch((err: any) => {
      throw err
    })
}

/**
 * @param instituteCode instituteCode
 * @param assignmentId target assignment
 * @param studentEmail email of student
 * @returns Assignment Object
 */
const getAssignmentToAttend = async (
  instituteCode: string,
  assignmentId: number,
  studentEmail: string | undefined
): Promise<IAssignmentDetailed> => {
  return await axios
    .post('/api/guru-api/assignment/getAssignmentToAttend', {
      instituteCode: instituteCode,
      assignmentId: assignmentId,
      studentEmail: studentEmail
    })
    .then((response: any) => {
      return response.data.assignment
    })
    .catch((err: any) => {
      throw err
    })
}

/**
 * @param instituteCode instituteCode
 * @param assignmentId target assignment
 * @param studentEmail email of student
 * @returns Assignment Object
 */
const getAssignmentToPreview = async (
  instituteCode: string,
  assignmentId: number,
  studentEmail: string | undefined
): Promise<IAssignmentDetailed> => {
  return await axios
    .post('/api/guru-api/assignment/getAssignment', {
      instituteCode: instituteCode,
      assignmentId: assignmentId,
      studentEmail: studentEmail
    })
    .then((response: any) => {
      return response.data.assignment
    })
    .catch((err: any) => {
      throw err
    })
}

/**
 * @param instituteCode instituteCode
 * @param assignmentId target assignment
 * @param studentEmail email of student
 * @returns Assignment Object
 */
const getAssignmentSubmissionsForEvaluation = async (
  instituteCode: string,
  assignmentId: number,
  studentEmail: string | undefined
): Promise<IEvaluationData> => {
  return await axios
    .post('/api/guru-api/assignment/getSubmissions', {
      instituteCode: instituteCode,
      assignmentId: assignmentId,
      studentEmail: studentEmail
    })
    .then((response: any) => {
      return response.data
    })
    .catch((err: any) => {
      throw err
    })
}

/**
 * @param instituteCode instituteCode
 * @param assignmentId target assignment
 * @returns response
 */
const submitAssignment = async (instituteCode: string, assignmentId: number): Promise<any> => {
  return await axios
    .post('/api/guru-api/assignment/submitAssignment', {
      instituteCode: instituteCode,
      assignmentId: assignmentId
    })
    .then((response: any) => {
      return response
    })
    .catch((err: any) => {
      throw err
    })
}

/**
 * @param requestObj Request object ISaveAnswer_Request
 * @returns Assignment Object
 */
const saveAnswer = async (requestObj: ISaveAnswer_Request): Promise<AxiosResponse> => {
  return await axios
    .post('/api/guru-api/assignment/saveAnswer', requestObj)
    .then((response: any) => {
      return response
    })
    .catch((err: any) => {
      throw err
    })
}

/**
 * @param requestObj Request object ISaveAnswer_Request
 * @returns Empty Object
 */
const saveAnswerAndPreviewResponses = async (
  requestObj: ISaveAnswer_Request
): Promise<ISubmission_Question[]> => {
  return await axios
    .post('/api/guru-api/assignment/saveAnswerAndPreviewResponses', requestObj)
    .then((response: any) => {
      return response.data.submission
    })
    .catch((err: any) => {
      throw err
    })
}

/**
 * @param instituteCode instituteCode
 * @param assignmentId target assignment
 * @returns response
 */
const getAssignmentResponses = async (
  instituteCode: string,
  assignmentId: number
): Promise<ISubmission_Question[]> => {
  return await axios
    .post('/api/guru-api/assignment/previewResponses', {
      instituteCode: instituteCode,
      assignmentId: assignmentId
    })
    .then((response: any) => {
      return response.data.submission
    })
    .catch((err: any) => {
      throw err
    })
}

/**
 * @param requestObj Request object ISaveEvaluation_Request
 * @returns Assignment Object
 */
const saveEvaluation = async (requestObj: ISaveEvaluation_Request): Promise<any> => {
  return await axios
    .post('/api/guru-api/assignment/saveEvaluation', requestObj)
    .then((response: any) => {
      return response
    })
    .catch((err: any) => {
      throw err
    })
}

/**
 * @param instituteCode instituteCode
 * @param assignmentId target assignment
 * @param studentEmail Student Email
 * @returns Assignment Object
 */
const markAssignmentAsEvaluated = async (
  instituteCode: string,
  assignmentId: number,
  studentEmail: string | undefined
): Promise<any> => {
  return await axios
    .post('/api/guru-api/assignment/markAsEvaluated', {
      instituteCode: instituteCode,
      assignmentId: assignmentId,
      studentEmail: studentEmail
    })
    .then((response: any) => {
      return response
    })
    .catch((err: any) => {
      throw err
    })
}

/**
 * @param instituteCode instituteCode
 * @param assignmentId target assignment
 * @param studentEmail Student Email
 * @param reason Status of assignment
 * @returns Assignment Object
 */
const reopenAssignment = async (
  instituteCode: string,
  assignmentId: number,
  studentEmail: string | undefined,
  reason: string
): Promise<any> => {
  return await axios
    .post('/api/guru-api/assignment/reOpen', {
      instituteCode: instituteCode,
      assignmentId: assignmentId,
      studentEmail: studentEmail,
      reason: reason
    })
    .then((response: any) => {
      return response
    })
    .catch((err: any) => {
      throw err
    })
}

/**
 * @param instituteCode instituteCode
 * @param assignmentId target assignment
 * @param studentEmail Student Email
 * @returns Response Object
 */
const autoEvaluateAgain = async (
  instituteCode: string,
  assignmentId: number,
  studentEmail: string | undefined
): Promise<any> => {
  return await axios
    .post('/api/guru-api/assignment/autoEvaluateAgain', {
      instituteCode: instituteCode,
      assignmentId: assignmentId,
      studentEmail: studentEmail
    })
    .then((response: any) => {
      return response
    })
    .catch((err: any) => {
      throw err
    })
}

/**
 * @param instituteCode instituteCode
 * @param assignmentId target assignment
 * @returns Response Object
 */
const requestReevaluation = async (instituteCode: string, assignmentId: number): Promise<any> => {
  return await axios
    .post('/api/guru-api/assignment/requestRevaluation', {
      instituteCode: instituteCode,
      assignmentId: assignmentId
    })
    .then((response: any) => {
      return response
    })
    .catch((err: any) => {
      throw err
    })
}

/**
 * @param instituteCode instituteCode
 * @param assignmentId target assignment
 * @param studentEmail Student Email
 * @returns Response Object
 */
const discloseResult = async (
  instituteCode: string,
  assignmentId: number,
  studentEmail: string | undefined
): Promise<any> => {
  return await axios
    .post('/api/guru-api/assignment/discloseResult', {
      instituteCode: instituteCode,
      assignmentId: assignmentId,
      studentEmail: studentEmail,
      reason: ASSESSMENT_SUBMISSION_STATUS.SUBMITTED
    })
    .then((response: any) => {
      return response
    })
    .catch((err: any) => {
      throw err
    })
}

// ====== Questions ======

/**
 * @param formData Form data of different types
 * @param questionTypeObj object of selected question type
 * @param assignmentId assignment id
 * @param instituteCode instituteCode
 * @returns HttpStatus
 */
const postQuestion = async (
  formData:
    | IOpenEndedQuestion_Request
    | ITrueOrFalseQuestion_Request
    | IProgramQuestion_Request
    | IMultipleChoiceQuestion_Request,
  questionTypeObj: IQuestionType,
  assignmentId: number,
  instituteCode: string
) => {
  const requestObject = new Question(formData, questionTypeObj)

  const requestData = {
    instituteCode: instituteCode,
    assignmentId: assignmentId,
    requestJson: requestObject
  }

  return await axios
    .post('/api/guru-api/assignment/addQuestion', requestData)
    .then((response: any) => {
      return response.status
    })
    .catch((err: any) => {
      throw err
    })
}

/**
 * @param formData Array of form data of all question types
 * @param assignmentId assignment id
 * @param instituteCode instituteCode
 * @returns HttpStatus
 */
const postBulkQuestion = async (
  formData: IQuestionTypesAll[],
  assignmentId: number,
  instituteCode: string
) => {
  const requestData = {
    instituteCode: instituteCode,
    assignmentId: assignmentId,
    requestJsons: formData
  }

  return await axios
    .post('/api/guru-api/assignment/batchAddQuestion', requestData)
    .then((response: any) => {
      return response.status
    })
    .catch((err: any) => {
      throw err
    })
}

/**
 * @param questionId Id
 * @param assignmentId Assignment Id
 * @param instituteCode Code of Institute
 * @returns HTTP status
 */
const deleteQuestion = async (questionId: number, assignmentId: number, instituteCode: string) => {
  const requestData = {
    instituteCode: instituteCode,
    assignmentId: assignmentId,
    questionId: questionId
  }

  return await axios
    .post('/api/guru-api/assignment/deleteQuestion', requestData)
    .then((response: any) => {
      return response.status
    })
    .catch((err: any) => {
      throw err
    })
}

/**
 * @param positions Array of Question Ids
 * @param assignmentId Assignment Id
 * @param instituteCode Code of Institute
 * @returns HTTP status
 */
const reorderQuestions = async (
  positions: number[],
  assignmentId: number,
  instituteCode: string
) => {
  const requestData = {
    instituteCode: instituteCode,
    assignmentId: assignmentId,
    positions: positions
  }

  return await axios
    .post('/api/guru-api/assignment/reOrderQuestions', requestData)
    .then((response: any) => {
      return response.status
    })
    .catch((err: any) => {
      throw err
    })
}

/**
 * @param questionId Id
 * @param instituteCode Code of Institute
 * @param assignmentId Assignment Id
 * @returns HTTP status
 */
const getQuestion = async (questionId: number, instituteCode: any, assignmentId: number) => {
  const requestData = {
    questionId: questionId,
    instituteCode: instituteCode,
    assignmentId: assignmentId
  }

  return await axios
    .post('/api/guru-api/assignment/getQuestion', requestData)
    .then((response: any) => {
      return response.data.question
    })
    .catch((err: any) => {
      throw err
    })
}

/**
 * @param requestObject Request of type IQuestionRequest_ForAssignment
 * @returns HTTP status
 */
const getQuestionForAssignment = async (
  requestObject: IQuestionRequest_ForAssignment
): Promise<IQuestion_ForAssignment> => {
  return await axios
    .post('/api/guru-api/assignment/getQuestion', requestObject)
    .then((response: any) => {
      return response.data.question
    })
    .catch((err: any) => {
      throw err
    })
}

/**
 * @param assignmentId Id of an assignment
 * @returns HTTP status
 */
const postAssessmentStarted = async (assignmentId: number): Promise<void> => {
  return await axios.post(`/api/guru-api/assignment/${assignmentId}/start`)
}

// === Student Groups ===

/**
 * @param instituteCode Institute Code
 * @returns Array of student groups
 */
const getStudentGroups = async (instituteCode: string): Promise<IStudentGroup[]> => {
  return await axios
    .post('/api/guru-api/studentGroups', { instituteCode: instituteCode })
    .then((response) => {
      return response.data.studentGroups
    })
    .catch((error) => {
      throw error
    })
}

/**
 * @param instituteCode Institute Code
 * @param insStudentGroupId Id
 * @returns One student group
 */
const getOneStudentGroup = async (
  instituteCode: string,
  insStudentGroupId: number
): Promise<IStudentGroup> => {
  return await axios
    .post('/api/guru-api/getStudentGroup', {
      instituteCode: instituteCode,
      insStudentGroupId: insStudentGroupId
    })
    .then((response) => {
      return response.data.sg
    })
    .catch((error) => {
      throw error
    })
}

/**
 * @param studentGroupRequestObj Request object
 * @returns Http status
 */
const addStudentGroup = async (studentGroupRequestObj: IStudentGroup_Request): Promise<any> => {
  return await axios
    .post('/api/guru-api/addStudentGroup', studentGroupRequestObj)
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

/**
 * @param instituteCode Institute Code
 * @param insStudentGroupId Id
 * @returns One student group
 */
const deleteStudentGroup = async (
  instituteCode: string,
  insStudentGroupId: number
): Promise<HttpStatusCode> => {
  return await axios
    .post('/api/guru-api/deleteStudentGroup', {
      instituteCode: instituteCode,
      insStudentGroupId: insStudentGroupId
    })
    .then((response) => {
      return response.status
    })
    .catch((error) => {
      throw error
    })
}

/**
 * @param requestObject AddOrRemove request object
 * @returns HttpStatusCode
 */
const addOrRemoveUserFromStudentGroup = async (
  requestObject: IStudentGroup_AddRemoveRequest
): Promise<HttpStatusCode> => {
  return await axios
    .post('/api/guru-api/addStudentGroup', requestObject)
    .then((response) => {
      return response.status
    })
    .catch((error) => {
      throw error
    })
}

// === User Management ===

/**
 *
 * @param instituteCode institute code
 * @returns list of users associated with the institute
 */
const getUsers = async (instituteCode: string): Promise<IUser[]> => {
  return await axios
    .post('/api/guru-api/users', {
      instituteCode: instituteCode
    })
    .then((response: any) => {
      response.data.users = response.data.users.map((user: IUser) => ({
        ...user,
        roles: user.roles.map((role) => {
          if (role.code === IAssociationCode.ORGANISATION_OWNER) {
            return { ...role, name: 'Team Owner' }
          }
          if (role.code === IAssociationCode.ORGANISATION_ADMIN) {
            return { ...role, name: 'Team Admin' }
          }
          return role
        })
      }))

      return response.data.users
    })
    .catch((err: any) => {
      throw err
    })
}

/**
 *
 * @param instituteCode institute Code
 * @param email email of the user
 * @returns single user with respective emailId and instutute Code
 */
const getUser = async (instituteCode: string, email: string): Promise<IUser[]> => {
  return await axios
    .post('/api/guru-api/getUser', {
      instituteCode: instituteCode,
      emailId: email
    })
    .then((response: any) => {
      return response.data.users
    })
    .catch((err: any) => {
      throw err
    })
}

// === Users ===

/**
 *
 * @param requestData IAddUser type of details of the user to be added
 * @returns the same user object
 */
const addUser = async (requestData: IAddUserRequest) => {
  return await axios
    .post('/api/guru-api/addUser', requestData)
    .then((response: any) => {
      return response
    })
    .catch((err: any) => {
      throw err
    })
}

/**
 *
 * @param instituteCode institute code from which the user will be deleted
 * @param email of that user who will be deleted
 * @returns status
 */
const deleteUser = async (instituteCode: string, email: string | undefined) => {
  return await axios
    .post('/api/guru-api/deleteUser', {
      instituteCode: instituteCode,
      emailId: email
    })
    .then((response: any) => {
      return response.status
    })
    .catch((err: any) => {
      throw err
    })
}

/**
 *
 * @param instituteCode instituteCode
 * @param email of the user
 * @param mode activate or deactivate
 * @returns status
 */
const changeUserActivation = async (
  instituteCode: string,
  email: string | undefined,
  mode: USER_ACTIVATION
) => {
  return await axios
    .post('/api/guru-api/changeActivation', {
      instituteCode: instituteCode,
      emailId: email,
      mode: mode
    })
    .then((response: any) => {
      return response
    })
    .catch((err: any) => {
      throw err
    })
}

/**
 * called when user select create own account in invite modal
 * @returns status
 */
const createOwnAccount = async () => {
  return await axios.post('/api/account/initOrgAccount')
}

export {
  getAssociationsAndInstituteOwn,
  getSubscriptionInfo,
  registerInstitute,
  getUsersInInstitute,
  getInstituteData,
  checkInvitationToken,
  respondToInvite,
  // getInstituteSubscriptionInfo,
  //assignments
  addOrRemoveUserFromAssignment,
  getAssignmentsOfStudent,
  getAssignments,
  getOneAssignment,
  getAssignmentToAttend,
  getAssignmentToPreview,
  getAssignmentSubmissionsForEvaluation,
  submitAssignment,
  getUsers,
  getUser,
  createAssignment,
  editAssignment,
  cloneAssignment,
  deleteAssignment,
  activateAssignment,
  onHoldAssignment,
  saveAnswer,
  saveAnswerAndPreviewResponses,
  getAssignmentResponses,
  saveEvaluation,
  markAssignmentAsEvaluated,
  reopenAssignment,
  autoEvaluateAgain,
  requestReevaluation,
  discloseResult,
  postQuestion,
  deleteQuestion,
  reorderQuestions,
  getQuestion,
  getQuestionForAssignment,
  getStudentGroups,
  getOneStudentGroup,
  addStudentGroup,
  deleteStudentGroup,
  addOrRemoveUserFromStudentGroup,
  addUser,
  deleteUser,
  changeUserActivation,
  postAssessmentStarted,
  postBulkQuestion,
  getUserInvitation,
  createOwnAccount
}
