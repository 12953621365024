import jdroidService from '@/services/ide/jdroid.service'
import { fetchStreamData } from '@/services/jdroid.stream.service'
import supbaseService, { type IJDAnswer } from '@/services/supbase.service'
import { useAuthStore } from '@/stores/auth.store'
import { useJdroidStore } from '@/stores/jdroid.store'
import {
  AIType,
  ChatStatus,
  CHECKCANCELCHATERRORVAL,
  type IChat,
  type ICredit,
  type ISetHistory
} from '@/utils/jDroid'
import { UNLIMITED_LIMIT } from '@/utils/sharedData/plans'
import axios from 'axios'

export interface IExecuteChatWithAi {
  inputs?: string | undefined
  ide_content?: string | undefined
  outputs?: string | undefined
  language?: string | undefined
  task: AIType
  parameters?: { temperature?: number; max_new_tokens?: number; stream?: boolean }
  history?: Array<[string, string]>
}

export interface IChatHistory {
  history: Array<IChatQuestion>
  chatId: string
}

export interface IChatQuestion {
  aiPayload: string
  answer: string
}
interface ISetHistoryRequest {
  chatId: string
  history: ISetHistory[]
}

let data = [] as IJDAnswer[]
let totalCount: number = 0

/**
 * @description initialize the SSG
 */
const initSSG = async () => {
  if (data.length == 0) {
    totalCount = (await supbaseService.getCount()).count
    while (data.length <= totalCount) {
      try {
        // eslint-disable-next-line no-console
        console.log(`Fetching data from supbase for SSG ${data.length} / ${totalCount}`)
        const jdroidAllQuestionArray = (
          await supbaseService.getQuestionsAndAnswers(data.length, totalCount)
        ).data
        data = data.concat(jdroidAllQuestionArray || [])
        if (data.length >= totalCount) {
          // eslint-disable-next-line no-console
          console.log(`Fetching data from supbase for SSG ${data.length} / ${totalCount}`)
          break
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching data from supbase for SSG', error)
        break
      }
    }
  }
  useJdroidStore().setSSGJdroidAllQuestion(data)
}
/**
 * @description get the routes links
 * @returns The array of routes links
 */
const getRoutesLinks = async (): Promise<string[]> => {
  const jDroidAnswerArray: string[] = []
  for (let i = 1; i <= totalCount / 10; i++) {
    jDroidAnswerArray.push(`/jdroid-answers/${i}`)
  }

  return jDroidAnswerArray
}
/**
 * Get one user's all chats
 * @returns all chats for the account
 */
const getChatsByAccountApi = async () => {
  return await axios
    .get('/api/ai-chat/byAccount')
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      throw error
    })
}

/**
 * Get a chat by id
 * @param chatId chat id
 * @returns chat details
 */
const getChatByIdApi = async (chatId: string) => {
  return await axios
    .get(`/api/ai-chat/${chatId}`)
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      throw error
    })
}

/**
 * Update a chat by id
 * @param chatId chat id
 * @param name chat name
 * @returns updated chat
 */
const updateChatByIdApi = async (chatId: string, name: string) => {
  return await axios
    .put(`/api/ai-chat/${chatId}`, {
      chatName: name
    })
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      throw error
    })
}

/**
 * Delete a chat by id
 * @param chatId chat id
 * @returns deletion response
 */
const deleteChatByIdApi = async (chatId: string) => {
  return await axios
    .delete(`/api/ai-chat/${chatId}`)
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      throw error
    })
}

/**
 * set chat history
 * @param abortController The controller
 * @param chatId The chat id
 * @param lastFiveHistory The last five history
 * @returns The chat id
 */
const setChatHistory = async (chatId: string, lastFiveHistory: ISetHistory[]): Promise<string> => {
  const setHisoryPayload: ISetHistoryRequest = {
    chatId: chatId,
    history: lastFiveHistory
  }
  return await axios
    .post('/api/ai-chat/question', setHisoryPayload)
    .then(() => {
      return chatId as string
    })
    .catch((error) => {
      throw error
    })
}
/**
 * Update a chat
 * @param requestObj Object
 * @returns created chat
 */
const createChatApi = async (requestObj: Object) => {
  return await axios
    .post('/api/ai-chat', requestObj)
    .then(({ data }) => {
      const id: string = (data.id as string) || ''
      const lastFiveHistory = useJdroidStore().getLastFiveChatAiPayloadHistory()
      if (lastFiveHistory.length === 0) {
        return data
      }
      return setChatHistory(id, lastFiveHistory)
        .then(() => {
          return data
        })
        .catch((error) => {
          throw error
        })
    })
    .catch((error) => {
      throw error
    })
}

/**
 * Add question to a chat
 * @param requestObj chat question
 * @returns response
 */
const addQuestionToChat = async (requestObj: IChatHistory) => {
  return await axios
    .post('/api/ai-chat/question', requestObj)
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      throw error
    })
}

/**
 * Assign a task
 * @returns assigned response
 */
const assignTaskApi = async () => {
  return await axios
    .post('/api/ai-chat/assign')
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      throw error
    })
}

/**
 * Delete a question
 * @param questionId number
 * @returns deletion response
 */
const deleteQuestionApi = async (questionId: number) => {
  return await axios
    .delete(`/api/ai-chat/question/${questionId}`)
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      throw error
    })
}

/**
 * Clear a chat
 * @param chatId string
 * @returns clear response
 */
const clearChatApi = async (chatId: string) => {
  return await axios
    .delete(`/api/ai-chat/clear/${chatId}`)
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      throw error
    })
}

/**
 * add chat
 * @param chatId chat id
 * @param chat IChat
 */
const addChat = async (chatId: string, chat: IChat) => {
  useJdroidStore().setShowJdroid(true)
  useJdroidStore().addChat(chat)
  await new Promise((resolve) => setTimeout(resolve, 100))
  await sendQuestionWithAiStream(chatId)
}

/**
 * Check whether a string needs to be parsed
 * @param str string
 * @returns boolean
 */
const isJSON = (str: string): boolean => {
  try {
    JSON.parse(str)
    return true
  } catch (e) {
    return false
  }
}

/**
 * Generate title with ai
 * @param chatId chat id
 * @param chat chat
 * @returns generated name
 */
const generateTitleWithAI = async (chatId: string, chat: IChat) => {
  const payload: IExecuteChatWithAi = {
    inputs: chat.prompt,
    task: AIType.GENERATETIITLE
  }

  return await axios
    .post('/api/compiler-ai/generate-code-with-session-stream', payload)
    .then(async ({ data }) => {
      if (isJSON(data)) {
        await updateChatByIdApi(chatId, JSON.parse(data))
        return JSON.parse(data)
      }
      const cleanedData = data.replace(/\n/g, '')
      await updateChatByIdApi(chatId, cleanedData)
      return cleanedData
    })
    .catch((error) => {
      throw error
    })
}

/**
 * Send a question with ai - stream 🌊
 * @param chatId chat id
 */
const sendQuestionWithAiStream = async (chatId: string) => {
  const lastChat = useJdroidStore().lastChat as IChat
  const controller = new AbortController()

  const payload = {
    chatId: chatId,
    aiPayload: JSON.stringify({
      inputs: lastChat.prompt,
      task: lastChat.type,
      parameters: { stream: true }
    })
  }
  try {
    const credit = await jdroidService.getCredits(controller, lastChat)
    const currentCredit: ICredit = credit
    if (currentCredit.quota - currentCredit.used <= 0 && currentCredit.quota !== UNLIMITED_LIMIT) {
      lastChat.status = ChatStatus.NOCREDIT
    } else {
      await fetchStreamData('/api/ai-chat/sendQuestion', 'POST', payload, false)
      currentCredit.used += 1
      useJdroidStore().setCredits(currentCredit)
      lastChat.credit = currentCredit
    }
  } catch (e) {
    const error = e as { response: { status: number } }
    if (error?.response?.status === 403) {
      useAuthStore().clearRobotCheck()
    } else if (error?.response?.status === 429) {
      lastChat.status = ChatStatus.NOCREDIT
      useJdroidStore().setCredits({
        quota: useJdroidStore().quota,
        used: useJdroidStore().quota
      })
    } else {
      const err: any = e
      if (!err.includes(CHECKCANCELCHATERRORVAL)) lastChat.status = ChatStatus.ERROR
    }
  }
}

/**
 * Generate answer when the user is not logged in
 * @param chat chat
 */
const generateAnswerForUnlogged = async (chat: IChat) => {
  useJdroidStore().setShowJdroid(true)
  useJdroidStore().addChat(chat)
  await new Promise((resolve) => setTimeout(resolve, 100))

  const lastChat = useJdroidStore().lastChat as IChat
  const controller = new AbortController()

  const payload: IExecuteChatWithAi = {
    inputs: lastChat.prompt,
    ide_content: lastChat.ideContent as string,
    task: lastChat.type,
    parameters: { stream: true },
    history: useJdroidStore().getLastFiveFormattedChat()
  }
  try {
    const credit = await jdroidService.getCredits(controller, lastChat)
    const currentCredit: ICredit = credit
    if (currentCredit.quota - currentCredit.used <= 0 && currentCredit.quota !== UNLIMITED_LIMIT) {
      lastChat.status = ChatStatus.NOCREDIT
    } else {
      await fetchStreamData(
        '/api/compiler-ai/generate-code-with-session-stream',
        'POST',
        payload,
        true
      )
      currentCredit.used += 1
      useJdroidStore().setCredits(currentCredit)
      lastChat.credit = currentCredit
    }
  } catch (e) {
    const error = e as { response: { status: number } }
    if (error?.response?.status === 403) {
      useAuthStore().clearRobotCheck()
    } else if (error?.response?.status === 429) {
      lastChat.status = ChatStatus.NOCREDIT
      useJdroidStore().setCredits({
        quota: useJdroidStore().quota,
        used: useJdroidStore().quota
      })
    } else {
      const err: any = e
      if (!err.includes(CHECKCANCELCHATERRORVAL)) lastChat.status = ChatStatus.ERROR
    }
  }
}

export default {
  initSSG,
  getRoutesLinks,
  getChatsByAccountApi,
  getChatByIdApi,
  updateChatByIdApi,
  deleteChatByIdApi,
  createChatApi,
  addQuestionToChat,
  assignTaskApi,
  clearChatApi,
  deleteQuestionApi,
  addChat,
  generateTitleWithAI,
  generateAnswerForUnlogged
}
