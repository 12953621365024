import { useAuthStore } from '@/stores/auth.store'
import { useJdroidStore } from '@/stores/jdroid.store'
import { ChatStatus, type IChat } from '@/utils/jDroid'

let controller: AbortController | null = null

/**
 * Fetch data through stream
 * @param url endpoint url
 * @param method endpoint method
 * @param payload endpoint payload
 * @param savePayload save payload in last chat
 */
export async function fetchStreamData(
  url: string,
  method: string,
  payload: any,
  savePayload: boolean = false
) {
  const lastChat = useJdroidStore().lastChat as IChat
  controller = new AbortController()

  const response = await fetch(url, {
    method: method,
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      'Kurukku-Kuri': useAuthStore().kurukkuKuri || ''
    },
    signal: controller?.signal
  })

  if (!response.body) {
    throw new Error('ReadableStream not supported in this browser.')
  }

  const reader = response.body.getReader()

  const run = true
  while (run) {
    const { done, value } = await reader.read()
    if (done) {
      if (savePayload) {
        console.log('payload', payload)
        lastChat.aiPayload = JSON.stringify(payload)
      }
      lastChat.status = ChatStatus.SUCCESS
      break
    }

    /* Decoded Chunk Data response */
    const text = new TextDecoder('utf-8').decode(value, { stream: true })
    /* Slicing null character from the end ( '\u0000' is null character ) */
    const trimmed = text.endsWith('\u0000') ? text.slice(0, -1) : text.trim()
    /* Spiting from null character */
    const responses = trimmed.split('\u0000')
    /* Picking last as best response */
    const bestResponse = responses[responses.length - 1]

    lastChat.status = ChatStatus.STREAMING

    try {
      lastChat.response = bestResponse
    } catch (error) {
      lastChat.status = ChatStatus.STREAMING
    }
  }
}

/**
 * Stop the jdroid chat and interrupt the request
 */
export const stopJdroidChat = () => {
  const lastChat = useJdroidStore().lastChat as IChat

  if (controller) {
    controller.abort()
    controller = null

    lastChat.status = ChatStatus.CANCLED
    const currentCredit = useJdroidStore().used + 1
    const credit = {
      quota: useJdroidStore().quota,
      used: currentCredit
    }
    useJdroidStore().setCredits(credit)
    lastChat.credit = credit
  }
}
