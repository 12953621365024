declare global {
  interface Window {
    ace: any
    MSStream: any
  }
  interface ace {
    Editor: any
  }
}
import loadScriptInBody from '@/services/loadScriptInBody.service'
import { ADVANCEDIDETYPE, IDECONSTANT, IDERELETIVEHEIGHT } from '@/utils/ide'

import aiService from '@/services/ide/ai.service'
import executeService from '@/services/ide/execute.service'
import ideService from '@/services/ide/ide.service'
import jdroidService from '@/services/ide/jdroid.service'
import projectTreeService from '@/services/ide/projectTree.service'
import wsService from '@/services/ide/ws.service'
import { useIdeStore, type THtmlExecutionHistory } from '@/stores/ide.store'
import { useJdroidStore } from '@/stores/jdroid.store'
import { usePluginStore } from '@/stores/plugin.store'
import { useProjectManager } from '@/stores/projectManager.store'

let heightChangeFunctionList: string[] = []

/**
 * get editor
 * @param key - the key of the editor
 * @returns the editor
 */
const getEditor = (key: (typeof IDECONSTANT)[keyof typeof IDECONSTANT]) => {
  if (!window.ace) return null
  return window.ace.edit(key)
}
/**
 * focus the editor
 * @param key - the key of the editor
 */
const forcusEditor = (key: (typeof IDECONSTANT)[keyof typeof IDECONSTANT]) => {
  getEditor(key).focus()
}
/**
 * get the editor session
 * @param key - the key of the editor
 * @returns the editor session
 */
const getEditorSession = (key: (typeof IDECONSTANT)[keyof typeof IDECONSTANT]) => {
  return getEditor(key).getSession()
}
/**
 * set the editor value
 * @param key - the key of the editor
 * @param value - the value to set
 */
const setEditorSession = (key: (typeof IDECONSTANT)[keyof typeof IDECONSTANT], value: string) => {
  const element = document.getElementById(key)
  if (element) getEditor(key).getSession().setValue(value)
  if (key === IDECONSTANT.CODE_EDITOR || key === IDECONSTANT.OUTPUT_EDITOR) {
    if (key === IDECONSTANT.CODE_EDITOR && useIdeStore().isAdvanced) {
      projectTreeService.setCodeChanged()
    }
    if (key === IDECONSTANT.OUTPUT_EDITOR) {
      saveOutputEditorHistory(value)
    }
    if (element) heightChangeFunction(key)
  }
}
/**
 * post ace editors
 * @param key - the key of the editor
 * @param count - the count
 * @returns true or false
 */
const postAceEditorsElement = async (
  key: (typeof IDECONSTANT)[keyof typeof IDECONSTANT],
  count: number = 0
): Promise<boolean> => {
  const element = document.getElementById(key)
  if (!element) {
    if (count > 10) {
      return false
    } else {
      await new Promise((resolve) => setTimeout(resolve, 100))
      return postAceEditorsElement(key, count + 1)
    }
  } else {
    return true
  }
}
/**
 * insert the value to the editor
 * @param key - the key of the editor
 * @param value - the value to insert
 */
const insertEditorSession = (
  key: (typeof IDECONSTANT)[keyof typeof IDECONSTANT],
  value: string
) => {
  if (key === IDECONSTANT.OUTPUT_EDITOR) {
    saveOutputEditorHistory(value, true)
  }
  const element = document.getElementById(key)
  if (!element) return
  const editor = getEditor(key)
  const session = editor.getSession()
  const lastRow = session.getLength() - 1
  const lastColumn = session.getLine(lastRow).length
  editor.selection.moveTo(lastRow, lastColumn)
  editor.insert(value) // Adding the code coming from websocket after the last outputHistory line to make it consitent
}
/**
 * resize when the window is resized or fullscreen is toggled or keyup
 */
const resizeOutputEditor = async () => {
  const element = await postAceEditorsElement(IDECONSTANT.OUTPUT_EDITOR)
  if (!element) return
  heightChangeFunction(IDECONSTANT.OUTPUT_EDITOR)
}
/**
 * resize when the window is resized or fullscreen is toggled or keyup
 */
const resizeCodeEditor = async () => {
  const element = await postAceEditorsElement(IDECONSTANT.CODE_EDITOR)
  if (!element) return
  if (!useIdeStore().codeEditor) return
  heightChangeFunction(IDECONSTANT.CODE_EDITOR)
}

/**
 * get relative height
 * @returns the relative height
 */
const getRelativeHeight = () => {
  const excludeElements = document.querySelectorAll(`#${IDERELETIVEHEIGHT.EXCLUDEID}`)
  const excludeElementsHeight = Array.from(excludeElements).reduce(
    (acc, el) => acc + el.clientHeight,
    0
  )
  const relativeHeight = window.innerHeight - excludeElementsHeight
  const minimumHeight = document.getElementById(IDERELETIVEHEIGHT.MINIMUMID)?.clientHeight || 0
  const innerWidth = window.innerWidth
  if (innerWidth < 640) {
    return relativeHeight
  } else {
    return relativeHeight < minimumHeight ? minimumHeight : relativeHeight
  }
}
/**
 * update the editor height
 * @param key - the key of the editor
 */
const heightChangeFunction = (key: (typeof IDECONSTANT)[keyof typeof IDECONSTANT]) => {
  const element = document.getElementById(key)
  let editor = null
  if (!element) return
  try {
    editor = getEditor(key)
  } catch (e) {
    return
  }

  const isPrintBlocker = useIdeStore().isPrintBlocker

  let newHeight =
    editor.getSession().getScreenLength() * editor.renderer.lineHeight +
    editor.renderer.scrollBar.getWidth() +
    (key === IDECONSTANT.OUTPUT_EDITOR && useIdeStore().isIdeView ? 40 : 20)
  let minHeight = 80
  if (key === IDECONSTANT.CODE_EDITOR && !isPrintBlocker) {
    //get parent on #ideCodeEditor
    document.getElementById(key)?.style.setProperty('height', `0px`)
    document.getElementById(key)?.style.setProperty('min-height', `0px`)
    const parent = document.getElementById('ideCodeEditor')
    const parentHeight = parent?.clientHeight || 256
    if (
      useIdeStore().isEmbedded ||
      (usePluginStore().isPlugin && !usePluginStore().isCusomPlugin)
    ) {
      newHeight = 384
      minHeight = 384
    } else {
      if (usePluginStore().isCusomPlugin) {
        newHeight = parentHeight
        minHeight = parentHeight
      } else if (useIdeStore().isBlockly) {
        newHeight = parentHeight
        minHeight = parentHeight
      } else {
        if (useIdeStore().isFullScreen) {
          const relativeHeight = getRelativeHeight()
          newHeight =
            parentHeight > newHeight
              ? parentHeight
              : newHeight > relativeHeight
              ? relativeHeight
              : newHeight
          ideService.heightChangeFunction(relativeHeight)
          jdroidService.heightChangeFunction(relativeHeight)
        } else {
          const innerWidth = window.innerWidth
          if (innerWidth < 640) {
            const relativeHeight = getRelativeHeight()
            newHeight = parentHeight
            ideService.heightChangeFunction(relativeHeight)
          } else {
            const relativeHeight = getRelativeHeight()
            newHeight = parentHeight
            ideService.heightChangeFunction(relativeHeight)
            jdroidService.heightChangeFunction(relativeHeight)
          }

          minHeight = parentHeight
        }
      }
    }
  }
  if (key === IDECONSTANT.OUTPUT_EDITOR && !isPrintBlocker) {
    //get parent on #ideOutputEditor
    if (useIdeStore().isIdeView) {
      document.getElementById(key)?.style.setProperty('height', `${newHeight}px`)
      document.getElementById(key)?.style.setProperty('min-height', `${minHeight}px`)
    } else {
      document.getElementById(key)?.style.setProperty('height', `0px`)
      document.getElementById(key)?.style.setProperty('min-height', `0px`)
    }

    const parent = document.getElementById('ideOutputEditor')
    const parentHeight = parent?.clientHeight || 114
    minHeight = 114
    if (
      useIdeStore().isEmbedded ||
      (usePluginStore().isPlugin && !usePluginStore().isCusomPlugin)
    ) {
      newHeight = 114
      minHeight = 114
    } else if (usePluginStore().isCusomPlugin) {
      newHeight = parentHeight
    } else {
      if (!useIdeStore().isGuru) {
        if (useIdeStore().isFullScreen) {
          newHeight = parentHeight
        } else {
          if (useIdeStore().currentMobileTab != null) {
            newHeight = parentHeight //mobile
          } else {
            newHeight = parentHeight > newHeight ? parentHeight : newHeight //desktop
          }
        }
      }
    }
  }
  document.getElementById(key)?.style.setProperty('height', `${newHeight}px`)
  document.getElementById(key)?.style.setProperty('min-height', `${minHeight}px`)
  // Scroll to end solves jump bug in non-fullscreen mode.
  //   document.getElementById(key)?.scrollIntoView({ block: 'end' })
  editor.resize()
  if (heightChangeFunctionList.indexOf(key) !== -1) {
    heightChangeFunctionList.splice(heightChangeFunctionList.indexOf(key), 1)
  }
}
/**
 * set the font size of the editor
 * @param size - the size to set
 */
const setFontSize = (size?: number) => {
  if (size) {
    if (!usePluginStore().isPlugin && !useIdeStore().isEmbedded) {
      localStorage.setItem(IDECONSTANT.LOCALSTORAGE_FONT_SIZE_NAME, size.toString())
    }
    useIdeStore().setFontSize(size)
  } else {
    if (!usePluginStore().isPlugin && !useIdeStore().isEmbedded) {
      if (localStorage.getItem(IDECONSTANT.LOCALSTORAGE_FONT_SIZE_NAME)) {
        useIdeStore().setFontSize(
          Number(localStorage.getItem(IDECONSTANT.LOCALSTORAGE_FONT_SIZE_NAME))
        )
      }
    }
  }
  if (useIdeStore().codeEditor && useIdeStore().fontSize) {
    useIdeStore().codeEditor.setFontSize(`${useIdeStore().fontSize}px`)
    resizeCodeEditor()
  }
}
/**
 * Initialize the ide everytime the route changes
 */
const resetCodeEditor = () => {
  saveOutputEditorHistory('') // when changing the route reset the outputHistory
  const codeEditor = document.querySelector(`#${IDECONSTANT.CODE_EDITOR}`)
  if (window.ace && codeEditor) {
    setEditorSession(IDECONSTANT.CODE_EDITOR, '')
  }
  const outputEditor = document.querySelector(`#${IDECONSTANT.OUTPUT_EDITOR}`)
  if (window.ace && outputEditor) {
    setEditorSession(IDECONSTANT.OUTPUT_EDITOR, '')
  }
  useIdeStore().resetEditor()
}
/**
 * reset output editor when the route changes
 */
const resetOutputEditor = () => {
  useIdeStore().setOutputHistory('')
  useIdeStore().outputEditor.getSession().setValue('')
  useIdeStore().resetExecutionTime()
}
/**
 * set code editor sample script
 */
const codeEditorSetSampleScript = async () => {
  if (useIdeStore().isGuru) return
  if (useJdroidStore().lastChat && useJdroidStore().lastChat?.autoReplaceEditor) return
  if (useJdroidStore().startCoadingEmpty == true && useIdeStore().isIdeView) {
    if (useIdeStore().isAdvanced) {
      ideService.initAdvancedIde(ADVANCEDIDETYPE.CLEAR)
    }
    return
  }
  if (useIdeStore().routeMeta?.sampleScript && useIdeStore().routeMeta?.language !== 'blockly') {
    if (useIdeStore().isHtml) {
      if (useIdeStore().isAdvanced) {
        useIdeStore().codeEditor.getSession().setValue(useIdeStore().routeMeta?.sampleScriptAdvance)
      } else {
        useIdeStore().codeEditor.getSession().setValue(useIdeStore().routeMeta?.sampleScript)
      }
    } else {
      useIdeStore().codeEditor.getSession().setValue(useIdeStore().routeMeta?.sampleScript)
    }
  }
}
/**
 * Set code editor language
 * @param language - The language to set
 */
const codeEditorChangeLanguage = (language: string) => {
  useIdeStore().codeEditor.getSession().setMode(`ace/mode/${language}`)
  if (language === 'whitespace') {
    useIdeStore().codeEditor.setShowInvisibles(true)
  } else {
    useIdeStore().codeEditor.setShowInvisibles(false)
  }
}
/**
 * Set code editor language
 */
const codeEditorSetLanguage = () => {
  if (useIdeStore().isAceLanguageCode) {
    codeEditorChangeLanguage(useIdeStore().isAceLanguageCode as string)
  }
}
/**
 * Set code editors theme
 */
const codeEditorsSetTheme = () => {
  const isDark =
    useIdeStore().isGuru ||
    usePluginStore().isPlugin ||
    useIdeStore().isEmbedded ||
    usePluginStore().isCusomPlugin
      ? false
      : useIdeStore().colorMode == 'dark'
  if (isDark) {
    if (useIdeStore().codeEditor && !useIdeStore().ideSettings)
      useIdeStore().codeEditor.setTheme('ace/theme/gruvbox')
    if (useIdeStore().projectEditor) {
      useIdeStore().projectEditor.setTheme('ace/theme/gruvbox')
    }
    if (useIdeStore().executeCodeEditor) {
      useIdeStore().executeCodeEditor.setTheme('ace/theme/gruvbox')
    }
    if (useIdeStore().copyEditor) {
      useIdeStore().copyEditor.setTheme('ace/theme/gruvbox')
    }
    if (useIdeStore().downloadEditor) {
      useIdeStore().downloadEditor.setTheme('ace/theme/gruvbox')
    }
    if (useIdeStore().openEditor) {
      useIdeStore().openEditor.setTheme('ace/theme/gruvbox')
    }
  } else {
    if (useIdeStore().codeEditor && !useIdeStore().ideSettings)
      useIdeStore().codeEditor.setTheme('ace/theme/xcode')
    if (useIdeStore().projectEditor) {
      useIdeStore().projectEditor.setTheme('ace/theme/xcode')
    }
    if (useIdeStore().executeCodeEditor) {
      useIdeStore().executeCodeEditor.setTheme('ace/theme/xcode')
    }
    if (useIdeStore().copyEditor) {
      useIdeStore().copyEditor.setTheme('ace/theme/xcode')
    }
    if (useIdeStore().downloadEditor) {
      useIdeStore().downloadEditor.setTheme('ace/theme/xcode')
    }
    if (useIdeStore().openEditor) {
      useIdeStore().openEditor.setTheme('ace/theme/xcode')
    }
  }
  if (useIdeStore().executeOutputEditor)
    useIdeStore().executeOutputEditor.setTheme('ace/theme/gruvbox')
  if (useIdeStore().outputEditor) {
    useIdeStore().outputEditor.setTheme('ace/theme/gruvbox')
  }
  if (useIdeStore().ideSettings?.theme)
    if (
      !useIdeStore().isGuru &&
      !useIdeStore().isEmbedded &&
      !usePluginStore().isPlugin &&
      !usePluginStore().isCusomPlugin
    )
      useIdeStore().codeEditor.setTheme(`ace/theme/${useIdeStore().ideSettings?.theme}`)
}
/**
 * Initialize the ace editor
 * @param id - The id of the ace editor to initialize
 * @returns The ace editor
 */
const initAceEditor = (id: (typeof IDECONSTANT)[keyof typeof IDECONSTANT]) => {
  window.ace.require('ace/ext/language_tools')
  window.ace.config.set('basePath', '/assets/javascript/ace')

  if (window?.ace?.edit(id)) {
    window?.ace?.edit(id).destroy()
  }

  const editor: ace['Editor'] = window.ace.edit(id)

  if (useIdeStore().isAceLanguageCode) {
    if (id === IDECONSTANT.OUTPUT_EDITOR || id === IDECONSTANT.EXECUTE_OUTPUT_EDITOR) {
      editor.getSession().setMode('ace/mode/text')
    } else {
      editor.getSession().setMode(`ace/mode/${useIdeStore().isAceLanguageCode}`)
    }
  }

  editor.renderer.setShowGutter(false)
  editor.renderer.setAnimatedScroll(true)
  editor.renderer.setShowPrintMargin(false)

  editor.setOptions({
    enableBasicAutocompletion: true,
    enableSnippets: true,
    enableLiveAutocompletion: false
  })
  editor.$blockScrolling = Infinity
  if (id === IDECONSTANT.CODE_EDITOR) setCodeEditorReadOnly()

  return editor
}
/**
 * On keyup code editor
 */
const onKeyupOutputEditor = () => {
  getEditor(IDECONSTANT.OUTPUT_EDITOR)
    .textInput.getElement()
    .addEventListener('keypress', (event: KeyboardEvent) => {
      wsService.onKeyupOutputEditor(event)
    })
}
/**
 * Set the sample script in the output editor
 */
const outputEditorSetSampleScript = () => {
  if (!useIdeStore().isIdeView) return
  useIdeStore().outputEditor.getSession().setValue('')
  if (useIdeStore().outputEditor) {
    useIdeStore().outputEditor.getSession().setValue(useIdeStore().outputHistory)
    const outputHasError = aiService.checkOutputHasError(useIdeStore().outputHistory)

    if (outputHasError) {
      // If error, store in ideStore
      useIdeStore().setHasErrorAfterExecute(true)
    } else {
      //If no error - store in ideStore
      useIdeStore().setHasErrorAfterExecute(false)
    }
  }
}

/**
 * On change push output editor
 */
const onChangePushOutputEditor = () => {
  getEditor(IDECONSTANT.OUTPUT_EDITOR)
    .getSession()
    .on('change', () => {
      saveOutputEditorHistory(useIdeStore().outputEditor.getSession().getValue())
    })
}
/**
 * Initialize the output editor
 */
const initOutputEditor = async () => {
  const element = await postAceEditorsElement(IDECONSTANT.OUTPUT_EDITOR)
  if (!element) return
  useIdeStore().outputEditor = initAceEditor(IDECONSTANT.OUTPUT_EDITOR)
  useIdeStore().outputEditor.renderer.setPadding(20)
  useIdeStore().outputEditor.renderer.setScrollMargin(20, 20)
  useIdeStore().outputEditor.setDisplayIndentGuides(false)
  useIdeStore().outputEditor.setHighlightActiveLine(false)
  outputEditorSetSampleScript()
  onKeyupOutputEditor()
  onChangePushOutputEditor()
  onParentOutputEditorResize()
  codeEditorsSetTheme()
}
/**
 * On keyup code editor
 */
const onKeyupCodeEditor = () => {
  getEditor(IDECONSTANT.CODE_EDITOR)
    .textInput.getElement()
    .addEventListener('keyup', (event: KeyboardEvent) => {
      useIdeStore().setCodeUpdated(true)
      jdroidService.updateNotificationKeyCount()
      // Ctrl + Enter to execute code
      if (event.ctrlKey && event.key === 'Enter') {
        executeService.tryExecute()
      }
      if (useIdeStore().isAdvanced) {
        projectTreeService.setCodeChanged()
      }
    })
}
/**
 * On parent code editor resize
 */
const onParentCodeEditorResize = () => {
  const parent = document.getElementById('ideCodeEditor')?.parentNode
  //if any child of parent is changed except the code editor with id code, resize the code editor
  if (parent) {
    const observer = new MutationObserver(() => {
      if (heightChangeFunctionList.indexOf(IDECONSTANT.CODE_EDITOR) === -1) {
        heightChangeFunctionList.push(IDECONSTANT.CODE_EDITOR)
        resizeCodeEditor()
      }
    })

    observer.observe(parent, {
      childList: true,
      subtree: true
    })
  }
}

/**
 * On parent code editor resize
 */
const onParentOutputEditorResize = () => {
  const parent = document.getElementById('ideOutputEditor')?.parentNode
  //if any child of parent is changed except the code editor with id code, resize the code editor
  if (parent) {
    const observer = new MutationObserver(() => {
      if (heightChangeFunctionList.indexOf(IDECONSTANT.OUTPUT_EDITOR) === -1) {
        heightChangeFunctionList.push(IDECONSTANT.OUTPUT_EDITOR)
        resizeOutputEditor()
      }
    })

    observer.observe(parent, {
      childList: true,
      subtree: true
    })
  }
}

/**
 * Initialize the ace code editor
 */
const initCodeEditor = async () => {
  useIdeStore().codeEditor = initAceEditor(IDECONSTANT.CODE_EDITOR)
  if (useProjectManager().projectPermissionWithId?.readOnly)
    useIdeStore().codeEditor.setReadOnly(true)
  useIdeStore().codeEditor.renderer.setShowGutter(true)
  codeEditorSetSampleScript()
  onKeyupCodeEditor()
  setFontSize()
  onParentCodeEditorResize()
}

/**
 * Initialize the editor when router changes
 * @param count - The count of the init
 * @returns null
 */
const initEditors = async (count: number = 0) => {
  if (!useIdeStore().isWindowAce) {
    if (count > 10) {
      return null
    } else {
      await new Promise((resolve) => setTimeout(resolve, 100))
      initEditors(count + 1)
    }
  } else {
    heightChangeFunctionList = []
    jdroidService.resetNotificationKeyCount()
    if (useIdeStore().codeEditor) {
      codeEditorSetSampleScript()
      codeEditorSetLanguage()
    } else {
      initCodeEditor()
    }

    // ** Output editor init/reset has been moved to OutputComp.vue **
    // ** output editor is not used in html mode **

    if (useIdeStore().outputEditor) {
      resetOutputEditor()
    } else {
      if (!useIdeStore().isHtml) {
        initOutputEditor() // no need to call initOutputEditor initially as by default output editor will be hidden
      }
    }

    codeEditorsSetTheme()
    useIdeStore().initVersionIndex()
    await new Promise((resolve) => setTimeout(resolve, 100))
  }
}
/**
 * Save output editor history
 * @param script - the script to save
 * @param push - push to history
 */
const saveOutputEditorHistory = (script: string | null = null, push: boolean = false) => {
  if (!useIdeStore().isIdeView) return
  if (script) {
    if (push) {
      const lastScript = useIdeStore().outputHistory
      useIdeStore().setOutputHistory(lastScript + script)
    } else useIdeStore().setOutputHistory(script)
  } else if (
    useIdeStore().outputEditor &&
    !useIdeStore().isCodeExecuting &&
    !useIdeStore().interactiveMode
  ) {
    useIdeStore().setOutputHistory(useIdeStore().outputEditor.getSession().getValue())
  } else useIdeStore().setOutputHistory(script !== null ? script : '')
}
/**
 * Destroys Output editor if exists
 */
const destroyOutputEditor = () => {
  if (useIdeStore().outputEditor) {
    useIdeStore().outputEditor.destroy()
    useIdeStore().outputEditor = null
  }
}

/**
 * Fix ace editor bug on ios
 */
const fixAceeIOSBug = () => {
  if (
    typeof navigator === 'object' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent) &&
    !window.MSStream
  ) {
    window.MSStream = {}
  }
}
/**
 * split and init the ide
 */
const splitAndInit = async () => {
  await new Promise((resolve) => setTimeout(resolve, 500))
  initEditors()
}
/**
 * Initialize the code editor
 */
const injectAceAndSplit = () => {
  fixAceeIOSBug()
  loadScriptInBody.loadScriptInBody('/assets/javascript/ace.min.js').then(async () => {
    loadScriptInBody.loadScriptInBody('/assets/javascript/ext-language_tools.js')
    loadScriptInBody.loadScriptInBody('/assets/javascript/ext-static_highlight.js')

    if (!useIdeStore().isShared) {
      splitAndInit()
    }
  })
}

/**
 * Initialize the embed code editor
 */
const injectAce = () => {
  fixAceeIOSBug()
  // inject nessary scripts
  loadScriptInBody.loadScriptInBody('/assets/javascript/ace.min.js').then(async () => {
    loadScriptInBody.loadScriptInBody('/assets/javascript/ext-language_tools.js')
    loadScriptInBody.loadScriptInBody('/assets/javascript/ext-static_highlight.js')
  })
}
/**
 * Eject the code editor
 */
const ejectAce = () => {
  if (window.ace) {
    loadScriptInBody.unloadScriptInBody('/assets/javascript/ace.min.js')
    loadScriptInBody.unloadScriptInBody('/assets/javascript/ext-language_tools.js')
    loadScriptInBody.unloadScriptInBody('/assets/javascript/ext-static_highlight.js')
    window.ace = null
  }
}

/**
 * post set script
 * @param script - the script to set
 * @param count - the count
 * @returns null
 */
const postSetScript = async (script: string, count: number = 0) => {
  if (!useIdeStore().isWindowAce) {
    if (count > 10) {
      return null
    } else {
      await new Promise((resolve) => setTimeout(resolve, 600))
      postSetScript(script, count + 1)
    }
  } else {
    setEditorSession(IDECONSTANT.CODE_EDITOR, script)
  }
}

/**
 * @param count - set the count
 * @description - waiting for the response to after running the
 * @returns - return the function with the value
 */
const postAwaitCodeEditor = async (count: number = 0) => {
  return await new Promise((resolve) => {
    if (!useIdeStore().isWindowAce || !useIdeStore().codeEditor) {
      if (count > 10) {
        resolve(false)
      } else {
        setTimeout(() => {
          resolve(postAwaitCodeEditor(count + 1))
        }, 600)
      }
    } else {
      resolve(true)
    }
  })
}

/**
 * @description - setting readOnly for codeEditor
 */
const setCodeEditorReadOnly = async () => {
  await postAwaitCodeEditor().then((res) => {
    if (res) {
      if (useProjectManager().projectPermissionWithId?.readOnly)
        useIdeStore().codeEditor?.setReadOnly(true)
      else useIdeStore().codeEditor?.setReadOnly(false)
    } else if (useIdeStore().codeEditor) useIdeStore().codeEditor?.setReadOnly(false)
  })
}
/**
 * get the project
 * @returns the project
 */
const htmlGetProject = () => {
  return {
    htmlCode: getEditorSession(IDECONSTANT.CODE_EDITOR).getValue()
  }
}
/**
 * get the last execution
 * @returns the last execution
 */
const htmlGetLastExecution = (): THtmlExecutionHistory => {
  const project = htmlGetProject()
  const lastExecution: THtmlExecutionHistory = {
    ...project,
    executedAt: new Date().getTime()
  }
  return lastExecution
}
export default {
  setFontSize,
  heightChangeFunction,
  ejectAce,
  injectAceAndSplit,
  injectAce,
  splitAndInit,
  initEditors,
  destroyOutputEditor,
  resizeCodeEditor,
  resizeOutputEditor,
  getEditor,
  getEditorSession,
  setEditorSession,
  forcusEditor,
  insertEditorSession,
  codeEditorChangeLanguage,
  codeEditorsSetTheme,
  resetCodeEditor,
  initAceEditor,
  postSetScript,
  initOutputEditor,
  setCodeEditorReadOnly,
  htmlGetLastExecution
}
