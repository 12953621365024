declare global {
  interface Window {
    grecaptcha: any
  }
}
import { useAuthStore } from '@/stores/auth.store'
import { useRecaptchaStore } from '@/stores/recaptcha.store'
import { RECAPTCHA_KEYS } from '@/utils/recaptcha'
import axios from 'axios'

export interface IRobotCheckRequest {
  gc: string
}
const recheckDuration = 30

/**
 * wait for app initiated because we want to check the environment
 * @param count count
 * @returns app initiated or not
 */
const postInitApp = async (count: number = 0): Promise<boolean> => {
  if (!useAuthStore().isInitiated) {
    if (count < 80) {
      await new Promise((resolve) => setTimeout(resolve, 100))
      return postInitApp(count + 1)
    } else {
      return false
    }
  } else return true
}
/**
 * disable recaptha by sending test key
 * @param key recaptha key
 * @returns if dev send test key else original key
 */
export const getRechapthaKey = async (key: string): Promise<string> => {
  const isAppInitiated = await postInitApp()
  if (isAppInitiated) {
    await new Promise((resolve) => setTimeout(resolve, 100))
    const environment = useAuthStore().environment
    if (environment === 'development' || environment === 'test')
      return RECAPTCHA_KEYS.RECAPTHATESTKEY
    else return key
  } else {
    return key
  }
}
/**
 * when recaptcha is verified, call the server and resolve the promise
 * or if already verified, resolve the promise
 * @param response - The response
 */
const recaptchaVerified = async (response?: string) => {
  if (response) {
    const robotCheckRequest: IRobotCheckRequest = {
      gc: response
    }
    await axios
      .post('/api/doodle/doRobotCheck', robotCheckRequest)
      .then(() => {
        useAuthStore().robotCheckDone()
        useRecaptchaStore().setrecaptchaDetailsResolve()
      })
      .catch((error: any) => {
        useAuthStore().clearRobotCheck()
        recaptchaFailed(error)
      })
  } else {
    useRecaptchaStore().setrecaptchaDetailsResolve()
  }
}
/**
 * when recaptcha fails reject the promise
 * @param error - The error
 */
const recaptchaFailed = async (error?: any) => {
  if (error) {
    useRecaptchaStore().setrecaptchaDetailsReject(error)
  } else {
    useRecaptchaStore().setrecaptchaDetailsReject('Failed to verify captcha')
  }
}
/**
 * post init recaptcha
 * @param count count
 * @returns The promise
 */
const postRecaptcha = async (count: number = 0): Promise<boolean> => {
  if (!window.grecaptcha || !useRecaptchaStore().rechaptcha) {
    if (count < 120) {
      await new Promise((resolve) => setTimeout(resolve, 100))
      return postRecaptcha(count + 1)
    } else {
      return false
    }
  } else {
    return true
  }
}
/**
 * when recaptcha errors reject the promise
 * @param error - The error
 */
const recaptchaError = async (error: string) => {
  await postRecaptcha()
  try {
    useRecaptchaStore().setrecaptchaDetailsReject(error)
  } catch (e) {
    ;() => {}
  }
}
/**
 * execute captcha
 * @param count count
 */
const executeCaptcha = async (count: number = 0) => {
  await postRecaptcha()
  try {
    if (useRecaptchaStore().rechaptcha) useRecaptchaStore().rechaptcha.execute()
  } catch (e) {
    await new Promise((resolve) => setTimeout(resolve, 100))
    if (count < 10) await executeCaptcha(count + 1)
  }
}
/**
 * reset captcha
 * @param count count
 */
const resetCaptcha = async (count: number = 0) => {
  await postRecaptcha()
  try {
    if (useRecaptchaStore().rechaptcha) useRecaptchaStore().rechaptcha.reset()
  } catch (e) {
    await new Promise((resolve) => setTimeout(resolve, 100))
    if (count < 10) await resetCaptcha(count + 1)
  }
}
/**
 * call via captcha step 2
 * if robot is checked, call recaptchaVerified
 * else call executeCaptcha
 */
const callViaCaptchaStep2 = () => {
  if (!useAuthStore().robotChecked) {
    resetCaptcha()
    executeCaptcha()
  } else {
    recaptchaVerified()
  }
}
/**
 * check robot status with server
 */
const checkRobotStatus = async () => {
  await axios
    .post('/api/doodle/isRobotChecked')
    .then((response: { data: { robotChecked: boolean } }) => {
      if (response.data.robotChecked) {
        useAuthStore().robotCheckDone()
      } else {
        useAuthStore().clearRobotCheck()
      }
      callViaCaptchaStep2()
    })
    .catch((error: any) => {
      useAuthStore().clearRobotCheck()
      recaptchaFailed(error)
    })
}
/**
 * check time since last checked
 * @returns The time since last checked
 */
const timeSinceLastChecked = () => {
  const lastRobotCheckedTime = useAuthStore().lastRobotCheckedTime
  if (!lastRobotCheckedTime) {
    return 0
  } else {
    return (Date.now() - lastRobotCheckedTime) / 1000
  }
}
/**
 * Check recaptcha
 * trigged when new project is created
 */
const checkRecaptcha = async () => {
  const lastCheckTime = timeSinceLastChecked()
  if (lastCheckTime > recheckDuration) {
    await checkRobotStatus()
  } else {
    await callViaCaptchaStep2()
  }
}

/**
 * post init recaptcha
 * @param count count
 * @returns The promise
 */
const postInitRecaptcha = async (count: number = 0): Promise<boolean> => {
  if (!useAuthStore().isAppInitiated || !window.grecaptcha) {
    if (count < 120) {
      await new Promise((resolve) => setTimeout(resolve, 100))
      return postInitRecaptcha(count + 1)
    } else {
      return new Promise((resolve, reject) => {
        reject('App not initiated')
      })
    }
  } else {
    await new Promise((resolve) => setTimeout(resolve, 100))
    return useRecaptchaStore().setrecaptchaDetails()
  }
}
/**
 * send back a promise
 * @returns The promise
 */
const callViaCaptcha = () => {
  return postInitRecaptcha()
}
/**
 * load recaptcha callback
 * @param recaptchaPromiseResolve - The resolve method
 * @param recaptchaPromiseReject - The reject method
 * @param gc - The gc string
 */
const loadRecaptchaCallback = (
  recaptchaPromiseResolve: (value: any) => void,
  recaptchaPromiseReject: (reason?: any) => void,
  gc: string
) => {
  if (!window.grecaptcha) {
    recaptchaPromiseReject('Recaptcha not loaded. Please try again.')
  }
  if (!gc) {
    recaptchaPromiseReject('Recaptcha failed. Please verify again.')
  } else {
    recaptchaPromiseResolve(gc)
  }
}
/**
 * post load recaptcha
 * @param container ref to the container
 * @param expiredCallback expired callback
 * @param count count
 * @returns The null or gcid string if success
 */
const postLoadRecaptcha = async (
  container: string,
  expiredCallback?: () => void,
  count: number = 0
): Promise<{ gcid: number; recaptchaPromise: Promise<any> }> => {
  if (!window.grecaptcha || !window.grecaptcha.render) {
    if (count > 50) {
      return { gcid: -1, recaptchaPromise: Promise.reject('Recaptcha not loaded') }
    } else {
      await new Promise((resolve) => setTimeout(resolve, 500))
      return await postLoadRecaptcha(container, expiredCallback, count + 1)
    }
  } else {
    let recaptchaPromiseResolve: ((value: any) => void) | null = null
    let recaptchaPromiseReject: ((reason?: any) => void) | null = null
    const recaptchaPromise = new Promise((resolve, reject) => {
      recaptchaPromiseResolve = resolve
      recaptchaPromiseReject = reject
    })
    const gcid = window.grecaptcha.render(container, {
      sitekey: RECAPTCHA_KEYS.RECAPTCHAIDEKEY,
      callback: loadRecaptchaCallback.bind(this, recaptchaPromiseResolve!, recaptchaPromiseReject!),
      'expired-callback': expiredCallback
    })
    return { gcid: gcid, recaptchaPromise: recaptchaPromise }
  }
}

/**
 * load recaptcha
 * @param container ref to the container
 * @param expiredCallback expired callback
 * @returns The null or gcid string if success
 */
const loadRecaptcha = async (
  container: string,
  expiredCallback?: () => void
): Promise<{ gcid: number; recaptchaPromise: Promise<any> }> => {
  return await postLoadRecaptcha(container, expiredCallback)
}

export default {
  getRechapthaKey,
  loadRecaptcha,
  recaptchaVerified,
  recaptchaFailed,
  recaptchaError,
  resetCaptcha,
  checkRecaptcha,
  callViaCaptcha
}
