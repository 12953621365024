import { useAuthStore } from '@/stores/auth.store'
import { useNavStore } from '@/stores/nav.store'
import markerSDK from '@marker.io/browser'

/**
 * this function initializes marker config settings
 * @param count count
 * @returns marker initiated or not
 */
const postMarkerInit = async (count: number = 0): Promise<boolean> => {
  if (!window.Marker || !useAuthStore().isInitiated) {
    if (count < 80) {
      await new Promise((resolve) => setTimeout(resolve, 100))
      return postMarkerInit(count + 1)
    } else {
      return false
    }
  } else return true
}

/**
 * this function initializes marker config settings
 * @returns marker initiated or not
 */
const loadMarkerConfig = async () => {
  return await postMarkerInit()
    .then(async () => {
      const widget = await markerSDK.loadWidget({
        project: '65379a42896b9b3785d33e92',
        source: 'snippet'
      })
      if (useAuthStore().isUserloggedIn) {
        widget.setReporter({
          email: useAuthStore().userEmail as string,
          fullName: useAuthStore().firstName + ' ' + useAuthStore().lastName
        })
      }
      window.Marker.hide()
      if (window.Marker) {
        useNavStore().setIsMarkerInitiated(true)
        return true
      } else return false
    })
    .catch(() => {
      return false
    })
}

/**
 * this function captures marker feedback
 * @param isAdvanced isAdvanced
 */
const captureMarkerFeedback = async (isAdvanced: boolean = false) => {
  await postMarkerInit().then(async () => {
    if (isAdvanced) window.Marker.capture('advanced')
    else window.Marker.capture('fullscreen')
  })
}

export default {
  loadMarkerConfig,
  captureMarkerFeedback
}
