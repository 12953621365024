<script setup lang="ts">
import supbaseService, { type IJDAnswer } from '@/services/supbase.service'

import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { marked } from 'marked'
import { useSSGStore } from '@/stores/ssg.store'
import { useJdroidStore } from '@/stores/jdroid.store'

const route = useRoute()
const router = useRouter()

const ssgStore = useSSGStore()
const jdroidStore = useJdroidStore()

const items = ref<IJDAnswer[]>([])
const visiblePages = ref<any>({})
const perPage = 10
const totalRecords = ref<number>(0)
const totalPages = ref<number>(0)
const currentPage = ref<number>(1)

if (ssgStore.isSSG) {
  const pageInPath = parseInt(route.params.pathIndex as string) || 1
  const start = (pageInPath - 1) * perPage
  items.value = jdroidStore.ssgJdroidAllQuestion.slice(start, start + perPage)
}

/**
 *pulls data from supabase
 */
const pullData = async () => {
  const start = (currentPage.value - 1) * perPage
  items.value = (await supbaseService.getQuestionsAndAnswers(start, start + perPage - 1)).data

  setVisiblePages()
}

/**
 * @param tags - tags to parse
 * parses tags from string to array
 * @returns arroy of tags
 */
const getTags = (tags: string) => {
  if (!tags) return []
  return JSON.parse(tags.replace(/'/g, '"'))
}

/**
 * sets visible pages
 */
const setVisiblePages = () => {
  const range = 2 // Number of pages to show around the current page
  const start = Math.max(1, currentPage.value - range)
  const end = Math.min(totalPages.value, currentPage.value + range)
  visiblePages.value = Array.from({ length: end - start + 1 }, (_, i) => start + i)
}

onMounted(async () => {
  totalRecords.value = (await supbaseService.getCount()).count
  totalPages.value = totalRecords.value / perPage
  let pageInPath = parseInt(route.params.pathIndex as string) || 1
  if (pageInPath > totalPages.value) {
    pageInPath = 1
  }

  router.push({ params: { pathIndex: currentPage.value.toString() } })
  currentPage.value = pageInPath

  await pullData()
})

/**
 * @param id of the question
 * @param title of the question
 * gets chat url
 * @returns chat url
 */
const getChatUrl = (id: string, title: string) => {
  return encodeURI(`/chat-jdroid/qa/${id}/${title.replace(/[\s/]/g, '-').toLowerCase()}`)
}

/**
 * moves to next page
 */
const nextPage = async () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++
    router.push({ params: { pathIndex: currentPage.value.toString() } })
    await pullData()
  }
}

/**
 * @param page - page to goto
 * @returns void
 */
const gotoPage = async (page: number) => {
  if (page > totalPages.value || page < 1) {
    return
  }

  currentPage.value = page
  router.push({ params: { pathIndex: currentPage.value.toString() } })
  await pullData()
}

/**
 * moves to previous page
 */
const prevPage = async () => {
  if (currentPage.value > 1) {
    currentPage.value--
    router.push({ params: { pathIndex: currentPage.value.toString() } })
    pullData()
  }
}
</script>

<template>
  <div class="flex-full flex flex-col px-10 py-10 md:px-20 lg:px-40">
    <div class="mb-10 text-2xl font-semibold">Questions answered by JDroid</div>
    <div>
      <div class="flex flex-col space-y-8">
        <div
          v-for="item in items"
          :key="item?.so_id"
          class="rounded-lg bg-white py-5 pl-4 pr-10 shadow-md md:pl-32"
        >
          <router-link
            :to="{
              path: getChatUrl(item?.so_id, item?.title)
            }"
          >
            <h2 class="text-xl font-semibold">{{ item?.so_id }} - {{ item?.title }}</h2>
          </router-link>
          <div class="my-4">
            <span
              v-for="tag in getTags(item?.tags)"
              :key="tag"
              class="mr-2 rounded-full bg-gray-200 px-2 py-1 text-sm text-gray-800"
            >
              {{ tag }}
            </span>
          </div>
          <div class="prose lg:prose-xl">
            <div class="mt-2 text-gray-600" v-html="marked(item?.body)"></div>
          </div>
        </div>
      </div>

      <div class="mt-8 flex flex-row justify-center space-x-4" v-if="items">
        <button
          @click="gotoPage(1)"
          class="ml-4 rounded bg-new-secondary-600 px-4 py-2 font-bold text-white hover:bg-new-secondary-400"
        >
          First
        </button>
        <button
          @click="prevPage()"
          :disabled="currentPage === 1"
          class="rounded bg-new-secondary-600 px-4 py-2 font-bold text-white hover:bg-new-secondary-400"
        >
          Previous
        </button>
        <button
          v-for="page in visiblePages"
          :key="page"
          @click="gotoPage(page)"
          :class="[
            page === currentPage
              ? 'bg-new-secondary-400'
              : 'bg-new-secondary-600 hover:bg-new-secondary-400',
            'rounded px-4 py-2 font-bold text-white'
          ]"
        >
          {{ page }}
        </button>
        <button
          @click="nextPage()"
          :disabled="currentPage === totalPages"
          class="ml-4 rounded bg-new-secondary-600 px-4 py-2 font-bold text-white hover:bg-new-secondary-400"
        >
          Next
        </button>
        <button
          @click="gotoPage(totalPages)"
          class="ml-4 rounded bg-new-secondary-600 px-4 py-2 font-bold text-white hover:bg-new-secondary-400"
        >
          Last
        </button>
      </div>
    </div>
  </div>
</template>
