export default interface IAssociationType {
  code: IAssociationCode
  description: string
  name: string
  type: IAssociationType_Type
}

export enum IAssociationCode {
  INDIVIDUAL_ACCOUNT = 'INDIVIDUAL_ACCOUNT',

  //Organisations
  ORGANISATION_OWNER = 'ORGANISATION_OWNER',
  ORGANISATION_ADMIN = 'ORGANISATION_ADMIN',

  //API
  API_ADMIN = 'API_ADMIN',
  API_VIEWER = 'API_VIEWER',

  //Assessments
  INSTITUTE_ADMIN = 'INSTITUTE_ADMIN',
  TEACHER = 'TEACHER',
  STUDENT = 'STUDENT',

  //Embed
  EMBED_ADMIN = 'EMBED_ADMIN',
  EMBED_VIEWER = 'EMBED_VIEWER',

  //IDE
  FREE_IDE = 'FREE_IDE',
  PREMIUM_IDE = 'PREMIUM_IDE'
}

export enum IAssociationType_Type {
  OWNER = 'OWNER',
  ASSESSMENT = 'ASSESSMENT',
  EMBED = 'EMBED',
  IDE = 'IDE',
  API = 'API'
}

export enum IAccountType {
  OWNER = 'OWNER',
  MEMBER = 'MEMBER'
}
export interface IGetAssociationsAndInstituteOwnResponse {
  data: {
    accountId: number
    associationTypes: IAssociationType[]
    owner: {
      firstName: string
      lastName: string | null
      email: string
    }
    type: IAccountType
    userId: number
  }
}

export interface IAssignRoleList {
  name: string
  value: IAssociationCode | null
}
