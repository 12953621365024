/* eslint-disable jsdoc/require-jsdoc */
import { META } from '@/utils/meta'
import { type RouteLocationNormalized } from 'vue-router'

const embedRoutes = [
  {
    path: '/embed-manager',
    name: 'embed-view',
    meta: META.default,
    component: () => import('@/views/embeds/EmbedView.vue'),
    children: [
      {
        path: '',
        name: 'embed-manager',
        component: () => import('@/views/embeds/EmbedManager.vue')
      },
      {
        path: 'configure/:embedId?',
        name: 'embed-configure',
        component: () => import('@/views/embeds/EmbedConfigure.vue'),
        props: (route: RouteLocationNormalized) => ({
          embedId: route.params.embedId,
          projectId: route.query.projectId,
          projectLanguage: route.query.projectLanguage
        })
      }
    ]
  }
]

export default embedRoutes
