<script setup lang="ts">
import { onMounted, ref, computed } from 'vue'
import TopModuleCard from '@/components/content-app/tutorials/LanguagePage/TopModuleCard.vue'
import { useRoute } from 'vue-router'
import TutorialsService from '@/services/content-app/tutorials.service'
import type ILanguage from '@/components/content-app/interface/tutorials/ILanguage'
import type ITutorialModule from '@/components/content-app/interface/tutorials/ITutorialModule'
const props = defineProps({
  currentModuleUrl: {
    type: String,
    required: false
  }
})
const route = useRoute()
const languageUrl = route.params.languageUrl as string
const _tutorialsService = new TutorialsService()

const languageData = ref(null as ILanguage | null)
const relatedModules = computed(() => {
  return languageData.value?.attributes.modules?.data
    .filter(
      (tutorialModule: ITutorialModule) => tutorialModule.attributes.Url != props.currentModuleUrl
    )
    .slice(0, 3) as ITutorialModule[]
})

onMounted(async () => {
  const data = await _tutorialsService.getOneLanguage(languageUrl)
  languageData.value = data
})
</script>

<template>
  <div v-show="relatedModules && relatedModules.length > 0">
    <h2 class="heading-medium mb-10">Related Modules</h2>
    <div class="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
      <top-module-card
        v-for="tutorialModule in relatedModules"
        :key="tutorialModule.id"
        :module="tutorialModule"
      ></top-module-card>
    </div>
  </div>
</template>
