/* eslint-disable jsdoc/require-jsdoc */
const organisationRoutes = [
  {
    path: '',
    name: 'organisation',
    component: () => import('@/views/OrganisationView.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'organisation-home',
        component: () => import('@/components/organisation/OrganisationHome.vue')
      },
      {
        path: 'teams',
        name: 'organisation-teams',
        component: () => import('@/components/organisation/OrganisationTeams.vue'),
        children: [
          {
            path: 'users',
            name: 'organisation-users',
            component: () => import('@/components/organisation/teams/UsersPage.vue')
          },
          {
            path: 'student-groups',
            name: 'organisation-student-groups',
            component: () => import('@/components/organisation/teams/StudentGroupsPage.vue')
          },
          {
            path: 'student-groups/:studentGroupId/manage',
            name: 'organisation-manage-student-groups',
            component: () =>
              import('@/components/organisation/teams/AddUsersToStudentGroupPage.vue')
          }
        ]
      },
      {
        path: 'assessments',
        name: 'organisation-assessments',
        component: () => import('@/views/organisation/assessment/AssessmentsHome.vue')
      },
      {
        path: 'assessments/create-assessments',
        name: 'organisation-create-assessments',
        component: () => import('@/views/organisation/assessment/AssessmentAIHome.vue')
      },
      {
        path: 'assessments/:assessmentId',
        name: 'organisation-one-assessment',
        component: () => import('@/views/organisation/assessment/AssessmentView.vue'),
        children: [
          {
            path: '',
            name: 'organisation-assessment-view-staff',
            component: () => import('@/components/organisation/assessments/AssessmentPage.vue')
          },
          {
            path: 'question-maker',
            name: 'organisation-assessment-question-maker',
            component: () => import('@/components/organisation/assessments/QuestionMaker.vue')
          },
          {
            path: ':mode',
            name: 'organisation-assignment-attend-preview',
            component: () =>
              import('@/views/organisation/assessment/AssessmentAttendPreviewEvaluatePage.vue')
          }
        ]
      },

      {
        path: 'student-assessments',
        name: 'organisation-student-assessments',
        component: () => import('@/views/organisation/assessment/StudentAssessmentsHome.vue')
      },
      // --------------------------
      // ----- Courses routes -----
      // --------------------------
      {
        path: ':orgCode/courses',
        name: 'course-manage',
        component: () => import('@/views/organisation/courses/CourseManagementView.vue'),
        children: [
          {
            path: '',
            name: 'course-manage-home',
            component: () => import('@/components/organisation/courses/CourseManageHome.vue')
          },
          {
            path: 'add',
            name: 'course-add',
            component: () => import('@/components/organisation/courses/AddEditCourse.vue')
          },
          {
            path: ':courseId',
            name: 'course-details',
            children: [
              {
                path: '',
                name: 'course-detail-home',
                component: () =>
                  import('@/components/organisation/courses/details/CourseDetailHome.vue')
              },
              {
                path: ':mode',
                name: 'course-edit',
                component: () => import('@/components/organisation/courses/AddEditCourse.vue')
              },
              {
                path: 'preview',
                name: 'course-preview',
                component: () =>
                  import('@/components/organisation/courses/details/CourseDetailPreview.vue'),
                children: [
                  {
                    path: '',
                    name: 'course-preview-summary',
                    component: () =>
                      import('@/components/organisation/courses/summary/CourseSummary.vue')
                  },
                  {
                    path: ':sequence',
                    name: 'course-preview-items',
                    component: () =>
                      import('@/components/organisation/courses/summary/CourseSummaryItems.vue')
                  }
                ]
              },
              {
                path: 'lesson/:lessonId',
                name: 'course-lesson-manage',
                component: () => import('@/components/organisation/courses/lesson/ManageLesson.vue')
              },
              {
                path: 'quiz/:quizId',
                name: 'course-quiz-manage',
                component: () => import('@/components/organisation/courses/quiz/ManageQuiz.vue'),
                children: [
                  {
                    path: '',
                    name: 'course-question-list',
                    component: () =>
                      import('@/components/organisation/courses/quiz/QuestionList.vue')
                  },
                  {
                    path: ':mode',
                    name: 'course-question-manage',
                    component: () =>
                      import('@/components/organisation/courses/quiz/ManageQuestion.vue'),
                    children: [
                      {
                        path: ':questionId',
                        name: 'course-question-edit',
                        component: () =>
                          import('@/components/organisation/courses/quiz/ManageQuestion.vue')
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      // ---- Student courses ------
      {
        path: ':orgCode/courses-student',
        name: 'organisation-student-courses',
        children: [
          {
            path: '',
            name: 'organisation-student-courses-home',
            component: () =>
              import('@/components/organisation/courses/student/CourseStudentHome.vue')
          },
          {
            path: ':courseId/:mode',
            name: 'organisation-student-course-view',
            component: () =>
              import('@/components/organisation/courses/student/CourseStudentDetail.vue'),
            children: [
              {
                path: '',
                name: 'organisation-student-course-summary',
                component: () =>
                  import('@/components/organisation/courses/summary/CourseSummary.vue')
              },
              {
                path: ':sequence',
                name: 'organisation-student-course-attend-item',
                component: () =>
                  import('@/components/organisation/courses/summary/CourseSummaryItems.vue')
              }
            ]
          }
        ]
      },
      // ----------------------------
      // ----- Exercises routes -----
      // ----------------------------
      {
        path: ':orgCode/exercises',
        name: 'exercise-manage',
        component: () => import('@/views/organisation/exercises/ExerciseManagementView.vue'),
        children: [
          {
            path: '',
            name: 'exercise-manage-home',
            component: () => import('@/components/organisation/exercises/ExerciseManageHome.vue')
          },
          {
            path: 'add',
            name: 'exercise-add',
            component: () => import('@/components/organisation/exercises/AddEditExercise.vue')
          },
          {
            path: ':exerciseId',
            name: 'exercise-details',
            children: [
              {
                path: '',
                name: 'exercise-detail-home',
                component: () =>
                  import('@/components/organisation/exercises/details/ExerciseDetailsHome.vue')
              },
              {
                path: ':mode',
                name: 'exercise-edit',
                component: () => import('@/components/organisation/exercises/AddEditExercise.vue')
              },
              {
                path: 'preview',
                name: 'exercise-preview',
                component: () =>
                  import('@/components/organisation/exercises/ExerciseEditorPage.vue')
              },
              {
                path: 'lesson/:lessonId',
                name: 'exercise-lesson-manage',
                component: () => import('@/components/organisation/courses/lesson/ManageLesson.vue')
              },
              {
                path: 'quiz/:quizId',
                name: 'exercise-quiz-manage',
                component: () => import('@/components/organisation/courses/quiz/ManageQuiz.vue'),
                children: [
                  {
                    path: '',
                    name: 'exercise-question-list',
                    component: () =>
                      import('@/components/organisation/courses/quiz/QuestionList.vue')
                  },
                  {
                    path: ':mode',
                    name: 'exercise-question-manage',
                    component: () =>
                      import('@/components/organisation/courses/quiz/ManageQuestion.vue'),
                    children: [
                      {
                        path: ':questionId',
                        name: 'exercise-question-edit',
                        component: () =>
                          import('@/components/organisation/courses/quiz/ManageQuestion.vue')
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      // ---- Student exercise ------
      {
        path: ':orgCode/exercises-student',
        name: 'organisation-student-exercises',
        children: [
          {
            path: '',
            name: 'organisation-student-exercise-home',
            component: () =>
              import('@/components/organisation/exercises/student/ExerciseStudentHome.vue')
          },
          {
            path: ':exerciseId/:mode',
            name: 'organisation-student-exercise-view',
            component: () =>
              import('@/components/organisation/exercises/student/ExerciseStudentDetail.vue'),
            children: [
              {
                path: '',
                name: 'organisation-student-exercise-details',
                component: () =>
                  import('@/components/organisation/exercises/ExerciseEditorPage.vue')
              }
            ]
          }
        ]
      }
    ]
  }
]

export default organisationRoutes
