export interface IAIRequest {
  prompt: string
  language: string
  task: string
}

/**
 * set if the Output has error in it
 * @param output code output from execution
 * @returns  Boolean if error is detected
 */
const checkOutputHasError = (output: string | undefined): boolean => {
  if (!output) return false
  //use regex to check error in execution output
  // The negative lookbehind (?<!\b0\s) excludes text such as "0 Errors"
  // const errorRe = new RegExp('[Ee]rror*|[Ee]xception*|[Ww]arning')
  const excludeNegation = '(?<!\b0s|\b[Nn]os)'
  const genericRe = new RegExp(
    `${excludeNegation}[Ee]rror|${excludeNegation}[Ee]xception|${excludeNegation}[Ww]arning`
  )
  const secondGenericRe = new RegExp(
    '[Ii]nvalid|[Ee]xpected|unexpected|not found|[Uu]nknown|[Nn]onzero|[Uu]ndefined'
  )
  // valid outputs
  const helloJdoodle = new RegExp('Your request seems invalid. Please try to execute again.', 'm')
  const csharp1 = new RegExp('Build succeeded', 'm')
  const csharp2 = new RegExp('0 Warning\\(s\\)', 'm')
  const csharp3 = new RegExp('0 Error\\(s\\)', 'm')
  const iconRe = new RegExp('No errors', 'm')
  // jlang uses the "|" character at line beginnings when displaying errors
  // const jLangRe = new RegExp('(^|s*S)')
  const javaRe = new RegExp('No "public class"')
  const intercalRe = new RegExp('RESUBNIT')
  const hackRe = new RegExp(`[Nn]otice|${excludeNegation}[Ee]rror`)
  const forthRe = new RegExp('Backtrace')
  const picolispRe = new RegExp('Overrun')
  const algol68Re = new RegExp('unterminated string')
  const racketRe = new RegExp('bad syntax|[Uu]ndefined')
  const cRe = new RegExp('not initialized|Command terminated')
  const goLangRe = new RegExp('not terminated')

  if (helloJdoodle.test(output)) {
    return false
  } else if (csharp1.test(output) && csharp2.test(output) && csharp3.test(output)) {
    return false
  } else if (iconRe.test(output)) {
    return false
  } else if (
    genericRe.test(output) ||
    secondGenericRe.test(output) ||
    javaRe.test(output) ||
    // jLangRe.test(output) ||
    forthRe.test(output) ||
    cRe.test(output) ||
    goLangRe.test(output) ||
    racketRe.test(output) ||
    intercalRe.test(output) ||
    hackRe.test(output) ||
    picolispRe.test(output) ||
    algol68Re.test(output)
  ) {
    return true
  } else return false
}

export default {
  checkOutputHasError
}
