<script setup lang="ts">
import { computed } from 'vue'
import { useIdeStore } from '@/stores/ide.store'
import { usePluginStore } from '@/stores/plugin.store'
import { IDECONSTANT } from '@/utils/ide'

const ideStore = useIdeStore()
const pluginStore = usePluginStore()

const isOutputEditor = computed(() => {
  return ideStore.outputEditor
})
const showExecuteDetails = computed(() => {
  return !ideStore.isCodeExecuting && ideStore.executionTime
})
const isInteractiveMode = computed(() => {
  return ideStore.interactiveMode
})
const isCompileLanguage = computed(() => {
  return ideStore.ideMeta?.isCompile || ideStore.ideMeta?.language === 'java'
})
const isEditable = computed(() => {
  return pluginStore.isEditable
})
</script>

<template>
  <div
    class="flex h-full flex-col justify-between gap-1 print:break-inside-avoid print:border print:p-1"
  >
    <p class="heading-xsmall text-left text-sm">Result</p>
    <div v-show="showExecuteDetails" class="p-xsmall">
      <p v-show="!isInteractiveMode">
        CPU Time: {{ ideStore.cpuTime }} sec(s), Memory: {{ ideStore.memory }} kilobyte(s)
      </p>
      <p v-show="isCompileLanguage">compiled and executed in {{ ideStore.executionTime }} sec(s)</p>
    </div>
    <div v-if="!showExecuteDetails && isEditable" class="p-xsmall">
      <p>CPU Time: N/A sec(s), Memory: N/A kilobyte(s)</p>
      <p>compiled and executed in N/A sec(s)</p>
    </div>
    <div id="ideOutputEditor" class="h-full flex-1">
      <div
        :id="IDECONSTANT.OUTPUT_EDITOR"
        v-show="isOutputEditor"
        class="h-full w-full rounded-lg bg-black text-white"
      />
    </div>
  </div>
</template>
