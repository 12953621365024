<script setup lang="ts">
import { useRoute } from 'vue-router'

import ContentAppBreadcrumbUtilsService from '@/utils/content-app/contentAppBreadcrumb.utils'

const route = useRoute()
const _contentAppBreadcrumbUtilService = new ContentAppBreadcrumbUtilsService()

const basePath: string = _contentAppBreadcrumbUtilService.GetHomeUrl(route.path)
const baseTitle: string = 'DOCUMENTATION'

const paramsArray: string[] = _contentAppBreadcrumbUtilService.GetUrlParamsArray(route.path)
const paths: string[] = _contentAppBreadcrumbUtilService.GetPathsArray(paramsArray)
const pathTitles: string[] = _contentAppBreadcrumbUtilService.ConvertUrlStringToTitle(paramsArray)
</script>
<template>
  <nav class="d-flex justify-content-start flex-row">
    <ol class="flex min-w-0 flex-wrap items-center whitespace-nowrap" aria-label="Breadcrumb">
      <li class="breadcrumb_link text-primary text-sm">
        <router-link
          :to="{ path: `/${basePath}` }"
          class="item-center color-caviar hover:color-secondary flex"
          >{{ baseTitle }}
        </router-link>
      </li>
      <li
        v-show="
          index !== 0 &&
          (route.name === 'documentation-category-doc' ||
            route.name === 'documentation-subcategory-doc')
        "
        v-for="(path, index) in paths"
        :key="path"
        class="breadcrumb_link truncate text-sm"
      >
        <router-link
          :to="{ path: `/${basePath}${path}` }"
          class="item-center flex"
          :class="
            index == paths.length - 1
              ? 'disabled text-caviar/30 '
              : 'color-caviar hover:color-secondary'
          "
        >
          {{ pathTitles[index] }}
        </router-link>
      </li>
    </ol>
  </nav>
</template>
