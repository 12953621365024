import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useSSGStore = defineStore('ssg', () => {
  const isSSG = ref<boolean>(false)

  /**
   * Sets the visibility state of the mobile IDE Navbar navigation.
   * @param val set mobileIdeNav visible state
   */
  const setIsSSG = (val: boolean) => {
    isSSG.value = val
  }

  return {
    isSSG,
    setIsSSG
  }
})
