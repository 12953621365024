<script setup lang="ts">
import { computed } from 'vue'
import { useIdeStore } from '@/stores/ide.store'
import { usePluginStore } from '@/stores/plugin.store'
import { FILE_MESSAGE_TYPE } from '@/utils/ide'
import uploadService from '@/services/ide/upload.service'

const ideStore = useIdeStore()
const pluginStore = usePluginStore()

const isCusomPlugin = computed(() => {
  return pluginStore.isCusomPlugin
})
const isFileMessage = computed(() => {
  return ideStore.isFileUploadMessages !== null
})
const fileMessageType = computed(() => {
  return ideStore.isFileUploadMessages?.type || FILE_MESSAGE_TYPE.INFO
})
const fileMessage = computed(() => {
  return ideStore.isFileUploadMessages?.message || ''
})
const showInputFiles = computed(() => {
  return ideStore.isInputFiles && ideStore.isInputFiles.length > 0
})
const inputFiles = computed(() => {
  return ideStore.isInputFiles
})
const showOutputFiles = computed(() => {
  return ideStore.isOutputFiles && ideStore.isOutputFiles.length > 0
})

const outputFiles = computed(() => {
  return ideStore.isOutputFiles
})
const showFiles = computed(() => {
  return showInputFiles.value || showOutputFiles.value || isFileMessage.value
})
/**
 * Gets the download url for the input file
 * @param file The file name
 * @returns The url
 */
const getUrlInput = (file: string) => {
  return `/api/projectSync/downloadItem?projectKey=1001&name=/${encodeURI(file)}&type=input`
}
/**
 * Gets the download url for the output file
 * @param file The file name
 * @returns The url
 */
const getUrlOutput = (file: string) => {
  return `/api/projectSync/downloadItem?projectKey=1001&name=${encodeURI(file)}&type=output`
}
/**
 * remove the file
 * @param file The file name
 */
const removeFile = (file: string) => {
  uploadService.removeFile(file)
}
</script>

<template>
  <div>
    <div v-if="showFiles" :class="[{ 'absolute h-full w-full overflow-auto': isCusomPlugin }]">
      <div class="flex w-full flex-col justify-start gap-5 align-middle">
        <p
          v-if="isFileMessage"
          :class="[
            'p-xsmall',
            {
              success: fileMessageType === FILE_MESSAGE_TYPE.SUCCESS,
              error: fileMessageType === FILE_MESSAGE_TYPE.ERROR
            }
          ]"
        >
          {{ fileMessage }}
        </p>
        <div v-if="showInputFiles">
          <ul>
            <li v-for="file in inputFiles" :key="file" class="flex items-baseline gap-1">
              <button @click="removeFile(file)">
                <FontAwesomeIcon icon="fa-trash-can" class="h-3 w-3" />
              </button>
              <a :href="getUrlInput(file)" class="link-secondary p-xsmall">/uploads/{{ file }}</a>
            </li>
          </ul>
        </div>
        <div v-if="showOutputFiles">
          <p class="heading-xsmall text-sm">Files Generated</p>
          <ul>
            <li v-for="file in outputFiles" :key="file" class="flex items-baseline gap-1">
              <a :href="getUrlOutput(file)" class="link-secondary p-xsmall">{{ file }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <p v-if="!showFiles && isCusomPlugin" class="p-small text-secondary">No files available</p>
  </div>
</template>
