<script setup lang="ts">
import { type ICredit } from '@/utils/jDroid'
import { PLAN_ENUM } from '@/components/organisation/interface/IInstituteSubscriptionInfo'
import Button from '@/components/atoms/button/index.vue'

import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import jdroidService from '@/services/ide/jdroid.service'
import { getSubscriptionInfo } from '@/services/teach.service'
import { useAuthStore } from '@/stores/auth.store'
import { useSubscribeStore } from '@/stores/subscribe.store'
import { useOrganisationStore } from '@/stores/organisation.store'
import { useAssessmentStore } from '@/stores/assessment.store'
import { useJdroidStore } from '@/stores/jdroid.store'
import { IDEVIEWMODELS, AUTHMODELHS } from '@/utils/models'
import { UNLIMITED_LIMIT } from '@/utils/sharedData/plans'
import { useRoute } from 'vue-router'

const props = defineProps({
  isChatJdroid: {
    type: Boolean,
    required: false,
    default: false
  }
})

const route = useRoute()
const authStore = useAuthStore()
const subscribeStore = useSubscribeStore()
const jdroidStore = useJdroidStore()
const organisationStore = useOrganisationStore()
const assessmentStore = useAssessmentStore()

const quota = computed(() => jdroidStore.quota)
const dispalyQuota = computed(() => (quota.value === UNLIMITED_LIMIT ? 'Unlimited' : quota.value))
const used = computed(() => jdroidStore.used)

const isHttpLoading = ref<boolean>(false)
const httpError = ref<string | null>(null)

const isInitiated = computed(() => {
  return authStore.isInitiated
})
const isLoggedIn = computed(() => {
  return authStore.isInitiated && authStore.isUserloggedIn
})
const isFetchingSubscriptionInfo = computed(() => {
  return subscribeStore.isFetchingSubscriptionInfo
})
const accountType = computed(() => {
  return (organisationStore.instituteSubscriptionInfo?.plan as string) || null
})
const hsModel = computed(() => {
  if (!isLoggedIn.value) {
    // Redirect to chatjdroid after login
    return AUTHMODELHS.LOGIN
  } else return IDEVIEWMODELS.JDROIDGETMORECREDITS
})

const chatList = computed(() => jdroidStore.chatList.length)

const planName = computed(() => {
  if (accountType.value === PLAN_ENUM.FREE) {
    return 'Free'
  } else if (accountType.value === PLAN_ENUM.PRO) {
    return 'Pro'
  } else if (accountType.value === PLAN_ENUM.TEAM) {
    return 'Team'
  } else if (accountType.value === PLAN_ENUM.CUSTOM) {
    return 'Custom'
  } else {
    return null
  }
})

const isFreePlan = computed(() => {
  return accountType.value === PLAN_ENUM.FREE
})

const promoteGetCredit = computed(() => {
  return quota.value !== UNLIMITED_LIMIT
})

const useagePrecentage = computed(() => {
  if (quota.value === UNLIMITED_LIMIT) {
    return 100
  }
  return Math.floor(((quota.value - used.value) / quota.value) * 100)
})

const remainingCredits = computed(() => {
  if (quota.value === UNLIMITED_LIMIT) {
    return 'Unlimited'
  }
  return quota.value - used.value
})

const isOwner = computed(() => {
  return useOrganisationStore().isOwner
})
/**
 * post init details
 * @param count - number of times to retry
 */
const postInitDetails = async (count: number = 0) => {
  if (!isInitiated.value) {
    if (count > 40) {
      return
    } else {
      await new Promise((resolve) => setTimeout(resolve, 100))
      postInitDetails(count + 1)
    }
  } else {
    httpError.value = null
    isHttpLoading.value = true
    if (isInitiated.value)
      await jdroidService
        .getCredits()
        .then((res: ICredit) => {
          jdroidStore.setCredits(res)
        })
        .catch((err: string) => {
          httpError.value = err
        })
        .finally(() => {
          isHttpLoading.value = false
        })
  }
}
/**
 * toggle details
 */
const toggleDetails = async () => {
  await postInitDetails()
}

/**
 * get subscription info
 */
const fetchSubscriptionInfo = async () => {
  subscribeStore.setIsFetchingSubscriptionInfo(true)
  if (organisationStore.instituteSubscriptionInfo && isOwner.value) {
    try {
      const res = await getSubscriptionInfo()
      organisationStore.setSubscriptionInfo(res)
      assessmentStore.setSubscriptionInfo(res)
    } catch (error) {
      subscribeStore.setIsFetchingSubscriptionInfo(false)
    }
  }
  subscribeStore.setIsFetchingSubscriptionInfo(false)
}

onMounted(async () => {
  toggleDetails()
  isHttpLoading.value = false
  httpError.value = null

  if (isLoggedIn.value) await fetchSubscriptionInfo()
  watch(isLoggedIn, async (value) => {
    if (value) {
      await fetchSubscriptionInfo()
      toggleDetails()
    }
  })
  watch(chatList, () => {
    isHttpLoading.value = false
    httpError.value = null
  })
})
onBeforeUnmount(() => {
  isHttpLoading.value = false
  httpError.value = null
})

watch(
  () => jdroidStore.getMoreCreditsUpdated,
  async () => {
    if (jdroidStore.getMoreCreditsUpdated && isOwner.value) {
      toggleDetails()
    }
  }
)
</script>

<template>
  <div v-show="promoteGetCredit">
    <div v-show="isInitiated && !isFetchingSubscriptionInfo" class="flex min-w-fit grow">
      <div v-if="!isHttpLoading && quota">
        <!-- IDE chat -->
        <div v-if="!isChatJdroid" class="flex w-full flex-wrap justify-end py-2 pr-0.5 sm:pr-2">
          <div class="flex items-center gap-2 pr-3">
            <div
              v-if="planName != null && isOwner"
              class="text-center text-xs font-semibold capitalize"
            >
              {{ planName }} Plan
            </div>
            <div class="flex w-fit items-center gap-2">
              <div class="h-2 w-[50px] rounded-2xl bg-slate-500 p-0.5 lg:w-[100px]">
                <div
                  class="left-0 top-0 z-10 h-1 rounded-2xl bg-green-300"
                  :style="{ width: useagePrecentage + '%' }"
                ></div>
              </div>
              <span class="text-center text-xs font-semibold capitalize"
                >{{ remainingCredits }}/{{ dispalyQuota }} </span
              ><span class="text-[11px]">Credits</span>
            </div>
          </div>

          <div class="text-end">
            <Button
              v-if="!(isOwner && !isFreePlan)"
              variant="link"
              :data-hs-overlay="`#${hsModel}`"
              class="whitespace-nowrap py-0 pl-0 text-xs sm:text-sm"
            >
              Get <span class="inline"> More</span>
              Credits
              <FontAwesomeIcon icon="fa-arrow-right" class="h-3 w-3" style="font-weight: bold" />
            </Button>
          </div>
        </div>
        <!-- Chat jdroid credits -->
        <div v-else class="h-9 text-xs text-neutral-500 sm:h-6">
          Remaining credits
          <span class="font-semibold text-black">{{ remainingCredits }}/{{ dispalyQuota }}</span
          >, to get unlimited credits
          <button class="font-semibold text-black underline" :data-hs-overlay="`#${hsModel}`">
            subscribe
          </button>
        </div>
      </div>
      <div v-else class="flex h-full w-full items-center justify-end p-2 sm:justify-between">
        <span class="text-center text-xs font-semibold tracking-[0.25em]">Loading...</span>
      </div>
    </div>
  </div>
</template>
