import axios from 'axios'

const STRAPI_KEYS = {
  READONLY:
    'ac529e35c0879a42e1ac21a9eb7d876c6e40ad8f992406b1d343b65407b90c9d02ad5cd5e1751b5078b6f755936264786f2203f23b9b5c57294cce11ff4a3b5781148c68a6e89e816e7909b8679d304e01c13227abe4162cf3bfd18a36283e5cd1e83f32cf85304d01fd2eed035385b415723f7ae5a9e079dbccfcb35d083fb6',
  READONLY_TEST02:
    '4b60d67142ce631f9b7a17ef92ce182e7ec710af8e7f5db32a85f5d9f2dcafa8c66d848953e8010b159bf82cb92d6f1dc1cae4be2fb7adfbc8b66eee75bd5fd5828c759371c2393cc727405bf14a524a4d26c62185a84c6e19b243fa86918eca6242d5d63519c6c7169501256458aabf20e41d3765a26dbca3f36520a0d2bbd3',
  DEV: '3d73d32638fffc508515bd1ff68c17a4a569eb69eaaf665e10f760783ae85052d72fa82b7c2ca6034e8db37f9284e62e1cfd604b4954837fb4d9c08933c714c000b9cf01e241dfdc79cd498718fd1682d9cec00036865a54ac63d29b96e4bff44edca90165dec901042af6a6686cdce9e96b653557703a49ebcbd178a861cb03',
  DEV_LIANNA:
    '2d53398672b3dafcf6a63022e2ebf0c8a4d50444d5736a0a913daf01070574dfdbd2b558c4889376c3c949c63488495c3bf84b42748b11d3315efa8fc328ed1aa89711eb3b32089827e42dc9ffb12b67f20e13a052cddecc9946b6602c6d8e455e93d5a358d2ba5966ced9a57dceda8f8db1e65c47dfe25947767d925e181bff'
}

export const $contentAppAxios = axios.create({
  //baseURL: import.meta.env.VITE_STRAPI_API_URL,
  timeout: 5000,
  headers: { Authorization: `Bearer ${STRAPI_KEYS.READONLY}` }
})
