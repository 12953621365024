import { IAssociationCode } from '@/components/organisation/interface/IAssociationType'

export interface IInstituteSubscriptionInfo {
  // null means unlimited
  limit: {
    users: {
      //Assessments
      [IAssociationCode.STUDENT]: number | null
      [IAssociationCode.TEACHER]: number | null
      [IAssociationCode.INSTITUTE_ADMIN]: number | null

      //Embed
      [IAssociationCode.EMBED_ADMIN]: number | null
      [IAssociationCode.EMBED_VIEWER]: number | null
      //IDE
      [IAssociationCode.FREE_IDE]: number | null
      [IAssociationCode.PREMIUM_IDE]: number | null
      //API
      [IAssociationCode.API_ADMIN]: number | null
      [IAssociationCode.API_VIEWER]: number | null

      //ORG
      [IAssociationCode.ORGANISATION_ADMIN]: number | null
    }
    activeAssignments: number | null
    draftAssignments: number | null
    questions: number | null
    premiumEmbeds: number
    ultraEmbeds: number
    domains: number
  }
  plan: PLAN_ENUM
}

export enum PLAN_ENUM {
  FREE = 'pFree',
  PRO = 'Pro',
  TEAM = 'Team',
  CUSTOM = 'Custom'
}
