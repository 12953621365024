declare global {
  interface Window {
    __PRERENDER_INJECTED: boolean
  }
  interface ace {
    Editor: any
  }
}

import type { AI_TAB } from '@/components/organisation/enum/ai.enum'
import { FILE_MESSAGE_TYPE } from '@/utils/ide'
import { type IMeta } from '@/utils/meta'

import { useJdroidStore } from '@/stores/jdroid.store'

import { TAB_BUTTON_POSITION, TAB_ICONS_NAMES } from '@/utils/ide'
import { IDETABSVIEW } from '@/utils/tabs'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

type TActiveItem = {
  parent?: string
  name?: string
  content?: string
  dirtyActions?: object[]
  codeChanged?: boolean
  isDirty?: boolean
}
type TFileMessage = {
  type: (typeof FILE_MESSAGE_TYPE)[keyof typeof FILE_MESSAGE_TYPE]
  message: string | null
}

export interface ActiveTab {
  previousPosition?: string
  position: string
  visible: boolean
  tabName: string
}

interface ActiveTabPosition {
  activeTab: ActiveTab[]
}

interface TabSettings {
  visible: boolean
  position: string
}

export interface Tab {
  id: number
  name: keyof typeof TAB_ICONS_NAMES
  position: keyof typeof TAB_BUTTON_POSITION
  visible: boolean
}

export interface TabItem {
  position: keyof typeof TAB_BUTTON_POSITION
  tabName: keyof typeof TAB_ICONS_NAMES
  visible: boolean
}

export interface UpdateTabPositionsRequest {
  tabs: TabItem[]
}

interface TransformedTab {
  tabName: string
  position: string
  visible: boolean
  previousPosition: string
}

const defaultActiveTabs: ActiveTab[] = [
  {
    previousPosition: TAB_BUTTON_POSITION.RIGHT,
    position: TAB_BUTTON_POSITION.RIGHT,
    tabName: TAB_ICONS_NAMES.EXTERNAL_LIBRARIES,
    visible: false
  },
  {
    previousPosition: TAB_BUTTON_POSITION.RIGHT,
    position: TAB_BUTTON_POSITION.RIGHT,
    tabName: TAB_ICONS_NAMES.UPLOAD_FILES,
    visible: false
  },
  {
    previousPosition: TAB_BUTTON_POSITION.RIGHT,
    position: TAB_BUTTON_POSITION.RIGHT,
    tabName: TAB_ICONS_NAMES.IO,
    visible: false
  },
  {
    previousPosition: TAB_BUTTON_POSITION.RIGHT,
    position: TAB_BUTTON_POSITION.RIGHT,
    tabName: TAB_ICONS_NAMES.JDROID,
    visible: true
  },
  {
    previousPosition: TAB_BUTTON_POSITION.RIGHT,
    position: TAB_BUTTON_POSITION.RIGHT,
    tabName: TAB_ICONS_NAMES.BROWSER,
    visible: false
  }
]

export type TIdeSettings = {
  fontSize: number
  theme: string
  defaultLanguage: string
  defaultVersionIndex: number
  defaultExecutionMode: boolean
  autoSaveOnExecute: boolean
  autoCompleteCode: boolean
}

export type TExecutionHistory = {
  script: string
  args: string | null
  stdin: string | null
  libs: string[]
  output: string
  executedAt: number
  versionIndex: number
}

export type THtmlExecutionHistory = {
  htmlCode: string
  executedAt: number
}

export const useIdeStore = defineStore('ide', () => {
  const isDefaultIde = ref<boolean>(false)
  const isIDE = ref<boolean>(false)
  const mobileTab = ref<string | null>(null)
  const mobileHeightLessThan690 = ref<boolean>(false)
  const sideBarCollapsed = ref<boolean>(true)
  const settingPopupHs = ref<string | null>(null)
  const showStartCodingModal = ref<boolean>(false) /* true if StartCodingModal is visible */

  const syncInProgress = ref<boolean>(false)
  const editingInProcess = ref<boolean>(false)
  const routeMeta = ref<IMeta | null>(null)
  const ideMeta = ref<IMeta | null>(null)
  const fontSize = ref<number>(12)
  const ideSplit = ref<any | null>(null)
  const codeEditor = ref<ace['Editor'] | null>(null)
  const codeUpdated = ref<boolean>(false)
  const outputEditor = ref<ace['Editor'] | null>(null)
  const outputHistory = ref<string>('')
  const project = ref<any | null>(null)
  const activeItem = ref<TActiveItem>({})
  const syncErrorMessages = ref<string | null>('')
  const projectKey = ref<string | boolean>(false)

  const interactiveMode = ref<boolean>(false)
  const versionIndex = ref<number>(-1)
  const args = ref<string | null>(null)
  const stdin = ref<string | null>(null)
  const libraries = ref<string[]>([])
  const executionTime = ref<string | null>(null)
  const memory = ref<number | null>(null)
  const cpuTime = ref<number | null>(null)
  const errorAfterExecute = ref<boolean>(false)
  const aiIsLoading = ref<boolean>(false)
  const aiResponse = ref<string | null>(null)
  const aiTabSelected = ref<AI_TAB | null>(null)
  const aiGenerateCodeReq = ref<string>('')
  const aiGenerateCodeRes = ref<string | null>(null)

  const autoSaveOn = ref<boolean>(false)

  const isCodeExecuting = ref<boolean>(false)
  const socketClient = ref<any | null>(null)
  const wsNextId = ref<number>(0)
  const socketConnected = ref<boolean>(false)

  const uploadInProgress = ref<boolean>(false)
  const maxUploads = ref<number>(5)
  const maxFileSize = ref<number>(5000000)
  const inputFiles = ref<string[]>([])
  const isIdeSettingsCompleted = ref<boolean>(false)
  const ideSettings = ref<TIdeSettings | null>(null)
  const outputFiles = ref<string[]>([])
  const fileUploadMessages = ref<TFileMessage | null>(null)

  const projectTreeCollapsed = ref<boolean>(false)
  const projects = ref<any[]>([])
  const selectedProject = ref<any | null>(null)
  const projectEditor = ref<ace['Editor'] | null>(null)

  const storage = ref<any | null>(null)
  const isLocalStoreEnabled = ref<boolean>(false)
  const executionHistory = ref<TExecutionHistory[] | THtmlExecutionHistory[] | null>(null)
  const selectedExecutionHistory = ref<TExecutionHistory | THtmlExecutionHistory | null>(null)
  const executeCodeEditor = ref<ace['Editor'] | null>(null)
  const executeOutputEditor = ref<ace['Editor'] | null>(null)

  const copyEditor = ref<ace['Editor'] | null>(null)
  const isCopied = ref<boolean>(false)
  const downloadEditor = ref<ace['Editor'] | null>(null)
  const isDownloadSuccess = ref<string | null>(null)
  const isDownloadFailed = ref<string | null>(null)

  const openEditor = ref<ace['Editor'] | null>(null)
  const importedFromFiles = ref<boolean>(false)

  const isImportSuccess = ref<string | null>(null)
  const isImportFailed = ref<string | null>(null)

  const printBlocker = ref<boolean>(false)
  const fullScreen = ref<boolean>(false)
  const isTabMaximized = ref<boolean>(false)

  const ideAndJdroidIsSplit = ref<boolean>(false)
  const inputAndFileUploadsIsSplit = ref<boolean>(false)
  const libManagerAndLibIsSplit = ref<boolean>(false)

  let blocklyWorkspace: any | null = null
  const blocklyEditorLanguages = ref<any[]>([])

  const shareNotFound = ref<boolean>(false)
  const shareNotFoundHttpError = ref<string | null>(null)
  const sharedId = ref<string | null>(null)
  const collabId = ref<string | null>(null)

  const isEmbedded = ref<boolean>(false)
  const pymChild = ref<any | null>(null)

  const isGuru = ref<boolean>(false)

  const htmlDoctypeEditor = ref<ace['Editor'] | null>(null)
  const htmlHeadEditor = ref<ace['Editor'] | null>(null)
  const htmlBodyEditor = ref<ace['Editor'] | null>(null)
  const htmlJsEditor = ref<ace['Editor'] | null>(null)
  const htmlCssEditor = ref<ace['Editor'] | null>(null)

  const terminal = ref<any | null>(null)

  const openProjectID = ref<string | null>(null)
  const isSharedAdvancedHTML = ref<boolean>(false)

  const colorMode = ref<string | null>(null)

  const showProjectCodingModal = ref<boolean>(false)

  const activeTabPosition = ref<ActiveTabPosition>({
    activeTab: defaultActiveTabs
  })

  const openTabSettings = ref<TabSettings>({
    visible: false,
    position: ''
  })

  const resizeSettingsBar = ref<boolean>(false)

  const uniqueActiveItemAdvIde = ref<any[]>([])

  const ideFullScreen = ref<boolean>(false)

  const ioTabVisible = ref<boolean>(false)

  const showSettingSideBarMobile = ref<boolean>(true)

  const isTabsDataFromApi = ref<boolean>(false)

  const isLiveCodingActive = ref<boolean>(false)

  const currentIFrameSourceBrowser = ref<string>('')

  const isIdeView = computed(() => {
    return isIDE.value
  })
  const currentMobileTab = computed(() => {
    return mobileTab.value
  })
  const isSettingPopupHs = computed(() => {
    return settingPopupHs.value
  })
  const isWindowAce = computed(() => {
    return window['ace'] || null
  })
  const isAdvanced = computed(() => {
    return ideMeta.value?.isAdvanced || false
  })
  const isShared = computed(() => {
    return routeMeta.value?.isShared || false
  })
  const isInstantShare = computed(() => {
    return ideMeta.value?.isInstantShare || false
  })
  const isAceLanguageCode = computed(() => {
    if (ideMeta.value?.language === 'blockly') return 'python'
    if (ideMeta.value?.aceCode) return ideMeta.value.aceCode
    return ideMeta.value?.language
  })
  const isLanguage = computed(() => {
    return routeMeta.value?.language || ideMeta.value?.language || ''
  })
  const isLangDisplayName = computed(() => {
    return routeMeta.value?.langDisplayName || ideMeta.value?.langDisplayName || ''
  })
  const isProject = computed(() => {
    return project.value || null
  })
  const isProjectId = computed(() => {
    return project.value?.id || null
  })
  const isPlusVersions = computed(() => {
    if (ideMeta.value?.plusVersions && ideMeta.value?.plusVersions.length > 0) return true
    return false
  })
  const isPlusVersionsList = computed(() => {
    if (isPlusVersions.value) return ideMeta.value?.plusVersions || []
    return []
  })
  const isBasicVersions = computed(() => {
    return ideMeta.value?.versions || []
  })
  const isVersions = computed(() => {
    if (isAdvanced.value && isPlusVersions.value) {
      return isPlusVersionsList.value || []
    } else {
      return ideMeta.value?.versions || []
    }
  })

  const isAutoSaveOn = computed(() => {
    return autoSaveOn.value
  })
  const isWsNextId = computed(() => {
    return wsNextId.value
  })
  const isSocketConnected = computed(() => {
    return socketConnected.value
  })
  const isUploadInProgress = computed(() => {
    return uploadInProgress.value
  })
  const isMaxUploads = computed(() => {
    return maxUploads.value
  })
  const isMaxFileSize = computed(() => {
    return maxFileSize.value
  })
  const isInputFiles = computed(() => {
    return inputFiles.value
  })
  const isOutputFiles = computed(() => {
    return outputFiles.value
  })
  const isFileUploadMessages = computed(() => {
    return fileUploadMessages.value
  })
  const isProjects = computed(() => {
    return projects.value
  })
  const isSelectedProject = computed(() => {
    return selectedProject.value
  })
  const isStorage = computed(() => {
    return storage.value || null
  })
  const isExecutionHistory = computed(() => {
    return executionHistory.value
  })
  const isSelectedExecutionHistory = computed(() => {
    return selectedExecutionHistory.value
  })
  const isImportedFromFiles = computed(() => {
    return importedFromFiles.value
  })
  const isPrintBlocker = computed(() => {
    return printBlocker.value
  })
  const isFullScreen = computed(() => {
    return fullScreen.value
  })
  const isCodeUpdated = computed(() => {
    return codeUpdated.value
  })
  const isBlocklyWorkspace = computed(() => {
    return blocklyWorkspace
  })
  const isBlocklyEditorLanguages = computed(() => {
    return blocklyEditorLanguages.value
  })
  const isBlockly = computed(() => {
    return (
      routeMeta.value?.language === 'blockly' ||
      ideMeta.value?.language === 'blockly' ||
      project.value?.language === 'blockly'
    )
  })
  const isBlocklyLanguages = computed(() => {
    return ideMeta.value?.blocklyLanguages || []
  })
  const isBlocklyLanguageVersions = computed(() => {
    return ideMeta.value?.blocklyLanguageVersions || []
  })
  const isShareId = computed(() => {
    return sharedId.value || null
  })
  const isCollabId = computed(() => {
    return collabId.value || null
  })
  const isHtml = computed(() => {
    return routeMeta.value?.language === 'html' || ideMeta.value?.language === 'html'
  })
  const isTerminal = computed(() => {
    return ideMeta.value?.terminal || ''
  })

  const hasErrorAfterExecute = computed<boolean>(() => {
    return errorAfterExecute.value
  })

  const isSideBarCollapsed = computed<boolean>(() => {
    return sideBarCollapsed.value
  })

  /**
   * Set the default ide
   * @param value - The value to set
   */
  const setIsDefaultIde = (value: boolean) => {
    isDefaultIde.value = value
  }
  /**
   * Set the ide view
   * @param value - The value to set
   */
  const setIsIdeView = (value: boolean) => {
    isIDE.value = value
  }

  /**
   * Set the sidebar collapse
   * @param isCollapsed - The value to set
   */
  const setSideBarCollapsed = (isCollapsed: boolean) => {
    sideBarCollapsed.value = isCollapsed
  }

  /**
   * Set the mobile ide tab
   * @param tabId - The mobile tab constant string to set
   */
  const setMobileTab = (tabId: string | null) => {
    mobileTab.value = tabId
  }
  /**
   * Set the mobile ide tab
   * @param isLessThan690 - True or False
   */
  const setMobileHeightLessThan690 = (isLessThan690: boolean) => {
    mobileHeightLessThan690.value = isLessThan690
  }
  /**
   * Set the setting popup hs
   * @param value - The value to set
   */
  const setSettingPopupHs = (value: string | null) => {
    settingPopupHs.value = value
  }
  /**
   * Set editing in progress
   * @param value - The value to set
   */
  const setEditingInProgress = (value: boolean) => {
    editingInProcess.value = value
  }
  /**
   * Set the ide meta
   * @param meta - The meta to set
   */
  const setRouteMeta = (meta: IMeta) => {
    routeMeta.value = meta
  }
  /**
   * Set the ide meta
   * @param meta - The meta to set
   */
  const setIdeMeta = (meta: IMeta) => {
    ideMeta.value = meta
  }
  /**
   * set font size
   * @param size - The size to set
   */
  const setFontSize = (size: number) => {
    fontSize.value = size
  }
  /**
   * Set the project
   * @param data - The project to set
   */
  const setProject = (data: any | null) => {
    project.value = data
  }
  /**
   * Set the project
   * @param isCollapsed - True = collapsed, False = open
   */
  const setProjectTreeCollapsed = (isCollapsed: boolean) => {
    projectTreeCollapsed.value = isCollapsed
  }
  /**
   * Set the active item. usually done when init
   */
  const setActiveItem = () => {
    activeItem.value = project.value?.treeData.children.find((o: any) => {
      return '/' + o.name === project.value.home
    })
  }
  /**
   * Set the project key
   * @param key - The key to set
   */
  const setProjectKey = (key: string | boolean) => {
    projectKey.value = key
  }
  /**
   * Set the sync error messages. will be displayed in the project tree
   * @param value - The value to set
   */
  const setSyncErrorMessages = async (value: string | null) => {
    syncErrorMessages.value = value
    await new Promise((resolve) => setTimeout(resolve, 8000))
    syncErrorMessages.value = null
  }
  /**
   * reset the editor
   */
  const resetEditor = () => {
    args.value = ''
    stdin.value = ''
    libraries.value = []
    executionTime.value = null
    memory.value = 0
    cpuTime.value = 0
  }
  /**
   * reset the execution time
   */
  const resetExecutionTime = () => {
    executionTime.value = null
    memory.value = null
    cpuTime.value = null
  }
  /**
   * Set the execution time
   * @param value - The value to set
   */
  const setExecutionqTime = (value: string) => {
    executionTime.value = value
  }
  /**
   * Set if the shared html project is advanced
   * @param value - The value to set
   */
  const setIsSharedAdvancedHTML = (value: boolean) => {
    isSharedAdvancedHTML.value = value
  }
  /**
   * set the initial version index
   */
  const initVersionIndex = () => {
    const tempStartCoadingVersion = useJdroidStore().startCoadingVersion
    useJdroidStore().setStartCoadingVersion(null)
    if (isGuru.value) return
    if (isAdvanced.value && isPlusVersions.value) {
      if (
        (ideSettings.value?.defaultVersionIndex || ideMeta.value?.defaultVersion) &&
        ideMeta.value?.versions &&
        ideMeta.value?.versions.length > 0
      ) {
        if (ideSettings.value?.defaultVersionIndex && isIdeView.value) {
          const index = isPlusVersionsList.value.findIndex((v: string) => {
            return v === ideMeta.value?.versions![ideSettings.value?.defaultVersionIndex!]
          })
          versionIndex.value = index > -1 ? index : isPlusVersionsList.value.length - 1
        } else {
          const index = isPlusVersionsList.value.findIndex((v: string) => {
            return v === ideMeta.value?.versions![ideMeta.value?.defaultVersion!]
          })
          versionIndex.value = index > -1 ? index : isPlusVersionsList.value.length - 1
        }
      } else versionIndex.value = isPlusVersionsList.value.length - 1
    } else {
      if (isVersions.value.length > 0) {
        if (ideSettings.value?.defaultVersionIndex && isIdeView.value) {
          const defaultLanguage = ideSettings.value.defaultLanguage
          if (isLanguage.value === defaultLanguage) {
            versionIndex.value = ideSettings.value.defaultVersionIndex
          } else {
            versionIndex.value = ideMeta.value?.defaultVersion
              ? ideMeta.value?.defaultVersion
              : isVersions.value.length - 1
          }
        } else {
          versionIndex.value = ideMeta.value?.defaultVersion
            ? ideMeta.value?.defaultVersion
            : isVersions.value.length - 1
        }
      } else {
        versionIndex.value = -1
      }
    }
    if (isIdeView.value && tempStartCoadingVersion !== null) {
      const index = isVersions.value.findIndex((v: string) => {
        return v === tempStartCoadingVersion
      })
      if (index > -1) versionIndex.value = index
    }
  }
  /**
   * Set the version
   * @param index - The version index to set
   */
  const setVersionIndex = (index: number) => {
    versionIndex.value = index
  }

  /**
   * Set the isCodeExecuting
   * @param value - The value to set
   */
  const setisCodeExecuting = (value: boolean) => {
    isCodeExecuting.value = value
  }
  /**
   * Set output files
   * @param files - The files to set
   */
  const setOutputFiles = (files: string[]) => {
    outputFiles.value = files
  }
  /**
   * reset stdin
   */
  const resetStdin = () => {
    stdin.value = null
  }
  /**
   * Set the execution data
   * @param data - The data to set
   */
  const setExcecutionData = (data: any) => {
    executionTime.value = data.executeTime
    memory.value = data.memory
    cpuTime.value = data.cpuTime
    outputFiles.value = data.outputFiles
  }
  /**
   * Set socket next id
   * @param value - The value to set
   */
  const setwsNextId = (value: number) => {
    wsNextId.value = value
  }
  /**
   * Set socket connected
   * @param value - The value to set
   */
  const setSocketConnected = (value: boolean) => {
    socketConnected.value = value
  }
  /**
   * Set upload in progress
   * @param value - The value to set
   */
  const setUploadInProgress = (value: boolean) => {
    uploadInProgress.value = value
  }
  /**
   * Set input files
   * @param files - The files to set
   */
  const setInputFiles = (files: string[]) => {
    inputFiles.value = files
  }
  /**
   * Set ide settings
   * @param settings - The IDE settings
   * @param state ide setting value got or not
   */
  const setIdeSettings = (settings: TIdeSettings | null, state: boolean) => {
    ideSettings.value = settings
    isIdeSettingsCompleted.value = state
  }
  /**
   * Remove input file
   * @param file - The file to remove
   */
  const removeInputFile = (file: string) => {
    inputFiles.value.splice(inputFiles.value.indexOf(file), 1)
  }
  /**
   * Set file error messages
   * @param type - The value to set
   * @param value - The value to set
   */
  const setFileUploadMessages = async (
    type: (typeof FILE_MESSAGE_TYPE)[keyof typeof FILE_MESSAGE_TYPE],
    value: string | null
  ) => {
    fileUploadMessages.value = {
      type: type,
      message: value
    }
    await new Promise((resolve) => setTimeout(resolve, 5000))
    fileUploadMessages.value = null
  }
  /**
   * Set projects
   * @param data - The data to set
   */
  const setProjects = (data: any[]) => {
    projects.value = data
  }
  /**
   * Set selected project
   * @param data - The data to set
   */
  const setSelectedProject = (data: any | null) => {
    selectedProject.value = data
  }
  /**
   * Set the isLocalStoreEnabled
   * @param value - The value to set
   */
  const setIsLocalStoreEnabled = (value: boolean) => {
    isLocalStoreEnabled.value = value
  }
  /**
   * Set the storage
   * @param value - The value to set
   */
  const setStotage = (value: any | null) => {
    storage.value = value
  }
  /**
   * Set the execution history
   * @param value - The value to set
   */
  const setExecutionHistory = (value: any[] | null) => {
    executionHistory.value = value
  }
  /**
   * Set the selected execution history
   * @param value - The value to set
   */
  const setSelectedExecutionHistory = (value: TExecutionHistory | THtmlExecutionHistory | null) => {
    selectedExecutionHistory.value = value
  }
  /**
   * Set the copy editor
   * @param value - The value to set
   */
  const setIsCopied = (value: boolean) => {
    isCopied.value = value
  }
  /**
   * Set the download success message
   * @param value - The value to set
   */
  const setDownloadSuccess = (value: string | null) => {
    isDownloadSuccess.value = value
  }
  /**
   * Set the download failed message
   * @param value - The value to set
   */
  const setDownloadFailed = (value: string | null) => {
    isDownloadFailed.value = value
  }

  /**
   * Set the execute code editor
   * @param value - The value to set
   */
  const setImportedFromFiles = (value: boolean) => {
    importedFromFiles.value = value
  }
  /**
   * Set the import success
   * @param value - The value to set
   */
  const setImportSuccess = (value: string | null) => {
    isImportSuccess.value = value
  }
  /**
   * Set import failed
   * @param value - The value to set
   */
  const setImportFailed = (value: string | null) => {
    isImportFailed.value = value
  }
  /**
   * Set project editable share url
   * @param url - The url to set
   */
  const setEditableShareUrl = (url: string | null) => {
    project.value.url = url
  }
  /**
   * Set project editable share embed url
   * @param embedUrl - The embed url to set
   */
  const setEditableShareEmbedUrl = (embedUrl: string | null) => {
    project.value.embedUrl = embedUrl
  }
  /**
   * Set the pluginId
   * @param pluginId - The value to set
   */
  const setEditableSharePluginId = (pluginId: string | null) => {
    project.value.pluginId = pluginId
  }
  /**
   * Set the isProBundlePlan
   * @param value - The value to set
   */
  const setIsProBundlePlan = (value: boolean) => {
    project.value.isProBundlePlan = value
  }
  /**
   * Set the print blocker
   * @param value - The value to set
   */
  const setPrintBlocker = (value: boolean) => {
    printBlocker.value = value
  }
  /**
   * Set the full screen
   * @param value - The value to set
   */
  const setFullScreen = (value: boolean) => {
    fullScreen.value = value
  }
  /**
   * Set the code updated
   * @param value - The value to set
   */
  const setCodeUpdated = (value: boolean) => {
    codeUpdated.value = value
  }
  /**
   * Set the code editor
   * @param value - The value to set
   */
  const setOutputHistory = (value: string) => {
    outputHistory.value = value
  }
  /**
   * Set the blockly workspace
   * @param value - The value to set
   */
  const setBlocklyWorkspace = (value: any) => {
    blocklyWorkspace = value
  }
  /**
   * Set the blockly editor languages
   * @param value - The value to set
   */
  const setBlocklyEditorLanguages = (value: any[]) => {
    blocklyEditorLanguages.value = value
  }
  /**
   * Set the blockly editor languages
   * @param value - The value to set
   */
  const setShareNotFound = (value: boolean) => {
    shareNotFound.value = value
  }
  /**
   * Set shareNotFoundHttpError value
   * @param value - The value to set
   */
  const setShareNotFoundHttpError = (value: string | null) => {
    shareNotFoundHttpError.value = value
  }
  /**
   * Set the shared id
   * @param value - The value to set
   */
  const setSharedId = (value: string | null) => {
    sharedId.value = value
  }
  /**
   * Set the collaborator id
   * @param value - The value to set
   */
  const setCollabId = (value: string | null) => {
    collabId.value = value
  }
  /**
   * Set the isEmbedded
   * @param value - The value to set
   */
  const setIsEmbedded = (value: boolean) => {
    isEmbedded.value = value
  }
  /**
   * Set the pym child
   * @param value - The value to set
   */
  const setPymChild = (value: any | null) => {
    pymChild.value = value
  }

  /**
   * Set the isGuru
   * @param value - The value to set
   */
  const setIsGuru = (value: boolean) => {
    isGuru.value = value
  }
  /**
   * Set the html expand
   * @param value - The value to set
   */
  const setOpenProjectID = (value: string | null) => {
    openProjectID.value = value
  }

  /**
   * Sets error after execute (Jdoodle AI button and tab)
   * @param hasError - Boolean of any errors after ide execution
   */
  const setHasErrorAfterExecute = (hasError: boolean) => {
    errorAfterExecute.value = hasError
  }

  /**
   * Set interactiveMode
   * @param val boolean
   */
  const setInteractiveMode = (val: boolean) => {
    interactiveMode.value = val
  }

  /**
   * Set autoSaveOn
   * @param val boolean
   */
  const setAutoSaveOn = (val: boolean) => {
    autoSaveOn.value = val
  }
  /**
   * Set color mode
   * @param val color mode value
   */
  const setColorMode = (val: string | null) => {
    colorMode.value = val
  }
  /**
   * Change visibility status of Start Coding Modal
   * @param value to update state
   */
  const setShowStartCodingModal = (value: boolean = false) => {
    showStartCodingModal.value = value
  }

  /**
   * Sync tab position values in pinia
   * @param value is new
   */
  const syncActiveTabPosition = (value: ActiveTabPosition) => {
    activeTabPosition.value = value
  }

  /**
   * Sets the status of the tab icon for a specific tab.
   * @param position The new position of the tab icon.
   * @param tabName The name of the tab to update or add.
   * @param currentView The current view of the application(this is optional)
   */
  const setActiveTabPosition = (position: string, tabName: string, currentView?: string) => {
    const index = activeTabPosition.value.activeTab.findIndex((item) => item.tabName === tabName)
    if (index !== -1) {
      activeTabPosition.value.activeTab[index].previousPosition =
        activeTabPosition.value.activeTab[index].position
      const currentStatus = activeTabPosition.value.activeTab[index].visible
      activeTabPosition.value.activeTab[index].position = position
      if (
        activeTabPosition.value.activeTab[index].position ==
        activeTabPosition.value.activeTab[index].previousPosition
      ) {
        activeTabPosition.value.activeTab[index].visible = !currentStatus
      } else {
        if (activeTabPosition.value.activeTab[index].position == TAB_BUTTON_POSITION.MAXIMIZE) {
          activeTabPosition.value.activeTab[index].visible = true
        }
        if (currentView == IDETABSVIEW.JDROIDCHAT) {
          activeTabPosition.value.activeTab[index].visible = true
        }
        if (activeTabPosition.value.activeTab[index].visible) {
          activeTabPosition.value.activeTab[index].visible = true
        }
      }
      activeTabPosition.value.activeTab.forEach((item) => {
        if (item.position == position && item.tabName != tabName) {
          item.visible = false
        }
      })
    } else {
      const currentStatus = false
      activeTabPosition.value.activeTab.push({ position, tabName, visible: !currentStatus })
    }
  }

  /**
   * Reset Maximized tabs to it's previous position
   */
  const resetMaximizedTabs = () => {
    activeTabPosition.value.activeTab.forEach((tab) => {
      if (tab.position === TAB_BUTTON_POSITION.MAXIMIZE) {
        tab.position = tab.previousPosition!
      }
    })
  }

  /**
   * Reset External Library tabs
   */
  const resetExternalLibraryTabs = () => {
    activeTabPosition.value.activeTab.forEach((tab) => {
      if (tab.tabName === TAB_ICONS_NAMES.EXTERNAL_LIBRARIES) {
        tab.position = tab.previousPosition!
        tab.visible = false
      }
    })
  }

  /**
   * Sets the tab settings based on the provided value.
   * @param value - An object containing the new tab settings.
   * @param value.visible - The visibility state of the tab.
   * @param value.position - The position of the tab.
   */
  const setTabSettings = (value: { visible: boolean; position: string }) => {
    openTabSettings.value.visible = value.visible
    openTabSettings.value.position = value.position
  }

  /**
   * Set the tab maximized screen
   * @param value - The value to set
   */
  const setTabMaximized = (value: boolean) => {
    isTabMaximized.value = value
  }

  /**
   * Set the resize setting bar
   * @param value - The value to set
   */
  const setResizeSettingBar = (value: boolean) => {
    resizeSettingsBar.value = value
  }

  /**
   * Set the unique active item
   * @param val item to add
   */
  const setUniqueActiveItemAdvIde = (val: any) => {
    const newValue = uniqueActiveItemAdvIde.value
    if (newValue && !uniqueActiveItemAdvIde.value.includes(val)) {
      uniqueActiveItemAdvIde.value.push(val)
    }
  }
  /**
   * clear and Set the unique active item
   * @param val item to add
   */
  const setCleanUniqueActiveItemAdvIde = (val: any | null = null) => {
    uniqueActiveItemAdvIde.value = []
    if (val) {
      setUniqueActiveItemAdvIde(val)
    }
  }

  /**
   *
   * @param val item to remove
   */
  const removeUniqueActiveItemAdvIde = (val: any) => {
    const filterValue = uniqueActiveItemAdvIde.value.filter((item) => item.name != val.name)
    uniqueActiveItemAdvIde.value = filterValue
  }
  /**
   *
   * @param val set ioTab visible state
   */
  const setIOTabVisible = (val: boolean) => {
    ioTabVisible.value = val
  }
  /**
   *
   * @param val set showSettingSideBarMobile visible state
   */
  const setShowSettingSideBarMobile = (val: boolean) => {
    showSettingSideBarMobile.value = val
  }
  /**
   * Transforms an array of tabs to include tab names and positions based on predefined enums.
   * @param tabs - The array of tabs to transform.
   * @returns an array of tabPositions
   */
  const transformTabs = (tabs: Tab[]): TransformedTab[] => {
    return tabs.map((item) => {
      return {
        tabName: TAB_ICONS_NAMES[item.name],
        visible: item.visible,
        position: TAB_BUTTON_POSITION[item.position],
        previousPosition: TAB_BUTTON_POSITION[item.position]
      }
    })
  }
  /**
   * Updates the default tab position with the db tab data.
   * @param data - The array of tab objects to transform and update.
   */
  const updateDefaultTabPosition = (data: Tab[]) => {
    const transformedDbData = transformTabs(data)

    activeTabPosition.value.activeTab =
      transformedDbData.length > 0 ? transformedDbData : defaultActiveTabs
  }
  /**
   * set visiblity state for isTabsDataFromApi
   * @param value visiblity state of isTabsDataFromApi
   */
  const setIsTabDateFromApi = (value: boolean) => {
    isTabsDataFromApi.value = value
  }

  /**
   * Change visibility status of Project Modal
   * @param value to update state
   */
  const setShowProjectCodingModal = (value: boolean = false) => {
    showProjectCodingModal.value = value
  }

  /**
   * opening the live coding modal
   * @param value to update state
   */
  const setIsLiveCodingActive = (value: boolean = false) => {
    isLiveCodingActive.value = value
  }
  /**
   * update the current tab positions array from local storage
   * @param tabsPosition current tab positions array
   */
  const updateTabPositionFromLocalStorage = (tabsPosition: ActiveTab[]) => {
    activeTabPosition.value.activeTab = tabsPosition || defaultActiveTabs
  }

  /**
   * update the current url of browser
   * @param value current url of browser
   */
  const setCurrentIframeSourceBrowser = (value: string) => {
    currentIFrameSourceBrowser.value = value
  }

  return {
    isDefaultIde,
    setIsDefaultIde,
    isIdeView,
    setIsIdeView,
    isSideBarCollapsed,
    setSideBarCollapsed,
    currentMobileTab,
    setMobileTab,
    mobileHeightLessThan690,
    setMobileHeightLessThan690,
    isSettingPopupHs,
    setSettingPopupHs,
    syncInProgress,
    editingInProcess,
    setEditingInProgress,
    routeMeta,
    setRouteMeta,
    ideMeta,
    setIdeMeta,
    fontSize,
    setFontSize,
    ideSplit,
    codeEditor,
    outputEditor,
    ideAndJdroidIsSplit,
    inputAndFileUploadsIsSplit,
    libManagerAndLibIsSplit,
    resetEditor,
    resetExecutionTime,
    setExecutionqTime,
    project,
    setProject,
    activeItem,
    setActiveItem,
    projectKey,
    setProjectKey,
    syncErrorMessages,
    setSyncErrorMessages,
    isWindowAce,
    isAdvanced,
    projectTreeCollapsed,
    setProjectTreeCollapsed,
    isShared,
    isInstantShare,
    isAceLanguageCode,
    isLanguage,
    isLangDisplayName,
    isProject,
    isProjectId,
    isPlusVersions,
    isPlusVersionsList,
    isBasicVersions,
    isVersions,
    versionIndex,
    initVersionIndex,
    setVersionIndex,
    interactiveMode,
    setInteractiveMode,
    executionTime,
    cpuTime,
    memory,
    args,
    stdin,
    resetStdin,
    isCodeExecuting,
    setisCodeExecuting,
    isBlockly,
    isBlocklyLanguages,
    isBlocklyLanguageVersions,
    libraries,
    setExcecutionData,
    autoSaveOn,
    isAutoSaveOn,
    setAutoSaveOn,
    socketClient,
    wsNextId,
    isWsNextId,
    setwsNextId,
    isSocketConnected,
    setSocketConnected,
    isUploadInProgress,
    setUploadInProgress,
    isMaxUploads,
    isMaxFileSize,
    isInputFiles,
    setInputFiles,
    removeInputFile,
    isIdeSettingsCompleted,
    ideSettings,
    setIdeSettings,
    isOutputFiles,
    setOutputFiles,
    isFileUploadMessages,
    setFileUploadMessages,
    isProjects,
    setProjects,
    projectEditor,
    isSelectedProject,
    setSelectedProject,
    isLocalStoreEnabled,
    setIsLocalStoreEnabled,
    isStorage,
    setStotage,
    isExecutionHistory,
    setExecutionHistory,
    isSelectedExecutionHistory,
    setSelectedExecutionHistory,
    executeCodeEditor,
    executeOutputEditor,
    copyEditor,
    isCopied,
    setIsCopied,
    downloadEditor,
    isDownloadSuccess,
    setDownloadSuccess,
    isDownloadFailed,
    setDownloadFailed,
    openEditor,
    isImportedFromFiles,
    setImportedFromFiles,
    isImportSuccess,
    setImportSuccess,
    isImportFailed,
    setImportFailed,
    setEditableShareUrl,
    setEditableShareEmbedUrl,
    setEditableSharePluginId,
    setIsProBundlePlan,
    isPrintBlocker,
    setPrintBlocker,
    isFullScreen,
    setFullScreen,
    isCodeUpdated,
    setCodeUpdated,
    outputHistory,
    setOutputHistory,
    isBlocklyWorkspace,
    setBlocklyWorkspace,
    isBlocklyEditorLanguages,
    setBlocklyEditorLanguages,
    isShareId,
    setSharedId,
    isCollabId,
    setCollabId,
    shareNotFound,
    setShareNotFound,
    shareNotFoundHttpError,
    setShareNotFoundHttpError,
    isEmbedded,
    setIsEmbedded,
    pymChild,
    setPymChild,
    isGuru,
    setIsGuru,
    htmlDoctypeEditor,
    htmlHeadEditor,
    htmlBodyEditor,
    htmlJsEditor,
    htmlCssEditor,
    isHtml,
    terminal,
    isTerminal,
    setOpenProjectID,
    openProjectID,
    hasErrorAfterExecute,
    setHasErrorAfterExecute,
    aiIsLoading,
    aiTabSelected,
    aiResponse,
    aiGenerateCodeReq,
    aiGenerateCodeRes,
    isSharedAdvancedHTML,
    setIsSharedAdvancedHTML,
    colorMode,
    setColorMode,
    showStartCodingModal,
    setShowStartCodingModal,
    openTabSettings,
    setTabSettings,
    isTabMaximized,
    setTabMaximized,
    resizeSettingsBar,
    setResizeSettingBar,
    activeTabPosition,
    setActiveTabPosition,
    syncActiveTabPosition,
    uniqueActiveItemAdvIde,
    setCleanUniqueActiveItemAdvIde,
    setUniqueActiveItemAdvIde,
    removeUniqueActiveItemAdvIde,
    ideFullScreen,
    ioTabVisible,
    setIOTabVisible,
    resetMaximizedTabs,
    resetExternalLibraryTabs,
    showSettingSideBarMobile,
    setShowSettingSideBarMobile,
    updateDefaultTabPosition,
    showProjectCodingModal,
    setShowProjectCodingModal,
    updateTabPositionFromLocalStorage,
    isTabsDataFromApi,
    setIsTabDateFromApi,
    isLiveCodingActive,
    setIsLiveCodingActive,
    currentIFrameSourceBrowser,
    setCurrentIframeSourceBrowser
  }
})
