/* eslint-disable jsdoc/require-jsdoc */
import { META } from '@/utils/meta'
import ChatJdroid from '@/views/jdroid/ChatJdroid.vue'
import JdroidAnswers from '@/views/jdroid/JdroidAnswers.vue'
import { type RouteRecordRaw } from 'vue-router'

const jdroidRoutes: Array<RouteRecordRaw> = [
  {
    path: '/chat-jdroid',
    name: 'jdroid-chat',
    component: ChatJdroid,
    meta: META.chatJdroid
  },
  {
    path: '/jdroid-answers/:pathIndex?',
    name: 'jdroid-answers',
    component: JdroidAnswers,
    meta: META.chatJdroid
  },
  {
    path: '/chat-jdroid/:initMode/:questionId/:questionTitle',
    name: 'jdroid-chat-with-question',
    component: ChatJdroid,
    meta: META.chatJdroid
  }
]

export default jdroidRoutes
