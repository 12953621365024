<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from 'vue'
import TutorialsService from '@/services/content-app/tutorials.service'
import { useNavStore } from '@/stores/nav.store'
import LanguageCard from '@/components/content-app/tutorials/TutorialsHome/LanguageCard.vue'
import type ILanguage from '@/components/content-app/interface/tutorials/ILanguage'

const navStore = useNavStore()
const _tutorialsService = new TutorialsService()
const languagesData = ref([] as ILanguage[])
const popularLanguages = ref([] as ILanguage[])
const otherLanguages = ref([] as ILanguage[])
const otherLanguages_filtered = ref([] as ILanguage[])

onMounted(async () => {
  navStore.setIsMarketingPage(true)
  const data = await _tutorialsService.getAllLanguages()
  // console.log('FOUND', data)
  languagesData.value = data

  popularLanguages.value = languagesData.value.filter(
    (lang: ILanguage) => lang.attributes.IsPopular == true
  )
  otherLanguages.value = languagesData.value.filter(
    (lang: ILanguage) => lang.attributes.IsPopular == false
  )

  otherLanguages_filtered.value = otherLanguages.value
})

onUnmounted(() => {
  navStore.setIsMarketingPage(false)
})

const searchInput = ref<string>('')

watch(
  () => searchInput.value,
  () => {
    //Return full list
    if (searchInput.value == '') {
      otherLanguages_filtered.value = otherLanguages.value
      return
    }
    //Filter for language
    otherLanguages_filtered.value = otherLanguages.value.filter((lang) =>
      lang.attributes.Name.toLocaleLowerCase().includes(searchInput.value.toLocaleLowerCase())
    )
  }
)
</script>
<template>
  <div class="view-container pb-10">
    <div class="my-10">
      <div class="mb-20 flex flex-col justify-center">
        <h1 class="heading-large mb-2">JDoodle Tutorials</h1>
        <p class="text-md">Created by JDoodle experts and experienced programmers worldwide.</p>
      </div>
      <div class="mb-28">
        <h2 class="heading-medium mb-10">Most Popular Languages</h2>
        <div class="grid gap-4 sm:grid-cols-2 sm:gap-6 md:grid-cols-3 lg:grid-cols-4">
          <language-card
            v-for="language in popularLanguages"
            :key="language.id"
            :language="language"
          ></language-card>
        </div>
      </div>
      <div class="mb-28">
        <div class="mb-10 flex flex-col items-start gap-6 sm:flex-row sm:items-center">
          <h2 class="heading-medium">All Other Languages</h2>
          <input
            v-model="searchInput"
            placeholder="Search"
            class="w-full rounded-full border px-3 py-1 sm:w-auto"
          />
        </div>
        <div class="grid gap-4 sm:grid-cols-2 sm:gap-6 md:grid-cols-3 lg:grid-cols-4">
          <language-card
            v-for="language in otherLanguages_filtered"
            :key="language.id"
            :language="language"
          ></language-card>
        </div>
      </div>
    </div>
  </div>
</template>
