import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

/**
 * @description cn fn allow to write conditional objects property and merge it using twMerge to resolve conflicts - shade cn
 * @param inputs that contains classnames
 * @returns merged string value
 */
export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs))
}
