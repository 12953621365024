import { GENERATE_CODE_TASK_TYPE } from '@/services/ide/generateCode.service'

export enum AI_TAB {
  GENERATE_NEW_CODE = 'Generate New Code',
  EXPLAIN_CODE = 'Explain Code',
  DEBUG_CODE = 'Debug Code',
  COMMENT = 'Comment Code',
  EXPLAIN_ERROR = 'Explain Error'
}

export const AI_CONSTANT_TAB_TASK = {
  [AI_TAB.GENERATE_NEW_CODE]: GENERATE_CODE_TASK_TYPE.GENERATE,
  [AI_TAB.EXPLAIN_CODE]: GENERATE_CODE_TASK_TYPE.EXPLAIN,
  [AI_TAB.COMMENT]: GENERATE_CODE_TASK_TYPE.COMMENT,
  [AI_TAB.EXPLAIN_ERROR]: GENERATE_CODE_TASK_TYPE.EXPLAIN_ERROR,
  [AI_TAB.DEBUG_CODE]: GENERATE_CODE_TASK_TYPE.DEBUG
}
