import editorService from '@/services/ide/editor.service'
import embedService from '@/services/ide/embed.service'
import executeService from '@/services/ide/execute.service'
import blocklyService from '@/services/ide/languages/blockly/blockly.service'
import pluginService from '@/services/ide/plugin/plugin.service'
import { useIdeStore } from '@/stores/ide.store'
import { usePluginStore } from '@/stores/plugin.store'
import { IDECONSTANT } from '@/utils/ide'
import { cloneDeep } from 'lodash-es'

/**
 * init upload input files feature
 * @param pluginData - The data from the setScript call
 */
const initGetFiles = (pluginData: any) => {
  if (pluginData?.hasFiles || pluginData.files) {
    if (!usePluginStore().isCusomPlugin) {
      if (pluginData.clientId) {
        usePluginStore().setClientkey(pluginData.clientId)
      } else {
        const message = `data-client-id is missing in the plugin script for upload input files feature.`
        usePluginStore().setFeaturePermissionError(message)
      }
    } else {
      if (!usePluginStore().isGetFiles) {
        const message = `This upload input files feature is not configured for this customized plugin.`
        usePluginStore().setFeaturePermissionError(message)
      }
    }
    if (pluginData?.hasFiles) usePluginStore().setPluginHasFiles(pluginData.hasFiles)
    if (pluginData.files) usePluginStore().setPluginFiles(pluginData.files)
  }
}
/**
 * init get code feature
 */
const initGetCode = () => {
  useIdeStore().pymChild.onMessage('getCode', () => {
    let script = ''
    if (!usePluginStore().isCusomPlugin) {
      const message = `This getCode feature is not available for this plugin.`
      usePluginStore().setFeaturePermissionError(message)
    } else {
      if (usePluginStore().isGetCode) {
        script = editorService.getEditorSession(IDECONSTANT.CODE_EDITOR).getValue()
        window.parent.postMessage({ script: script }, '*')
      } else {
        const message = `This getCode feature is not configured for this customized plugin.`
        usePluginStore().setFeaturePermissionError(message)
      }
    }
  })
}
/**
 * init set code feature
 */
const initSetCode = () => {
  useIdeStore().pymChild.onMessage('setCode', (script: string = '') => {
    if (!usePluginStore().isCusomPlugin) {
      const message = `This setCode feature is not available for this plugin.`
      usePluginStore().setFeaturePermissionError(message)
    } else {
      if (usePluginStore().isSetCode) {
        let processedScript = script
        try {
          processedScript = JSON.parse(script)
        } catch (e) {
          processedScript = script
        }
        editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, processedScript)
      } else {
        const message = `This setCode feature is not configured for this customized plugin.`
        usePluginStore().setFeaturePermissionError(message)
      }
    }
  })
}
/**
 * init execute feature
 */
const initExecute = () => {
  useIdeStore().pymChild.onMessage('execute', () => {
    if (!usePluginStore().isCusomPlugin) {
      const message = `This execute feature is not available for this plugin.`
      usePluginStore().setFeaturePermissionError(message)
    } else {
      if (usePluginStore().isExecute) {
        executeService.tryExecute()
      } else {
        const message = `This execute feature is not configured for this customized plugin.`
        usePluginStore().setFeaturePermissionError(message)
      }
    }
  })
}
/**
 * init get language list feature
 */
const initGetLanguageList = () => {
  useIdeStore().pymChild.onMessage('getLanguageList', () => {
    let languages = []
    if (!usePluginStore().isCusomPlugin) {
      const message = `This getLanguageList feature is not available for this plugin.`
      usePluginStore().setFeaturePermissionError(message)
    } else {
      if (usePluginStore().isGetLanguageList) {
        languages = cloneDeep(usePluginStore().languages).map((lang: any) => {
          return {
            searchTerm: lang.searchTerm,
            displayName: lang.displayName,
            language: lang.language
          }
        })
        window.parent.postMessage({ languages: JSON.stringify(languages) }, '*')
      } else {
        const message = `This getLanguageList feature is not configured for this customized plugin.`
        usePluginStore().setFeaturePermissionError(message)
      }
    }
  })
}
/**
 * init set language feature
 */
const initSetLanguage = () => {
  useIdeStore().pymChild.onMessage('setLanguage', (code: string | null = null) => {
    if (!usePluginStore().isCusomPlugin) {
      const message = `This setLanguage feature is not available for this plugin.`
      usePluginStore().setFeaturePermissionError(message)
    } else {
      if (usePluginStore().isSetLanguage) {
        if (code) {
          const index = usePluginStore().languages.findIndex(
            (lang: any) =>
              lang.displayName === code || lang.searchTerm === code || lang.language === code
          )
          if (index === -1) {
            const message = `invalide language code.`
            usePluginStore().setFeaturePermissionError(message)
          } else {
            usePluginStore().setDefautlLanguageIndex(index)
            usePluginStore().setLanguage(index)
            useIdeStore().setProject({
              ...useIdeStore().project,
              language: usePluginStore().isDefaultLanguageAceCode,
              script: ''
            })
            pluginService.onLanguageChange()
          }
        } else {
          const message = `code is missing in the setLanguage call.`
          usePluginStore().setFeaturePermissionError(message)
        }
      } else {
        const message = `This setLanguage feature is not configured for this customized plugin.`
        usePluginStore().setFeaturePermissionError(message)
      }
    }
  })
}
/**
 * init get language version list feature
 */
const initGetVersionList = () => {
  useIdeStore().pymChild.onMessage('getVersionList', () => {
    let versions = []
    if (!usePluginStore().isCusomPlugin) {
      const message = `This getVersionList feature is not available for this plugin.`
      usePluginStore().setFeaturePermissionError(message)
    } else {
      if (usePluginStore().isGetVersionList) {
        versions = cloneDeep(useIdeStore().isVersions).map((version: any, index: number) => {
          return {
            index: index,
            version
          }
        })

        window.parent.postMessage({ versions: JSON.stringify(versions) }, '*')
      } else {
        const message = `This getVersionList feature is not configured for this customized plugin.`
        usePluginStore().setFeaturePermissionError(message)
      }
    }
  })
}
/**
 * init set version feature
 */
const initSetVersion = () => {
  useIdeStore().pymChild.onMessage('setVersion', (index: number | null = null) => {
    if (!usePluginStore().isCusomPlugin) {
      const message = `This setVersion feature is not available for this plugin.`
      usePluginStore().setFeaturePermissionError(message)
    } else {
      if (usePluginStore().isSetVersion) {
        if (index) {
          const versionLength = useIdeStore().isVersions.length
          if (index >= 0 && index < versionLength) {
            useIdeStore().setVersionIndex(parseInt(index as any))
            useIdeStore().setCodeUpdated(true)
            if (useIdeStore().isBlockly) blocklyService.changeBlocklyLanguage()
          } else {
            const message = `invalide version index.`
            usePluginStore().setFeaturePermissionError(message)
          }
        } else {
          const message = `Language version index is missing in the setVersion call.`
          usePluginStore().setFeaturePermissionError(message)
        }
      } else {
        const message = `This setVersion feature is not configured for this customized plugin.`
        usePluginStore().setFeaturePermissionError(message)
      }
    }
  })
}
/**
 * init set code feature
 */
const initInteractiveMode = () => {
  useIdeStore().pymChild.onMessage('setInteractiveMode', (status: boolean | string = 'false') => {
    if (!usePluginStore().isCusomPlugin) {
      const message = `This setInteractiveMode feature is not available for this plugin.`
      usePluginStore().setFeaturePermissionError(message)
    } else {
      if (usePluginStore().isSetInteractiveMode) {
        // check status is a boolean
        if (typeof status === 'boolean') {
          useIdeStore().interactiveMode = status as boolean
        } else if (status === 'true') {
          useIdeStore().interactiveMode = true
        } else if (status === 'false') {
          useIdeStore().interactiveMode = false
        } else {
          const message = `status should be a either 'true' or 'false'.`
          usePluginStore().setFeaturePermissionError(message)
        }
      } else {
        const message = `This setInteractiveMode feature is not configured for this customized plugin.`
        usePluginStore().setFeaturePermissionError(message)
      }
    }
  })
}
/**
 * init set input args feature
 */
const initSetInputArgs = () => {
  useIdeStore().pymChild.onMessage('setInputArgs', (args: string = '') => {
    if (!usePluginStore().isCusomPlugin) {
      const message = `This setInputArgs feature is not available for this plugin.`
      usePluginStore().setFeaturePermissionError(message)
    } else {
      if (usePluginStore().isSetInputArgs) {
        useIdeStore().args = args
      } else {
        const message = `This setInputArgs feature is not configured for this customized plugin.`
        usePluginStore().setFeaturePermissionError(message)
      }
    }
  })
}
/**
 * init set stdin input feature
 */
const initSetStdinInput = () => {
  useIdeStore().pymChild.onMessage('setStdinInput', (stdIn: string = '') => {
    if (!usePluginStore().isCusomPlugin) {
      const message = `This setStdinInput feature is not available for this plugin.`
      usePluginStore().setFeaturePermissionError(message)
    } else {
      if (usePluginStore().isSetStdinInput) {
        useIdeStore().stdin = stdIn
      } else {
        const message = `This setStdinInput feature is not configured for this customized plugin.`
        usePluginStore().setFeaturePermissionError(message)
      }
    }
  })
}
/**
 * init set clear code editor feature
 */
const initClearCodeEditor = () => {
  useIdeStore().pymChild.onMessage('clearCodeEditor', () => {
    if (!usePluginStore().isCusomPlugin) {
      const message = `This clearCodeEditor feature is not available for this plugin.`
      usePluginStore().setFeaturePermissionError(message)
    } else {
      if (usePluginStore().isClearCodeEditor) {
        editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, '')
      } else {
        const message = `This clearCodeEditor feature is not configured for this customized plugin.`
        usePluginStore().setFeaturePermissionError(message)
      }
    }
  })
}
/**
 * init set clear output editor feature
 */
const initClearOutputEditor = () => {
  useIdeStore().pymChild.onMessage('clearOutputEditor', () => {
    if (!usePluginStore().isCusomPlugin) {
      const message = `This clearOutputEditor feature is not available for this plugin.`
      usePluginStore().setFeaturePermissionError(message)
    } else {
      if (usePluginStore().isClearOutputEditor) {
        editorService.setEditorSession(IDECONSTANT.OUTPUT_EDITOR, '')
      } else {
        const message = `This clearOutputEditor feature is not configured for this customized plugin.`
        usePluginStore().setFeaturePermissionError(message)
      }
    }
  })
}
/**
 * init on code editor updated
 */
const initOnCodeEditorUpdate = () => {
  useIdeStore().pymChild.onMessage('enableOnCodeEditorUpdate', () => {
    if (!usePluginStore().isCusomPlugin) {
      const message = `This onCodeEditorUpdate feature is not available for this plugin.`
      usePluginStore().setFeaturePermissionError(message)
    } else {
      if (usePluginStore().isOnCodeEditorUpdate) {
        editorService
          .getEditor(IDECONSTANT.CODE_EDITOR)
          .textInput.getElement()
          .addEventListener('keyup', () => {
            const script = editorService.getEditorSession(IDECONSTANT.CODE_EDITOR).getValue()
            window.parent.postMessage({ updatedScript: script }, '*')
          })
      } else {
        const message = `This onCodeEditorUpdate feature is not configured for this customized plugin.`
        usePluginStore().setFeaturePermissionError(message)
      }
    }
  })
}
/**
 * init on code editor updated
 */
const initOnExecuteComplete = () => {
  useIdeStore().pymChild.onMessage('enableOnExecuteComplete', () => {
    if (!usePluginStore().isCusomPlugin) {
      const message = `This onExecuteComplete feature is not available for this plugin.`
      usePluginStore().setFeaturePermissionError(message)
    } else {
      if (usePluginStore().isOnExecuteComplete) {
        usePluginStore().setOnExecuteCompleteEnabled(true)
      } else {
        const message = `This onExecuteComplete feature is not configured for this customized plugin.`
        usePluginStore().setFeaturePermissionError(message)
      }
    }
  })
}
/**
 * when execute is completed send the output to the host app
 * @param output - The output
 */
const onCustomPluginExecuteComplete = (output: string = '') => {
  if (usePluginStore().isOnExecuteCompleteEnabled) {
    window.parent.postMessage({ output: output }, '*')
  }
}
/**
 * init postToJDoodle feature
 */
const initPostToJDoodle = () => {
  useIdeStore().pymChild.onMessage('postToJDoodle', () => {
    if (!usePluginStore().isCusomPlugin) {
      const message = `This postToJDoodle feature is not available for this plugin.`
      usePluginStore().setFeaturePermissionError(message)
    } else {
      if (usePluginStore().isPostToJDoodle) {
        embedService.postToJDoodle()
      } else {
        const message = `This postToJDoodle feature is not configured for this customized plugin.`
        usePluginStore().setFeaturePermissionError(message)
      }
    }
  })
}
/**
 * init stop execute feature
 */
const initStopExecute = () => {
  useIdeStore().pymChild.onMessage('stopExecute', () => {
    if (!usePluginStore().isCusomPlugin) {
      const message = `This stopExecute feature is not available for this plugin.`
      usePluginStore().setFeaturePermissionError(message)
    } else {
      if (usePluginStore().isStopExecute) {
        executeService.stopExecution()
      } else {
        const message = `This stopExecute feature is not configured for this customized plugin.`
        usePluginStore().setFeaturePermissionError(message)
      }
    }
  })
}
export default {
  initGetFiles,
  initGetCode,
  initSetCode,
  initExecute,
  initGetLanguageList,
  initSetLanguage,
  initGetVersionList,
  initSetVersion,
  initInteractiveMode,
  initSetInputArgs,
  initSetStdinInput,
  initClearCodeEditor,
  initClearOutputEditor,
  initOnCodeEditorUpdate,
  initOnExecuteComplete,
  onCustomPluginExecuteComplete,
  initPostToJDoodle,
  initStopExecute
}
