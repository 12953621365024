/**
 * Shared utility functions used in Content-app sections (docs, tutorials, manage)
 */
export default class ContentAppUtilsService {
  /**
   * Gets data url hi-jdoodle-here and covnert into title HI JDOODLE HERE
   * @param urlArray string array of urls
   * @returns string Title
   */
  ConvertUrlStringToTitle(urlArray: string[]): string[] {
    return urlArray.map((url: string) =>
      url
        .split('-')
        .map((word) => word.toLocaleUpperCase())
        .join(' ')
    )
  }

  /**
   * Get's the home url (first item)
   * @param url string
   * @returns string home url
   */
  GetHomeUrl(url: string): string {
    return url.split('/')[1]
  }

  /**
   * Get an array of URL paths
   * @param url string of entire URL
   * @returns string array of params that exist
   */
  GetUrlParamsArray(url: string): string[] {
    return url
      .split('/')
      .filter((item) => item !== '')
      .slice(1)
  }

  /**
   * For each item in URL path append to breadcrumb
   * @param paramsArray array of all URL path/parameters
   * @returns string array of /path
   */
  GetPathsArray(paramsArray: string[]): string[] {
    let appendedPath = ''

    const appendedPaths = paramsArray.map((urlParam) => {
      appendedPath += `/${urlParam}`
      return appendedPath
    })

    return appendedPaths
  }
}
