import Accordian from '@/components/ide/embed/AccordionComp.vue'
import FileComp from '@/components/ide/embed/FileComp.vue'
import Ide from '@/components/ide/embed/IdeComp.vue'
import Output from '@/components/ide/embed/OutputComp.vue'
import PostToComp from '@/components/ide/embed/PostToComp.vue'
import ProjectHeader from '@/components/ide/embed/ProjectHeaderComp.vue'
import Settings from '@/components/ide/embed/SettingsComp.vue'
import { shallowRef } from 'vue'

export const TYPES = {
  BASIC: 'BASIC',
  PROJECT: 'PROJECT',
  EMBED: 'EMBED',
  IEMBED: 'IEMBED'
}

export const SCREENSIZES = {
  SM: 'sm',
  MD: 'md',
  LG: 'lg'
}

export const BREAKPOINTS = {
  lg: 1004,
  md: 748,
  sm: 620,
  xs: -1,
  xxs: -2
}

export const COMPONENTS = {
  PROJECTHEADER: 'PluginProjectHeader',
  IDE: 'PluginIde',
  ACCORDIAN: 'PluginAccordian',
  SETTINGS: 'PluginSettings',
  OUTPUT: 'PluginOutput',
  FILECOMP: 'PluginFileComp',
  POSTTOCOMP: 'PluginPostToComp'
}

export const COMPONENTDATA = [
  {
    name: COMPONENTS.PROJECTHEADER,
    component: shallowRef(ProjectHeader),
    settings: {
      isStatic: false,
      name: 'Header',
      minH: 0.28
    }
  },
  {
    name: COMPONENTS.IDE,
    component: shallowRef(Ide),
    settings: {
      isStatic: true,
      name: 'IDE',
      minH: 3.84
    }
  },
  {
    name: COMPONENTS.ACCORDIAN,
    component: shallowRef(Accordian),
    settings: {
      isStatic: false,
      name: 'Settings',
      minH: 1.79
    }
  },
  {
    name: COMPONENTS.SETTINGS,
    component: shallowRef(Settings),
    settings: {
      isStatic: false,
      name: 'Execute',
      minH: 0.62
    }
  },
  {
    name: COMPONENTS.OUTPUT,
    component: shallowRef(Output),
    settings: {
      isStatic: true,
      name: 'Output',
      minH: 1.36
    }
  },
  {
    name: COMPONENTS.FILECOMP,
    component: shallowRef(FileComp),
    settings: {
      isStatic: false,
      name: 'Files',
      minH: 1
    }
  },
  {
    name: COMPONENTS.POSTTOCOMP,
    component: shallowRef(PostToComp),
    settings: {
      isStatic: true,
      name: 'Post To JDoodle',
      minH: 0.48
    }
  }
]

export const FUNCTIONALSDATA = [
  {
    id: 0,
    name: 'Get Code'
  },
  {
    id: 1,
    name: 'Set Code'
  },
  {
    id: 2,
    name: 'Execute'
  },
  {
    id: 3,
    name: 'Get Files'
  },
  {
    id: 4,
    name: 'Get Language List'
  },
  {
    id: 5,
    name: 'Set Language'
  },
  {
    id: 6,
    name: 'Get Version List'
  },
  {
    id: 7,
    name: 'Set Version'
  },
  {
    id: 8,
    name: 'Set Interactive Mode'
  },
  {
    id: 9,
    name: 'Set Input Arguments'
  },
  {
    id: 10,
    name: 'Set Stdin Inputs'
  },
  {
    id: 11,
    name: 'Clear Code Editor'
  },
  {
    id: 12,
    name: 'Clear Output Editor'
  },
  {
    id: 13,
    name: 'On Code Editor Update'
  },
  {
    id: 14,
    name: 'On Execute Complete'
  },
  {
    id: 15,
    name: 'Post to JDoodle'
  },
  {
    id: 16,
    name: 'Stop Execute'
  }
]

export const DEFAULTLAYPUTS = {
  [SCREENSIZES.SM]: [
    { name: COMPONENTS.IDE, x: 0, y: 0, w: 6, h: 4, i: '1' },
    { name: COMPONENTS.ACCORDIAN, x: 0, y: 4, w: 6, h: 1.79, i: '2' },
    { name: COMPONENTS.SETTINGS, x: 0, y: 5.79, w: 6, h: 0.62, i: '3' },
    { name: COMPONENTS.OUTPUT, x: 0, y: 6.41, w: 6, h: 2, i: '4' },
    { name: COMPONENTS.POSTTOCOMP, x: 0, y: 8.41, w: 6, h: 0.48, i: '5' }
  ],
  [SCREENSIZES.MD]: [
    { name: COMPONENTS.IDE, x: 0, y: 0, w: 10, h: 4, i: '1' },
    { name: COMPONENTS.ACCORDIAN, x: 0, y: 4, w: 10, h: 1.79, i: '2' },
    { name: COMPONENTS.SETTINGS, x: 0, y: 5.79, w: 10, h: 0.62, i: '3' },
    { name: COMPONENTS.OUTPUT, x: 0, y: 6.41, w: 10, h: 2, i: '4' },
    { name: COMPONENTS.POSTTOCOMP, x: 0, y: 8.41, w: 10, h: 0.48, i: '5' }
  ],
  [SCREENSIZES.LG]: [
    { name: COMPONENTS.IDE, x: 0, y: 0, w: 12, h: 4, i: '1' },
    { name: COMPONENTS.ACCORDIAN, x: 0, y: 4, w: 12, h: 1.79, i: '2' },
    { name: COMPONENTS.SETTINGS, x: 0, y: 5.79, w: 12, h: 0.62, i: '3' },
    { name: COMPONENTS.OUTPUT, x: 0, y: 6.41, w: 12, h: 2, i: '4' },
    { name: COMPONENTS.POSTTOCOMP, x: 0, y: 8.41, w: 12, h: 0.48, i: '5' }
  ]
}
