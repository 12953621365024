<script setup lang="ts">
import { computed } from 'vue'
import { useIdeStore } from '@/stores/ide.store'
import { usePluginStore } from '@/stores/plugin.store'

const ideStore = useIdeStore()
const pluginStore = usePluginStore()

const showProjectName = computed(() => {
  return (
    (ideStore.project && ideStore.project?.name) ||
    (pluginStore.isCusomPlugin && pluginStore.isPluginResponse?.name)
  )
})
const projectName = computed(() => {
  if (ideStore.project?.name) return `Project Name : ${ideStore.project?.name}`
  if (pluginStore.isPluginResponse?.name)
    return `Plugin Name : ${pluginStore.isPluginResponse?.name}`
  return ''
})
</script>

<template>
  <h1 v-show="showProjectName" class="heading-small text-center">{{ projectName }}</h1>
</template>
