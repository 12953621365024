// === Assignments ===

export enum USER_TABLE_TYPE {
  ASSIGNMENT = 'assignment',
  STUDENT_GROUP = 'student group',
  COURSE = 'course',
  EXERCISE = 'exercise'
}

export enum ASSESSMENT_MODE {
  ATTEND = 'attend',
  PREVIEW = 'preview',
  EVALUATE = 'evaluate'
}

export enum ASSESSMENT_TAB {
  SUMMARY = 'Summary',
  QUESTION = 'Question',
  REVIEW = 'Review'
}

export enum ASSESSMENT_ACTIONS {
  ADD = 'add',
  EDIT = 'edit',
  CLONE = 'clone'
}

export enum ASSESSMENT_TABS {
  QUESTIONS = 'assessment-questions',
  STUDENTS = 'assessment-students',
  TEACHERS = 'assessment-teachers'
}

export enum TEST_CASE_ACTIONS {
  ADD = 'add',
  EDIT = 'edit',
  CLONE = 'clone'
}

export enum ASSESSMENT_RESULT_TYPE {
  MARK_AND_COMMENT = 'Mark & Comment',
  MARK_ONLY = 'Mark Only',
  COMMENT_ONLY = 'Comment Only'
}

export enum ASSESSMENT_DISCLOSE_TYPE {
  MANUAL = 'Manual',
  ON_SUBMISSION = 'On Assignment Submission'
}

export enum ASSESSMENT_STATUS {
  //Assignment status
  DRAFT = 'Draft',
  READY = 'Ready',
  ON_HOLD = 'On Hold',
  IN_PROGRESS = 'In Progress',
  COMPLETED = 'Completed'
}

export enum ASSESSMENT_SUBMISSION_STATUS {
  YET_TO_START = 'Yet to Start',
  IN_PROGRESS = 'In Progress',
  SUBMITTED = 'Submitted',
  ERROR_WHEN_AUTO_CORRECT = 'Unable to Auto Correct',
  EVALUATED = 'Evaluated',
  PARTIALLY_EVALUATED = 'Partially Evaluated',
  RESULT_ANNOUNCED = 'Result Announced',
  REEVALUATION_REQUESTED = 'Reevaluation Requested'
}

export enum ASSESSMENT_DURATION_MODAL_MODE {
  ACTIVATE = 'activate',
  CHANGE = 'change'
}

// === Questions ===

export enum ASSESSMENT_QUESTION_TYPE {
  MC_ONE_ANSWER = 'Multiple Choice - One Answer',
  MC_MULTIPLE_ANSWER = 'Multiple Choice - Multiple Answer',
  OPEN_ENDED = 'Open Ended',
  PROGRAM = 'Program',
  TRUE_OR_FALSE = 'True or False'
}

// === Ai Assessment Form ====
export enum AI_ASSESSMENT_FORM_TYPE {
  SINGLE_SELECT_TYPE = 'single select type',
  MULTI_SELECT_TYPE = 'multiple select type',
  TEXT_DESCRIPTION = 'text description',
  PROMPT_TYPE = 'prompt'
}
export enum ASSESSMENT_QUESTION_TYPE_AI {
  MC_ONE_ANSWER = 'MCQ - Single Answer',
  MC_MULTIPLE_ANSWER = 'MCQ - Multiple Answer',
  OPEN_ENDED = 'Open Ended',
  PROGRAM = 'Program',
  TRUE_OR_FALSE = 'True or False'
}

// === Questions difficulty ===

export enum ASSESSMENT_QUESTION_DIFFICULTY {
  EASY = 'Easy',
  MEDIUM = 'Medium',
  HARD = 'Hard'
}

// === Questions test cases ===

export enum ASSESSMENT_QUESTION_TEST_CASES {
  ONE = '1',
  THREE = '3',
  FIVE = '5',
  TEN = '10',
  FIFTEEN = '15',
  TWENTY = '20'
}

// === Improve Question Selection Fields  ====
export enum AI_ASSESSMENT_IMPROVE_QUESTION_FIELDS {
  QUESTIONTYPE = 'Question Type',
  DIFFICULTYLEVEL = 'Difficulty Level',
  TESTCASES = 'Test Cases',
  ADDDETAILSORCONTEXT = 'Add Details or Context',
  TOPICSELECTION = 'Topic Selection'
}

// === Improve Question Selection Fields  ====
export enum AI_ASSESSMENT_DATA_CONVERTERTYPE {
  QUESTIONTYPE = 'Question Type',
  PAYLOADNAMECHANGE = 'Payload Name Change'
}
