<script setup lang="ts">
import { computed } from 'vue'
import blocklyService from '@/services/ide/languages/blockly/blockly.service'
import { useIdeStore } from '@/stores/ide.store'
import { usePluginStore } from '@/stores/plugin.store'
import { CMD_LANGUAGES } from '@/utils/ide'

const ideStore = useIdeStore()
const pluginStore = usePluginStore()

const isCusomPlugin = computed(() => {
  return pluginStore.isCusomPlugin
})
const isBlockly = computed(() => {
  return ideStore.isBlockly
})
const shareNotFound = computed(() => {
  return ideStore.shareNotFound
})
const interactiveMode = computed(() => {
  return ideStore.interactiveMode
})
const versions = computed(() => {
  return ideStore.isVersions
})
const showInputArg = computed(() => {
  if (!ideStore.isLanguage) {
    return false
  }
  return !CMD_LANGUAGES.includes(ideStore.isLanguage)
})
const isFullScreen = computed(() => {
  return ideStore.isFullScreen
})
/**
 * Checks if the version is selected
 * @param index The version index to check
 * @returns True if the version is selected
 */
const isVersionSelected = (index: number) => {
  return ideStore.versionIndex === index
}
/**
 * Sets the version
 * @param e The event
 */
const setVersion = (e: Event) => {
  ideStore.setVersionIndex(parseInt((e.target as HTMLSelectElement).value))
  ideStore.setCodeUpdated(true)
  if (isBlockly.value) blocklyService.changeBlocklyLanguage()
}
</script>

<template>
  <div
    class="hs-accordion-group data-hs-accordion-always-open print:break-inside-avoid print:border print:p-1"
  >
    <div class="hs-accordion active" id="hs-settings">
      <button
        v-show="!isCusomPlugin"
        class="hs-accordion-toggle heading-xsmall inline-flex w-full items-center gap-x-5 break-normal rounded-lg px-5 py-2 text-left text-sm no-underline transition hs-accordion-active:rounded-b-none"
        aria-controls="hs-basic-always-open-setting"
      >
        <FontAwesomeIcon
          icon="angle-down"
          class="block h-full w-4 flex-shrink-0 hs-accordion-active:hidden"
        />
        <FontAwesomeIcon
          icon="angle-up"
          class="hidden h-full w-4 flex-shrink-0 hs-accordion-active:block"
        />
        Execute Mode, Version, Inputs & Arguments
      </button>
      <div
        id="hs-basic-always-open-setting"
        class="hs-accordion-content w-full overflow-hidden rounded-b-lg transition-[height] duration-300"
        aria-labelledby="hs-settings"
      >
        <div class="p-1">
          <div
            :class="[
              'flex',
              'w-full',
              'justify-between',
              'gap-5',
              {
                'flex-col lg:flex-row': !isCusomPlugin
              },
              {
                'xl:flex-col': isFullScreen
              }
            ]"
          >
            <div
              :class="[
                'flex',
                'w-full',
                'h-full',
                'justify-between',
                'gap-5',
                'items-start',
                'flex-col',
                'lg:flex-col'
              ]"
            >
              <div
                :class="[
                  'flex',
                  'w-full',
                  'flex-col',
                  'justify-between',
                  'gap-5',
                  {
                    'lg:flex-row': !isCusomPlugin
                  }
                ]"
              >
                <select
                  v-if="!shareNotFound"
                  id="versionSelect"
                  class="section-primary p-small block w-full rounded-md px-4 py-2 print:border"
                  @change="setVersion($event)"
                >
                  <option
                    v-for="(version, index) in versions"
                    :key="index"
                    :selected="isVersionSelected(index)"
                    :value="index"
                  >
                    {{ version }}
                  </option>
                </select>
                <div class="flex items-center gap-2 rounded-lg px-1 print:hidden print:border">
                  <input
                    type="checkbox"
                    id="hs-basic-with-description-checked"
                    class="section-primary checkbox-primary"
                    checked
                    v-model="ideStore.interactiveMode"
                  />
                  <label for="hs-basic-with-description-checked" class="p-small my-2 block"
                    >Interactive</label
                  >
                </div>
                <div class="hidden rounded-lg px-1 print:block print:border">
                  <label class="p-normal my-2 block px-3"
                    >Interactive Mode: {{ interactiveMode ? 'On' : 'Off' }}</label
                  >
                </div>
              </div>
              <div v-show="showInputArg" class="flex w-full flex-col justify-between">
                <div class="flex justify-between">
                  <p class="p-normal">Input Arguments</p>
                </div>

                <input
                  class="section-primary p-small block w-full rounded-md px-4 py-2 print:border"
                  :value="ideStore.args || ''"
                  @input="(e: Event) => { ideStore.args = (e.target as HTMLInputElement).value }"
                />
              </div>
            </div>
            <div v-if="!interactiveMode" class="flex w-full flex-col justify-between">
              <div class="flex justify-between">
                <p class="p-normal">Stdin Inputs</p>
              </div>
              <textarea
                class="section-primary p-small block h-full w-full resize-none rounded-md px-4 py-2 print:border"
                :value="ideStore.stdin || ''"
                @input="(e: Event) => { ideStore.stdin = (e.target as HTMLInputElement).value }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
