<script setup lang="ts">
import type ITutorial from '@/components/content-app/interface/tutorials/ITutorial'
import TutorialUtilsService from '@/utils/content-app/tutorial.utils'
import { type PropType, reactive, type Ref, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

const props = defineProps({
  currentLanguageUrl: {
    type: String,
    required: true
  },
  currentModuleUrl: {
    type: String,
    required: true
  },
  currentTutorialUrl: {
    type: String,
    required: true
  },
  tutorialsInModule: {
    type: Array as PropType<ITutorial[]>,
    required: true
  }
})
const router = useRouter()

const _tutorialsUtilsService = new TutorialUtilsService()
const tutorialsInModule: Ref<ITutorial[] | undefined> = ref(props.tutorialsInModule)
const currentTutorialUrl: Ref<string | undefined> = ref(props.currentTutorialUrl)
const navigateTutorials = reactive({
  nextTutorial: null as null | ITutorial,
  previousTutorial: null as null | ITutorial,
  currentTutorialIndex: NaN as number
})

//Populate next/prev links once Module data loads
watch(
  () => [props.tutorialsInModule, props.currentTutorialUrl],
  () => {
    tutorialsInModule.value = props.tutorialsInModule
    currentTutorialUrl.value = props.currentTutorialUrl

    //Current Index
    navigateTutorials.currentTutorialIndex = _tutorialsUtilsService.getCurrentTutorialIndex(
      tutorialsInModule.value,
      currentTutorialUrl.value
    )

    //Next index
    navigateTutorials.nextTutorial = _tutorialsUtilsService.getNextTutorial(
      tutorialsInModule.value,
      currentTutorialUrl.value,
      navigateTutorials.currentTutorialIndex
    )

    //Prev index
    navigateTutorials.previousTutorial = _tutorialsUtilsService.getPreviousTutorial(
      tutorialsInModule.value,
      currentTutorialUrl.value,
      navigateTutorials.currentTutorialIndex
    )
  }
)

/**
 * Change tutorial using dropdown
 */
function changeTutorial() {
  router.push(
    `/tutorials/${props.currentLanguageUrl}/${props.currentModuleUrl}/${currentTutorialUrl.value}`
  )
}
</script>

<template>
  <div class="w-100 section-tertiary flex justify-between px-5 py-3 text-xs">
    <!-- Previous Button -->
    <RouterLink
      :class="navigateTutorials.previousTutorial ? '' : 'invisible'"
      :to="`/tutorials/${props.currentLanguageUrl}/${props.currentModuleUrl}/${navigateTutorials.previousTutorial?.attributes.Url}`"
      class="text-primary flex flex items-center gap-1"
      ><FontAwesomeIcon icon="fa-arrow-left" class="text-primary w-3" /> Previous</RouterLink
    >

    <!-- Unit Dropdown -->
    <div v-if="tutorialsInModule">
      <select
        @change="changeTutorial"
        v-model="currentTutorialUrl"
        class="section-secondary max-w-[180px] truncate rounded-md p-1 text-sm sm:max-w-[200px]"
      >
        <option
          v-for="(tutorial, index) in tutorialsInModule"
          :key="index"
          :value="tutorial.attributes.Url"
          :selected="tutorial.attributes.Url === props.currentTutorialUrl"
        >
          {{ `Unit ${index + 1} - ${tutorial.attributes.Title}` }}
        </option>
      </select>
    </div>

    <!-- Next Button -->
    <RouterLink
      :class="navigateTutorials.nextTutorial ? '' : 'invisible'"
      :to="`/tutorials/${props.currentLanguageUrl}/${props.currentModuleUrl}/${navigateTutorials.nextTutorial?.attributes?.Url}`"
      class="text-primary flex flex items-center gap-1"
      >Next
      <FontAwesomeIcon icon="fa-arrow-right" class="text-primary w-3" />
    </RouterLink>
  </div>
</template>
