declare global {
  interface Window {
    dataLayer: any
  }
}
import loadScriptInBody from '@/services/loadScriptInBody.service'
/**
 * injects the gtm script into the body
 */
const initGtm = () => {
  loadScriptInBody.loadScriptInBody('/assets/javascript/gmt.script.js')
}
/**
 * this function identifies the user when they log in
 * @param email - user email
 * @param count - number of times the user has logged in
 */
const identifyGtm = async (email: string, count: number = 0) => {
  //@ts-ignore
  if (process.env.NODE_ENV === 'development') return
  if (!window.dataLayer) {
    if (count < 20) {
      await new Promise((resolve) => setTimeout(resolve, 100))
      await identifyGtm(email, count + 1)
    }
  } else {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      userId: email,
      event: 'login'
    })
  }
}

export default { initGtm, identifyGtm }
