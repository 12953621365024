<script setup lang="ts">
import type ITutorialModule from '@/components/content-app/interface/tutorials/ITutorialModule'
import { type PropType, type Ref, ref, watch } from 'vue'
import LevelPill from '@/components/content-app/tutorials/LevelPill.vue'
const props = defineProps({
  module: {
    type: Object as PropType<ITutorialModule>,
    required: true
  }
})

const tutorialModule: Ref<ITutorialModule> = ref(props.module)
watch(
  () => props.module,
  (newValue) => {
    // console.log('NEW MODULE', newValue)
    tutorialModule.value = newValue
  }
)
</script>

<template>
  <!-- Card -->
  <RouterLink
    :to="`/tutorials/${tutorialModule.attributes.language?.data.attributes.Url}/${tutorialModule.attributes.Url}`"
  >
    <div
      class="group flex h-full max-h-[450px] flex-col overflow-hidden rounded-xl border border-text-secondary shadow-sm transition hover:shadow-md"
    >
      <div class="h-2/5 w-full">
        <!-- Default image -->
        <img
          v-if="!tutorialModule.attributes.Thumbnail?.data?.attributes.url"
          class="h-full w-full object-cover"
          :src="tutorialModule.attributes.Thumbnail?.data?.attributes.url"
        />
        <!-- Saved image -->
        <img
          v-if="tutorialModule.attributes.Thumbnail?.data?.attributes.url"
          class="h-full w-full object-cover"
          :src="tutorialModule.attributes.Thumbnail?.data?.attributes.url"
        />
      </div>
      <div class="flex h-auto grow flex-col p-4 md:p-6">
        <level-pill :level="tutorialModule.attributes.Level" class="mb-1"></level-pill>
        <h4 class="text-primary mb-2 font-semibold">
          {{ tutorialModule.attributes.Title }}
        </h4>
        <p class="text-secondary grow break-normal text-sm">
          {{ tutorialModule.attributes.Description }}
        </p>
        <button class="btn btn-primary w-fit">View</button>
      </div>
    </div>
  </RouterLink>
  <!-- End Card -->
</template>
