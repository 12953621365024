<script setup lang="ts">
import { ref, watch } from 'vue'
import TutorialUtilsService from '@/utils/content-app/tutorial.utils'

const props = defineProps({
  level: {
    type: String,
    required: true
  }
})

const _tutorialUtilsService = new TutorialUtilsService()

const styleClass = ref(props.level)
styleClass.value = _tutorialUtilsService.getCssClassForLevel(props.level)

//If changed
watch(
  () => props.level,
  () => {
    styleClass.value = _tutorialUtilsService.getCssClassForLevel(props.level)
  }
)
</script>

<template>
  <div class="text-sm" :class="`${styleClass}`">
    {{ props.level }}
  </div>
</template>
