<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import embedService from '@/services/ide/embed.service'
import { useIdeStore } from '@/stores/ide.store'
import { usePluginStore } from '@/stores/plugin.store'
import LogoBlack from '@/assets/images/JD_logo_colour.webp'

const route = useRoute()
const ideStore = useIdeStore()
const pluginStore = usePluginStore()

const shareId = computed(() => {
  return ideStore.isShareId
})
const isPlugin = computed(() => {
  return pluginStore.isPlugin
})
const shareUrl = computed(() => {
  let url: string = ''
  //@ts-ignore
  if (process.env.NODE_ENV === 'development') {
    url = `${import.meta.env.VITE_APP_IP}:${import.meta.env.VITE_APP_PORT}`
  } else {
    url = `https://www.jdoodle.com`
  }
  return `${url}/${route.meta.isInstantShare ? 'ia' : 'a'}/${shareId.value}`
})
/**
 * Posts the code to JDoodle
 */
const postToJDoodle = () => {
  embedService.postToJDoodle()
}
</script>

<template>
  <div class="flex max-h-12 items-center justify-between print:hidden">
    <p class="p-small print:hidden">
      <a v-if="isPlugin" @click="postToJDoodle" class="link-secondary"
        >Edit this program in JDoodle.com</a
      >
      <a v-else :href="shareUrl" target="_blank" class="link-secondary"
        >Edit this program in JDoodle.com</a
      >
    </p>
    <a
      href="https://www.jdoodle.com"
      target="_blank"
      class="flex items-center gap-2 rounded-lg p-2"
    >
      <span class="p-small">Online compiler powered by</span>
      <img class="h-12 w-fit" :src="LogoBlack" alt="JDoodle" />
    </a>
  </div>
</template>
