<script setup lang="ts">
import { computed } from 'vue'
import settingService from '@/services/ide/settings/setting.service'
import { useIdeStore } from '@/stores/ide.store'
import { IDECONSTANT } from '@/utils/ide'

const ideStore = useIdeStore()

const isAdvanced = computed(() => {
  return ideStore.isAdvanced || false
})
const isCodeEditor = computed(() => {
  return ideStore.codeEditor !== null
})
/**
 * Init download
 */
const initDownload = async () => {
  await new Promise((resolve) => setTimeout(resolve, 100))
  settingService.download(IDECONSTANT.CODE_EDITOR)
}
/**
 * Pretty print the page
 */
const prettyPrint = () => {
  settingService.prettyPrint()
}
</script>

<template>
  <div class="hs-dropdown relative inline-flex">
    <button id="hs-dropdown-default" type="button" class="btn btn-secondary w-fit min-w-fit">
      <FontAwesomeIcon icon="fa-gears" class="h-4 w-4" />
    </button>
    <div
      class="section-primary hs-dropdown-menu z-10 hidden w-fit rounded-lg p-2 opacity-0 shadow-md transition-[opacity,margin] duration-[0.1ms] hs-dropdown-open:opacity-100"
      aria-labelledby="hs-dropdown-default"
    >
      <button
        v-if="!isAdvanced && isCodeEditor"
        class="btn link-primary flex w-fit justify-start gap-4 p-1 align-middle"
        @click="initDownload"
      >
        <FontAwesomeIcon icon="fa-download" class="h-4 w-4" />
        Save (to local file)
      </button>
      <button
        v-if="!isAdvanced"
        class="btn link-primary flex w-fit justify-start gap-4 p-1 align-middle"
        @click="prettyPrint"
      >
        <FontAwesomeIcon icon="fa-print" class="h-4 w-4" />
        Pretty Print
      </button>
    </div>
  </div>
</template>
