import type ILanguage from '@/components/content-app/interface/tutorials/ILanguage'
import type ITutorial from '@/components/content-app/interface/tutorials/ITutorial'
import type { ITutorialExerciseBanner } from '@/components/content-app/interface/tutorials/ITutorialExerciseBanner'
import type ITutorialModule from '@/components/content-app/interface/tutorials/ITutorialModule'
import { $contentAppAxios } from '@/utils/content-app/content-app-axios.util'
/**
 * Service that retrieves Tutorial data
 */
export default class TutorialsService {
  /**
   * Retrieve all languages
   * @returns ILanguage[]
   */
  async getAllLanguages(): Promise<ILanguage[]> {
    return await $contentAppAxios
      .get(`/content/languages?populate=*,Thumbnail&sort[0]=Order`)
      .then((response) => {
        // console.log('getAllLanguages', response.data.data)
        return response.data.data
      })
      .catch((err) => {
        throw err
      })
  }
  /**
   * Retrieve data of one language
   * @param url language url
   * @returns ILanguage object
   */
  async getOneLanguage(url: string): Promise<ILanguage> {
    return await $contentAppAxios
      .get(
        `/content/languages?populate=*,Thumbnail,modules.Thumbnail,modules.language&filters[Url][$eq]=${url}`
      )
      .then((response) => {
        // console.log('getOneLanguage', response.data.data)

        if (response.data.data.length < 1) {
          throw new Error('Langauge not found')
        } else {
          const language: ILanguage = response.data.data[0]

          if (language.attributes.modules) {
            language.attributes.modules.data = language.attributes.modules.data.sort(
              (a: ITutorialModule, b: ITutorialModule) => a.attributes.Order - b.attributes.Order
            )
          }

          return language
        }
      })
      .catch((err) => {
        throw err
      })
  }

  /**
   * Retrieve data of one language
   * @param languageUrl language url
   * @param moduleUrl module url
   * @returns ITutorialModule object
   */
  async getOneModule(languageUrl: string, moduleUrl: string): Promise<ITutorialModule> {
    return await $contentAppAxios
      .get(
        `/content/modules?populate=tutorials,Thumbnail,language,language.Thumbnail&filters[language][Url][$eq]=${languageUrl}&filters[Url][$eq]=${moduleUrl}`
      )
      .then((response) => {
        // console.log('getOneModule', response.data.data)
        if (response.data.data < 1) {
          throw new Error('Module not found')
        } else {
          const moduleData: ITutorialModule = response.data.data[0]
          if (moduleData.attributes.tutorials) {
            moduleData.attributes.tutorials.data = moduleData.attributes.tutorials.data.sort(
              (a: ITutorial, b: ITutorial) => a.attributes.Order - b.attributes.Order
            )
          }
          return moduleData
        }
      })
      .catch((err) => {
        throw err
      })
  }
  /**
   * Retrieve tutorials of one module
   * @param languageUrl language url
   * @param moduleUrl module url
   * @returns ITutorial object
   */
  async getAllTutorialsFromModule(languageUrl: string, moduleUrl: string): Promise<ITutorial[]> {
    return await $contentAppAxios
      .get(
        `/content/tutorials?populate=*,Thumbnail,module.language&filters[module][language][Url][$eq]=${languageUrl}&filters[module][Url][$eq]=${moduleUrl}&sort[0]=Order`
      )
      .then((response) => {
        // console.log('getAllTutorialsFromModule', response.data.data)
        return response.data.data
      })
      .catch((err) => {
        throw err
      })
  }

  /**
   * Retrieve data of one language
   * @param languageUrl language url
   * @param moduleUrl module url
   * @param tutorialUrl tutorial url
   * @returns ITutorial object
   */
  async getOneTutorial(
    languageUrl: string,
    moduleUrl: string,
    tutorialUrl: string
  ): Promise<ITutorial> {
    return await $contentAppAxios
      .get(
        `/content/tutorials?populate=*,module.language&filters[module][language][Url][$eq]=${languageUrl}&filters[module][Url][$eq]=${moduleUrl}&filters[Url]=${tutorialUrl}`
      )
      .then((response) => {
        // console.log('getOneTutorial', response.data.data)
        if (response.data.data < 1) {
          throw new Error('Tutorial not found')
        } else {
          return response.data.data[0]
        }
      })
      .catch((err) => {
        throw err
      })
  }

  /**
   * Get data for customisable banner inside tutorial exercise page
   * @returns ITutorialExerciseBanner object
   */
  async getTutorialExerciseBanner(): Promise<ITutorialExerciseBanner> {
    return await $contentAppAxios
      .get(`/content/tutorial-exercise-banner`)
      .then((response) => {
        // console.log('getTutorialExerciseBanner', response.data.data)
        return response.data.data
      })
      .catch((err) => {
        throw err
      })
  }
}
