import type { ISelectListItem } from '@/components/forms/ISelectListItem'
import {
  ASSESSMENT_DISCLOSE_TYPE,
  ASSESSMENT_QUESTION_TYPE,
  ASSESSMENT_RESULT_TYPE
} from '@/components/organisation/enum/assessment.enums'
import type { IQuestionType } from '@/components/organisation/interface/IQuestionType'

export const TEACH_CONSTANTS = {
  ASSIGNMENT_RESULT_TYPES: [
    { type: ASSESSMENT_RESULT_TYPE.MARK_AND_COMMENT, value: 0 },
    { type: ASSESSMENT_RESULT_TYPE.MARK_ONLY, value: 1 },
    { type: ASSESSMENT_RESULT_TYPE.COMMENT_ONLY, value: 2 }
  ] as ISelectListItem[],

  DISCLOSE_TYPES: [
    { type: ASSESSMENT_DISCLOSE_TYPE.MANUAL, value: 0 },
    { type: ASSESSMENT_DISCLOSE_TYPE.ON_SUBMISSION, value: 1 }
  ],

  QUESTION_TYPES: [
    { type: ASSESSMENT_QUESTION_TYPE.MC_ONE_ANSWER, value: 0 },
    { type: ASSESSMENT_QUESTION_TYPE.MC_MULTIPLE_ANSWER, value: 1 },
    { type: ASSESSMENT_QUESTION_TYPE.OPEN_ENDED, value: 2 },
    { type: ASSESSMENT_QUESTION_TYPE.PROGRAM, value: 3 },
    { type: ASSESSMENT_QUESTION_TYPE.TRUE_OR_FALSE, value: 4 }
  ] as IQuestionType[]

  // GURU_ROLE_TYPES: [
  //   { name: ORGANISATION_USER_ROLE.INSTITUTE_ADMIN, index: 0 },
  //   { name: ORGANISATION_USER_ROLE.TEACHER, index: 1 },
  //   { name: ORGANISATION_USER_ROLE.STUDENT, index: 2 }
  // ] as IUserRole[]
}
