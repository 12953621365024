interface PopupCenter {
  url?: string | URL | undefined
  target?: string | undefined
  width?: number
  height?: number
}

/**
 *
 * @param obj Open new window at center
 * @param obj.url URL that will be open
 * @param obj.target target
 * @param obj.width width
 * @param obj.height height
 * @returns Open window Pop ref
 */
export const popupCenter = ({ url, target, width: w, height: h }: PopupCenter): Window | null => {
  // Return if width or height is not defined
  if (!w || !h) return null

  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height

  const systemZoom = width / window.screen.availWidth
  const left = (width - w) / 2 / systemZoom + dualScreenLeft
  const top = (height - h) / 2 / systemZoom + dualScreenTop
  const newWindow = window.open(
    url,
    target,
    `
          scrollbars=yes,
          width=${w / systemZoom}, 
          height=${h / systemZoom}, 
          top=${top}, 
          left=${left}
          `
  )

  //   if (window.focus ) newWindow!.focus()
  newWindow?.focus()

  return newWindow
}
