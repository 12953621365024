<!-- eslint-disable vue/multi-word-component-names -->
<script lang="ts" setup>
import type { PropType } from 'vue'
import { computed, useAttrs } from 'vue'
import { cn } from '@/utils/cn'

const attrs = useAttrs()

const props = defineProps({
  color: {
    type: String as PropType<'inherit' | 'primary' | 'secondary'>,
    default: 'secondary'
  },
  variant: {
    type: String as PropType<'filled' | 'outline' | 'link'>,
    default: 'outline'
  },
  size: {
    type: String as PropType<'small' | 'medium' | 'large'>,
    default: 'small'
  },
  pill: { type: Boolean, default: false },
  class: {
    type: [String, Array, Object]
  }
})

const isPrimary = computed(() => props.color === 'primary')
const isSecondary = computed(() => props.color === 'secondary')
const isPill = computed(() => props.pill)
const isDisabled = computed(() => {
  const disabled = attrs.disabled
  return disabled === '' || disabled === true || disabled === 'true'
})

const buttonClasses = computed(() => {
  const baseClasses = 'transition-colors'

  const colorClasses = {
    inherit: 'text-inherit bg-inherit',
    primary: '',
    secondary: ''
  }

  const variantClasses = {
    outline: cn(
      'border-2',
      {
        'text-new-primary border-new-primary': isPrimary.value,
        'hover:bg-new-primary-50': isPrimary.value
      },
      {
        'text-new-secondary-600 border-new-secondary-600': isSecondary.value,
        'hover:bg-new-secondary-50': isSecondary.value
      }
    ),
    filled: cn(
      'border-2',
      {
        'text-white bg-new-primary border-new-primary': isPrimary.value,
        'hover:bg-new-primary-300': isPrimary.value
      },
      {
        'text-white bg-new-secondary-600 border-new-secondary-600': isSecondary.value,
        'hover:bg-new-secondary-400 hover:border-new-secondary-400': isSecondary.value
      }
    ),
    link: cn(
      'border-none underline underline-offset-2',
      { 'text-new-primary': isPrimary.value, 'hover:text-new-primary-300': isPrimary.value },
      {
        'text-new-secondary-600': isSecondary.value,
        'hover:text-new-secondary-400': isSecondary.value
      }
    )
  }

  const sizeClasses = {
    small: cn('px-3 py-2.5 rounded text-sm leading-none font-medium'),
    medium: cn('px-3 py-3.5 rounded text-base leading-none font-medium bg'),
    large: cn('p-4 rounded-lg text-xl leading-6 font-medium')
  }

  const pillClasses = cn({ 'rounded-[64px]': isPill.value })

  const disableClasses = cn(
    isDisabled.value &&
      'bg-new-neutral-200 cursor-default text-black text-opacity-50 pointer-events-none border-transparent select-none'
  )

  return cn(
    baseClasses,
    colorClasses[props.color],
    variantClasses[props.variant],
    sizeClasses[props.size],
    disableClasses,
    pillClasses,
    props.class
  )
})
</script>

<template>
  <button :class="buttonClasses" v-bind="attrs">
    <slot />
  </button>
</template>
