<script setup lang="ts">
import type ITutorial from '@/components/content-app/interface/tutorials/ITutorial'
import { type PropType, type Ref, ref } from 'vue'

const props = defineProps({
  languageUrl: {
    type: String,
    required: true
  },
  moduleUrl: {
    type: String,
    required: true
  },
  tutorial: {
    type: Object as PropType<ITutorial>,
    required: true
  },
  index: {
    type: Number,
    required: true
  }
})

const tutorial: Ref<ITutorial> = ref(props.tutorial)
const index: Ref<Number> = ref(props.index + 1)
</script>

<template>
  <div class="mb-5">
    <router-link
      :to="{
        path: `/tutorials/${props.languageUrl}/${props.moduleUrl}/${tutorial.attributes.Url}`
      }"
    >
      <span class="hover:text-brand m-0 text-lg font-semibold"
        >{{ index }}. {{ tutorial.attributes.Title }}</span
      >
    </router-link>
    <span class="text-md text-secondary block"
      >{{ tutorial.attributes.MinutesToComplete }} minutes</span
    >
  </div>
</template>
