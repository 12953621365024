<script setup lang="ts">
import Breadcrumb from '@/components/content-app/shared/ContentAppBreadcrumb.vue'
import type ITutorial from '@/components/content-app/interface/tutorials/ITutorial'
import TutorialsService from '@/services/content-app/tutorials.service'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import NextPreviousTutorialHeader from '@/components/content-app/tutorials/TutorialExercisePage/NextPreviousTutorialHeader.vue'
import NextTutorialFooter from '@/components/content-app/tutorials/TutorialExercisePage/NextTutorialFooter.vue'
import TutorialExerciseBanner from '@/components/content-app/tutorials/TutorialExercisePage/TutorialExerciseBanner.vue'

const route = useRoute()
const _tutorialsService = new TutorialsService()
const languageUrl = computed(() => route.params.languageUrl as string)
const moduleUrl = computed(() => route.params.moduleUrl as string)
const tutorialUrl = computed(() => route.params.tutorialUrl as string)

const tutorialData = ref<ITutorial | undefined>(undefined)
const tutorialsInModule = ref<ITutorial[]>([])

const ideData = ref()

onMounted(async () => {
  await fetchDataAndInjectHTML()
})

watch(
  () => [languageUrl.value, moduleUrl.value, tutorialUrl.value],
  async () => {
    await fetchDataAndInjectHTML()
  }
)

/**
 * Retrieve tutorial data and inject into HTML
 */
async function fetchDataAndInjectHTML() {
  const data: ITutorial = await _tutorialsService.getOneTutorial(
    languageUrl.value,
    moduleUrl.value,
    tutorialUrl.value
  )
  // console.log('TUTORIAL', data)
  tutorialData.value = data

  const tutorialsArray: ITutorial[] = await _tutorialsService.getAllTutorialsFromModule(
    languageUrl.value,
    moduleUrl.value
  )
  // console.log('MODULE', moduleData)
  tutorialsInModule.value = tutorialsArray

  //IDE Template
  // eslint-disable-next-line no-useless-escape
  ideData.value = `<html lang="en"><body><div data-pym-src='https://www.jdoodle.com/plugin' data-language="${tutorialData.value?.attributes.IdeLanguage}">${tutorialData.value?.attributes.IdeTemplate}</div><script src="https://www.jdoodle.com/assets/jdoodle-pym.min.js" type="text/javascript"><\/script></body></html>`
}
</script>

<template>
  <breadcrumb></breadcrumb>
  <div class="h-full">
    <div class="flex h-full w-full flex-col lg:h-screen lg:flex-row">
      <div class="h-auto w-full lg:w-1/2 lg:overflow-y-auto">
        <next-previous-tutorial-header
          :currentLanguageUrl="languageUrl"
          :currentModuleUrl="moduleUrl"
          :currentTutorialUrl="tutorialUrl"
          :tutorialsInModule="tutorialsInModule"
        ></next-previous-tutorial-header>
        <div class="p-5">
          <div class="mb-6">
            <h1 class="text-4xl">{{ tutorialData?.attributes.Title }}</h1>
            <p class="text-sm text-gray-500">
              {{ tutorialData?.attributes.MinutesToComplete }} Minutes
            </p>
          </div>
          <div class="doc_content" v-html="tutorialData?.attributes.Content"></div>
          <div>
            <next-tutorial-footer
              :currentLanguageData="tutorialData?.attributes.module?.data.attributes.language?.data"
              :currentModuleUrl="moduleUrl"
              :currentTutorialUrl="tutorialUrl"
              :tutorialsInModule="tutorialsInModule"
            ></next-tutorial-footer>
          </div>
        </div>
        <tutorial-exercise-banner></tutorial-exercise-banner>
      </div>
      <div class="h-full min-h-[600px] w-full p-1 lg:w-1/2 lg:p-3">
        <iframe ref="iframe" :srcdoc="ideData" class="block min-h-[600px] w-full"></iframe>
      </div>
    </div>
  </div>
</template>
