import { SUBSCRIPTION_PLAN_NAME } from '@/components/shared/subscription/enum/subscription.enum'

export interface IApiPlan {
  id: number
  name: string
  value: string
  credits: string
}

export interface IBundledPlan {
  name: string
  value: string
  type?: string
}

export const apiPlansData: IApiPlan[] = [
  { id: 0, name: 'Free', value: 'Free', credits: '20' },
  { id: 1, name: '$10', value: '$10', credits: '1k' },
  { id: 2, name: '$20', value: '$20', credits: '2k' },
  { id: 3, name: '$50', value: '$50', credits: '5k' },
  { id: 4, name: '$100', value: '$100', credits: '10k' },
  { id: 5, name: '$200', value: '$200', credits: '20k' },
  { id: 6, name: '$300', value: '$300', credits: '30k' },
  { id: 7, name: '$400', value: '$400', credits: '40k' },
  { id: 8, name: '$500', value: '$500', credits: '50k' },
  { id: 9, name: 'Unlimited', value: '$990', credits: 'Unlimited' }
]

//TODO: Delete this in the future
export const ide_teach_plansData: IBundledPlan[] = [
  { name: 'Free', value: SUBSCRIPTION_PLAN_NAME.PFREE },
  { name: 'Pro', value: SUBSCRIPTION_PLAN_NAME.PRO }
]

export const pluginPlansData: IBundledPlan[] = [
  { name: 'pFree', value: '$0', type: 'Free' },
  { name: 'Pro', value: '$9.99', type: 'Pro' },
  // { name: 'Team', value: '$100', type: 'Team' } ,
  { name: 'Teacher', value: '$19.99', type: 'Teacher' }
]

export const UNLIMITED_LIMIT = -999

export const DESKTOP_SIZE = 1024
