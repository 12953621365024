declare global {
  interface Window {
    HSTabs: any
    $hsTabsCollection: any
  }
}
/**
 * open the tab
 * @param hs hash to close
 * @param scrollIntoView scroll to the tab
 */
const setActiveTab = (hs: string, scrollIntoView: boolean = true) => {
  const htmlHs: HTMLElement | null = document.querySelector(`#${hs}-item`)

  if (!htmlHs) return

  htmlHs.classList.add('active')
  const tab: HTMLElement | null = htmlHs.closest('[aria-label="Tabs"][role="tablist"]')

  if (!tab) return

  const elInCollection = window?.$hsTabsCollection?.find((el: any) => {
    return Array.from(el.element.toggles || []).includes(tab)
  })

  if (!elInCollection && window && window.HSTabs) {
    htmlHs.classList.add('active')
    const element = new window.HSTabs(tab)
    element.currentContent?.classList.remove('hidden')
    window?.$hsTabsCollection?.push({
      id: element?.el?.id || window?.$hsTabsCollection?.length + 1,
      element
    })
  }

  window?.HSTabs.open(htmlHs)
  if (scrollIntoView) htmlHs.scrollIntoView({ behavior: 'smooth' })
}
/**
 * scroll to output editor on execute program
 * @param hs id to scroll
 */
const scrollToOutputEditor = (hs: string) => {
  const htmlHs: HTMLElement | null = document.getElementById(hs)
  if (htmlHs) {
    htmlHs.scrollIntoView({ behavior: 'smooth' })
  }
}

export default {
  setActiveTab,
  scrollToOutputEditor
}
