import { DEFAULTLAYPUTS, SCREENSIZES } from '@/utils/customPlugin'
import { languagesItems, type ILanguage } from '@/utils/sharedData/languages'
import { cloneDeep } from 'lodash-es'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const usePluginStore = defineStore('plugin', () => {
  const isPymKey = ref<string | null>(null)
  const isPlugin = ref<boolean>(false)

  // simple plugin
  const pluginHasFiles = ref<boolean>(false)
  const pluginFiles = ref<any[]>([])
  const pluginIsFileUploaded = ref<boolean>(false)
  const pluginRunDiabled = ref<boolean>(false)

  // custom plugin
  const isEditable = ref<boolean>(false)
  const isCusomPlugin = ref<boolean>(false)
  const isClientkey = ref<string | null>(null)
  const isCustomkey = ref<string | null>(null)
  const initialPluginResponse = ref<any | null>(null)
  const pluginResponse = ref<any | null>(null)

  const showCustomPlugin = ref<boolean>(false)
  const screenSize = ref<(typeof SCREENSIZES)[keyof typeof SCREENSIZES]>(SCREENSIZES.LG)
  const layouts = ref<typeof DEFAULTLAYPUTS>({} as any)
  const layoutByScreenSize = ref<(typeof DEFAULTLAYPUTS)[keyof typeof DEFAULTLAYPUTS]>([] as any)
  const components = ref<any[]>([])
  const availbleComponents = ref<any[]>([])
  const selectedComponent = ref<any | null>(null)
  const availbleProjects = ref<any[]>([])
  const selectedProject = ref<any | null>(null)
  const dragging = ref<boolean>(false)
  const mouseInGrid = ref<boolean>(false)
  const functionals = ref<any[]>([])
  const featurePermissionError = ref<string | null>(null)
  const availableFunctionals = ref<any[]>([])
  const selectedFunctionals = ref<any[]>([])

  const languages: ILanguage[] = cloneDeep(languagesItems)
    .sort((a: ILanguage, b: ILanguage) => (a.displayName > b.displayName ? 1 : -1))
    .filter(
      (lang: ILanguage) => lang.isSupportedInAssignments == true || lang.language === 'blockly'
    )
  const language = ref<any | null>(languages[0])
  const defautlLanguageIndex = ref<number | null>(null)
  const defautlVersionIndex = ref<number | null>(null)
  const script = ref<string>('')
  const defaultScript = ref<string | null>('')

  const isOnExecuteCompleteEnabled = ref<boolean>(false)

  // simple plugin
  const isDefaultLanguage = computed(() => {
    return language.value
  })
  const isDefaultLanguageAceCode = computed(() => {
    return language.value.language
  })
  const isDragging = computed(() => {
    return dragging.value
  })
  const isMouseInGrid = computed(() => {
    return mouseInGrid.value
  })

  // custom plugin
  const isGetCode = computed(() => {
    return selectedFunctionals.value.some((f: any) => f.id === 0)
  })
  const isSetCode = computed(() => {
    return selectedFunctionals.value.some((f: any) => f.id === 1)
  })
  const isExecute = computed(() => {
    return selectedFunctionals.value.some((f: any) => f.id === 2)
  })
  const isGetFiles = computed(() => {
    return selectedFunctionals.value.some((f: any) => f.id === 3)
  })
  const isGetLanguageList = computed(() => {
    return selectedFunctionals.value.some((f: any) => f.id === 4)
  })
  const isSetLanguage = computed(() => {
    return selectedFunctionals.value.some((f: any) => f.id === 5)
  })
  const isGetVersionList = computed(() => {
    return selectedFunctionals.value.some((f: any) => f.id === 6)
  })
  const isSetVersion = computed(() => {
    return selectedFunctionals.value.some((f: any) => f.id === 7)
  })
  const isSetInteractiveMode = computed(() => {
    return selectedFunctionals.value.some((f: any) => f.id === 8)
  })
  const isSetInputArgs = computed(() => {
    return selectedFunctionals.value.some((f: any) => f.id === 9)
  })
  const isSetStdinInput = computed(() => {
    return selectedFunctionals.value.some((f: any) => f.id === 10)
  })
  const isClearCodeEditor = computed(() => {
    return selectedFunctionals.value.some((f: any) => f.id === 11)
  })
  const isClearOutputEditor = computed(() => {
    return selectedFunctionals.value.some((f: any) => f.id === 12)
  })
  const isOnCodeEditorUpdate = computed(() => {
    return selectedFunctionals.value.some((f: any) => f.id === 13)
  })
  const isOnExecuteComplete = computed(() => {
    return selectedFunctionals.value.some((f: any) => f.id === 14)
  })
  const isPostToJDoodle = computed(() => {
    return selectedFunctionals.value.some((f: any) => f.id === 15)
  })
  const isStopExecute = computed(() => {
    return selectedFunctionals.value.some((f: any) => f.id === 16)
  })
  const isShowCustomPlugin = computed(() => {
    return showCustomPlugin.value
  })
  const isInitialPluginResponse = computed(() => {
    return initialPluginResponse.value
  })
  const isPluginResponse = computed(() => {
    return pluginResponse.value
  })

  // simple plugin
  /**
   * pym key
   * @param value - The value to set
   */
  const setPymKey = (value: string | null) => {
    isPymKey.value = value
  }
  /**
   * Set the isPlugin
   * @param value - The value to set
   */
  const setIsPlugin = (value: boolean) => {
    isPlugin.value = value
  }
  /**
   * Set the plugin has files
   * @param value - The value to set
   */
  const setPluginHasFiles = (value: boolean) => {
    pluginHasFiles.value = value
  }
  /**
   * Set the plugin files
   * @param value - The value to set
   */
  const setPluginFiles = (value: any[]) => {
    pluginFiles.value = value
  }
  /**
   * Set the plugin is file uploaded
   * @param value - The value to set
   */
  const setPluginIsFileUploaded = (value: boolean) => {
    pluginIsFileUploaded.value = value
  }
  /**
   * Set the plugin run disabled
   * @param value - The value to set
   */
  const setPluginRunDiabled = (value: boolean) => {
    pluginRunDiabled.value = value
  }

  // custom plugin
  /**
   * set plugin editable
   * @param value - The value to set.
   */
  const setIsEditable = (value: boolean) => {
    isEditable.value = value
  }
  /**
   * set custom plugin
   * @param value - The value to set.
   */
  const setIsCusomPlugin = (value: boolean) => {
    isCusomPlugin.value = value
  }
  /**
   * set client key
   * @param value - The value to set.
   */
  const setClientkey = (value: string | null) => {
    isClientkey.value = value
  }
  /**
   * set plugin key
   * @param value - The value to set.
   */
  const setCustomkey = (value: string | null) => {
    isCustomkey.value = value
  }
  /**
   * set initial plugin response
   * @param value - The value to set.
   */
  const setInitialPluginResponse = (value: any) => {
    initialPluginResponse.value = value
  }
  /**
   * update initial plugin response
   * @param key - The key to set.
   * @param value - The value to set.
   */
  const updateInitialPluginResponse = (key: string, value: any) => {
    pluginResponse.value[key] = value
    initialPluginResponse.value[key] = value
  }
  /**
   * set plugin response
   * @param value - The value to set.
   */
  const setPluginResponse = (value: any) => {
    pluginResponse.value = value
  }
  /**
   * update plugin response
   * @param key - The key to set.
   * @param value - The value to set.
   */
  const updatePluginResponse = (key: string, value: any) => {
    pluginResponse.value[key] = value
  }
  /**
   * set default language
   * @param index - The index to set.
   */
  const setLanguage = (index: number) => {
    language.value = languages[index]
  }
  /**
   * set default language
   * @param index - The index to set.
   */
  const setDefautlLanguageIndex = (index: number) => {
    defautlLanguageIndex.value = index
    language.value = languages[index]
  }
  /**
   * set default version
   * @param index - The index to set.
   */
  const setDefautlVersionIndex = (index: number | null) => {
    defautlVersionIndex.value = index
  }
  /**
   * set script
   * @param value - The value to set.
   */
  const setScript = (value: string) => {
    script.value = value
  }
  /**
   * set default script
   * @param value - The value to set.
   */
  const setDefaultScript = (value: string | null) => {
    defaultScript.value = value
  }
  /**
   * set show custom plugin
   * @param value - The value to set.
   */
  const setShowCustomPlugin = (value: boolean) => {
    showCustomPlugin.value = value
  }
  /**
   * set screenSize
   * @param value - The value to set.
   */
  const setScreenSize = (value: (typeof SCREENSIZES)[keyof typeof SCREENSIZES]) => {
    screenSize.value = value
  }
  /**
   * set layouts
   * @param value - The value to set.
   */
  const setLayouts = (value: typeof DEFAULTLAYPUTS | any) => {
    layouts.value = value
  }
  /**
   * set layoutByScreenSize
   * @param value - The value to set.
   */
  const setLayoutByScreenSize = (value: typeof DEFAULTLAYPUTS | any) => {
    layoutByScreenSize.value = value
  }
  /**
   * set components
   * @param value - The value to set.
   */
  const setComponents = (value: any[]) => {
    components.value.splice(0, components.value.length)
    components.value.push(...value)
  }
  /**
   * set availbleComponents
   * @param value - The value to set.
   */
  const setAvailbleComponents = (value: any[]) => {
    availbleComponents.value.splice(0, availbleComponents.value.length)
    availbleComponents.value.push(...value)
  }
  /**
   * set selectedComponent
   * @param value - The value to set.
   */
  const setSelectedComponent = (value: any) => {
    selectedComponent.value = value
  }
  /**
   * set availbleProjects
   * @param value - The value to set.
   */
  const setAvailbleProjects = (value: any[]) => {
    availbleProjects.value.splice(0, availbleProjects.value.length)
    availbleProjects.value.push(...value)
  }
  /**
   * set selectedProject
   * @param value - The value to set.
   */
  const setSelectedProject = (value: any) => {
    selectedProject.value = value
  }
  /**
   * set functionals
   * @param value - The value to set.
   */
  const setFunctionals = (value: any[]) => {
    functionals.value.splice(0, functionals.value.length)
    functionals.value.push(...value)
  }
  /**
   * set featurePermissionError
   * @param value - The value to set.
   */
  const setFeaturePermissionError = (value: string | null) => {
    featurePermissionError.value = value
  }
  /**
   * set availableFunctionals
   * @param value - The value to set.
   */
  const setAvailableFunctionals = (value: any[]) => {
    availableFunctionals.value.splice(0, availableFunctionals.value.length)
    availableFunctionals.value.push(...value)
  }
  /**
   * set selectedFunctional
   * @param value - The value to set.
   */
  const addToSelectedFunctional = (value: any) => {
    selectedFunctionals.value.push(value)
  }
  /**
   * set selectedFunctionals
   * @param value - The value to set.
   */
  const setSelectedFunctionals = (value: any[]) => {
    selectedFunctionals.value.splice(0, selectedFunctionals.value.length)
    selectedFunctionals.value.push(...value)
  }
  /**
   * set dragging
   * @param value - The value to set.
   */
  const setDragging = (value: boolean) => {
    dragging.value = value
  }
  /**
   * set mouseInGrid
   * @param value - The value to set.
   */
  const setMouseInGrid = (value: boolean) => {
    mouseInGrid.value = value
  }
  /**
   * set onExecuteCompleteEnabled
   * @param value - The value to set.
   */
  const setOnExecuteCompleteEnabled = (value: boolean) => {
    isOnExecuteCompleteEnabled.value = value
  }

  return {
    isPlugin,
    setIsPlugin,
    isPymKey,
    setPymKey,
    pluginHasFiles,
    setPluginHasFiles,
    pluginFiles,
    setPluginFiles,
    pluginIsFileUploaded,
    setPluginIsFileUploaded,
    pluginRunDiabled,
    setPluginRunDiabled,
    isEditable,
    setIsEditable,
    isCusomPlugin,
    setIsCusomPlugin,
    isClientkey,
    setClientkey,
    isCustomkey,
    setCustomkey,
    isOnExecuteCompleteEnabled,
    setOnExecuteCompleteEnabled,
    isGetCode,
    isSetCode,
    isExecute,
    isGetFiles,
    isGetLanguageList,
    isSetLanguage,
    isGetVersionList,
    isSetVersion,
    isSetInteractiveMode,
    isSetInputArgs,
    isSetStdinInput,
    isClearCodeEditor,
    isClearOutputEditor,
    isOnCodeEditorUpdate,
    isOnExecuteComplete,
    isPostToJDoodle,
    isStopExecute,
    isShowCustomPlugin,
    setShowCustomPlugin,
    isInitialPluginResponse,
    setInitialPluginResponse,
    updateInitialPluginResponse,
    isPluginResponse,
    setPluginResponse,
    updatePluginResponse,
    languages,
    isDefaultLanguage,
    isDefaultLanguageAceCode,
    setLanguage,
    defautlLanguageIndex,
    setDefautlLanguageIndex,
    defautlVersionIndex,
    setDefautlVersionIndex,
    script,
    setScript,
    defaultScript,
    setDefaultScript,
    screenSize,
    setScreenSize,
    layouts,
    setLayouts,
    layoutByScreenSize,
    setLayoutByScreenSize,
    components,
    setComponents,
    availbleComponents,
    setAvailbleComponents,
    selectedComponent,
    setSelectedComponent,
    availbleProjects,
    setAvailbleProjects,
    selectedProject,
    setSelectedProject,
    functionals,
    setFunctionals,
    availableFunctionals,
    setAvailableFunctionals,
    selectedFunctionals,
    featurePermissionError,
    setFeaturePermissionError,
    addToSelectedFunctional,
    setSelectedFunctionals,
    isDragging,
    setDragging,
    isMouseInGrid,
    setMouseInGrid
  }
})
