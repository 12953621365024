<script setup lang="ts">
import { ref, computed } from 'vue'
import uploadService from '@/services/ide/upload.service'
import editorService from '@/services/ide/editor.service'
import executeService from '@/services/ide/execute.service'
import htmlExecuteService from '@/services/ide/languages/html/execute.service'
import { useIdeStore } from '@/stores/ide.store'
import { usePluginStore } from '@/stores/plugin.store'
import { IDEVIEWMODELS } from '@/utils/models'
import { COMPONENTS } from '@/utils/customPlugin'
import SettingEmbedDropdown from '@/components/ide/embed/settings/SettingEmbedDropdown.vue'

const ideStore = useIdeStore()
const pluginStore = usePluginStore()

const uploadInput = ref<HTMLElement | null>(null)

const isPlugin = computed(() => {
  return pluginStore.isPlugin
})
const isShowUploadButton = computed(() => {
  if (pluginStore.isCusomPlugin) {
    const indexOfPluginFileComp = pluginStore.layoutByScreenSize.findIndex(
      (c) => c.name === COMPONENTS.FILECOMP
    )
    if (indexOfPluginFileComp > -1) {
      return true
    } else return false
  } else return false
})
const isEmbedded = computed(() => {
  return ideStore.isEmbedded
})
const isHtml = computed(() => {
  return ideStore.isHtml
})
const isCodeExecuting = computed(() => {
  return ideStore.isCodeExecuting
})
const isUploadInProgress = computed(() => {
  return ideStore.isUploadInProgress
})
const toogleFullScreenIcon = computed(() => {
  return ideStore.isFullScreen ? 'fa-compress' : 'fa-expand'
})
/**
 * Tries to execute the code
 */
const tryExecute = () => {
  if (isHtml.value) {
    htmlExecuteService.tryExecute()
  } else {
    executeService.tryExecute()
  }
}
/**
 * Stops the execution
 */
const stopExecution = () => {
  if (isHtml.value) {
    htmlExecuteService.stopExecution()
  } else {
    executeService.stopExecution()
  }
}
/**
 * Handles the upload click
 */
const handleUploadClick = () => {
  uploadInput?.value?.click()
}
/**
 * Toggles the full screen
 */
const toogleFullScreen = async () => {
  ideStore.setFullScreen(!ideStore.isFullScreen)
  await new Promise((resolve) => setTimeout(resolve, 100))
  editorService.resizeCodeEditor()
}
</script>

<template>
  <div class="rounded-lg print:hidden">
    <div class="flex w-full justify-center align-middle">
      <div
        :class="[
          'flex',
          'w-fit',
          '',
          'gap-2',
          '',
          {
            'flex-row lg:flex-row': isEmbedded
          }
        ]"
      >
        <button
          v-if="!isCodeExecuting"
          class="btn btn-primary flex w-full gap-2 align-middle"
          @click="tryExecute"
        >
          <FontAwesomeIcon icon="fa-play" class="h-4 w-4" />
          Execute
        </button>
        <button
          v-if="isCodeExecuting"
          class="btn btn-secondary flex w-fit gap-2 border align-middle"
          @click="stopExecution"
        >
          <FontAwesomeIcon spin icon="fa-spinner" class="h-4 w-4" />
          Stop Execution
        </button>
        <div v-if="!isPlugin" class="flex gap-2">
          <div v-if="!isEmbedded && !isHtml">
            <button
              v-if="!isUploadInProgress"
              class="btn btn-secondary w-fit min-w-fit"
              @click="handleUploadClick"
            >
              <span>
                <input
                  v-show="false"
                  ref="uploadInput"
                  class="hidden"
                  type="file"
                  name="file"
                  @change="uploadService.tryUploadFile($event)"
                />
                <FontAwesomeIcon icon="fa-upload" class="h-4 w-4" />
              </span>
            </button>
            <button
              v-if="isUploadInProgress"
              class="btn btn-secondary w-fit min-w-fit"
              @click="uploadService.cancelUpload"
            >
              <FontAwesomeIcon bounce icon="fa-stop" class="error h-4 w-4" />
            </button>
          </div>
          <button
            v-if="!isEmbedded"
            class="btn btn-secondary w-fit min-w-fit"
            :data-hs-overlay="`#${IDEVIEWMODELS.SETTINGS}`"
          >
            <FontAwesomeIcon icon="fa-gears" class="h-4 w-4" />
          </button>
          <SettingEmbedDropdown v-else />
          <button
            v-if="!isEmbedded && !isHtml"
            class="btn btn-secondary w-fit min-w-fit"
            @click="toogleFullScreen"
          >
            <FontAwesomeIcon :icon="toogleFullScreenIcon" class="h-4 w-4" />
          </button>
        </div>
        <div v-if="isShowUploadButton">
          <button
            v-if="!isUploadInProgress"
            class="btn btn-secondary w-fit min-w-fit"
            @click="handleUploadClick"
          >
            <span>
              <input
                v-show="false"
                ref="uploadInput"
                class="hidden"
                type="file"
                name="file"
                @change="uploadService.tryUploadFile($event)"
              />
              <FontAwesomeIcon icon="fa-upload" class="h-4 w-4" />
            </span>
          </button>
          <button
            v-if="isUploadInProgress"
            class="btn btn-secondary w-fit min-w-fit"
            @click="uploadService.cancelUpload"
          >
            <FontAwesomeIcon bounce icon="fa-stop" class="error h-4 w-4" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
