import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useRecaptchaStore = defineStore('rechatcha', () => {
  const gcId = ref<number | null>(null)
  const rechaptcha = ref<any | null>(null)
  const recaptchaPromise = ref<Promise<boolean> | null>(null)
  const recaptchaPromiseFinish = ref<boolean>(true)
  const recaptchaPromiseResolve = ref<((value: boolean) => void) | null>(null)
  const recaptchaPromiseReject = ref<((reason?: any) => void) | null>(null)

  const isRecaptchaPromise = computed(() => {
    return recaptchaPromise.value || null
  })
  const isRecaptchaPromiseFinish = computed(() => {
    return recaptchaPromiseFinish.value || false
  })
  /**
   * Create a promise to recaptcha the action
   * @returns promise to recaptcha the action
   */
  const setrecaptchaDetails = (): Promise<boolean> => {
    if (recaptchaPromise.value) {
      setrecaptchaDetailsReject('Previous recaptcha is not finished. Please try again.')
      recaptchaPromiseFinish.value = true
    }
    recaptchaPromise.value = new Promise((resolve, reject) => {
      recaptchaPromiseFinish.value = false
      recaptchaPromiseResolve.value = resolve
      recaptchaPromiseReject.value = reject
    })

    return recaptchaPromise.value
  }
  /**
   * on recaptcha click
   */
  const setrecaptchaDetailsResolve = () => {
    if (recaptchaPromiseResolve.value) {
      recaptchaPromiseFinish.value = true
      recaptchaPromiseResolve.value(true)
    }
  }
  /**
   * on reject click
   * @param error - the error
   */
  const setrecaptchaDetailsReject = (error: any) => {
    if (recaptchaPromiseReject.value) {
      recaptchaPromiseFinish.value = true
      recaptchaPromiseReject.value(error)
    }
  }

  return {
    gcId,
    rechaptcha,
    isRecaptchaPromise,
    isRecaptchaPromiseFinish,
    setrecaptchaDetails,
    setrecaptchaDetailsResolve,
    setrecaptchaDetailsReject
  }
})
