import { TEACH_CONSTANTS } from '@/components/guru/constants/teach.constants'
import {
  ASSESSMENT_STATUS,
  TEST_CASE_ACTIONS
} from '@/components/organisation/enum/assessment.enums'
import type IAssignmentDetailed from '@/components/organisation/interface/IAssignmentDetailed'
import type IAssignmentMini from '@/components/organisation/interface/IAssignmentMini'
import type { IEvaluationData } from '@/components/organisation/interface/IEvaluationData'
import { type IInstituteSubscriptionInfo } from '@/components/organisation/interface/IInstituteSubscriptionInfo'
import type { IMultipleChoiceOption_ForAssignment } from '@/components/organisation/interface/IMultipleChoiceOption_ForAssignment'
import { type IQuestionMini } from '@/components/organisation/interface/IQuestionMini'
import type { IQuestion_ForAssignment } from '@/components/organisation/interface/IQuestion_ForAssignment'
import type { IStudentInAssignment } from '@/components/organisation/interface/IStudentInAssignment'
import type { ISubmission_Question } from '@/components/organisation/interface/ISubmission_Question'
import type { ITestCase } from '@/components/organisation/interface/ITestCase'
import { languagesItems, type ILanguage } from '@/utils/sharedData/languages'
import { UNLIMITED_LIMIT } from '@/utils/sharedData/plans'
import { defineStore } from 'pinia'
import { computed, reactive, ref } from 'vue'

export const useAssessmentStore = defineStore('assessment', () => {
  // ===== Assignment related =====
  const assignmentStartDate = ref<string>('')
  const assignmentStartTime = ref<string>('')
  const questionToEdit = ref<IQuestionMini | undefined>(undefined)
  const showQuestionForm = ref<boolean>()
  const testCaseToEdit = ref<ITestCase | undefined>(undefined)
  const testCaseToEdit_unmodified = ref<ITestCase | undefined>(undefined)
  const testCaseMode = ref<TEST_CASE_ACTIONS | undefined>(undefined)

  // ===== Subscription related =====
  //Stores
  const instituteSubscriptionInfo = ref<IInstituteSubscriptionInfo | null>(null)
  const instituteAssignments = ref<IAssignmentMini[] | null>(null)
  const currentAssignment = ref<IAssignmentDetailed | null>(null)

  //Computed
  const draftLimitReached = computed(() => {
    //Once subscription info is fetched
    if (instituteSubscriptionInfo.value) {
      //If FREE
      // if (instituteSubscriptionInfo.value.plan == PLAN_ENUM.FREE) {
      const draftAssignments = instituteAssignments.value?.filter(
        (ass: IAssignmentMini) => ass.status == ASSESSMENT_STATUS.DRAFT
      )

      // Count draft assignments
      if (draftAssignments) {
        if (instituteSubscriptionInfo.value?.limit.draftAssignments == null) return false
        return draftAssignments.length >= instituteSubscriptionInfo.value?.limit.draftAssignments
      } else {
        return false
      }
    }
    return true
  })

  const activeAssignmentLimitReached = computed(() => {
    if (instituteSubscriptionInfo.value) {
      //If FREE
      // if (instituteSubscriptionInfo.value.plan == PLAN_ENUM.FREE) {
      const activeAssignments = instituteAssignments.value?.filter((ass: IAssignmentMini) => {
        return ass.status == ASSESSMENT_STATUS.IN_PROGRESS || ass.status == ASSESSMENT_STATUS.READY
      })
      //Count assignments
      if (activeAssignments) {
        if (
          instituteSubscriptionInfo.value?.limit.activeAssignments == null ||
          instituteSubscriptionInfo.value?.limit.activeAssignments == UNLIMITED_LIMIT
        )
          return false

        return activeAssignments.length >= instituteSubscriptionInfo.value?.limit.activeAssignments
      } else {
        return false
      }
    }
    return true
  })

  const questionsLimitReached = computed(() => {
    if (currentAssignment.value && instituteSubscriptionInfo.value) {
      //If FREE
      // if (instituteSubscriptionInfo.value.plan == PLAN_ENUM.FREE) {
      if (instituteSubscriptionInfo.value?.limit.questions == null) return false

      //Count questions
      return (
        currentAssignment.value.questions.length >= instituteSubscriptionInfo.value?.limit.questions
      )
    }
    return true
  })

  //Messages

  const draftLimitMessage = computed(() => {
    return `You have reached the organisation's plan limit of ${instituteSubscriptionInfo.value?.limit.draftAssignments} draft assignments`
  })

  // ====== Evaluation-related ======
  const studentUnderEvaluation = ref<IStudentInAssignment | undefined>(undefined)

  // ====== Language-related ======
  const assignmentDefaultLanguage = ref<string>()
  const assignmentTryLanguage = ref<string | null>('')
  const assignmentDefaultLanguageVersionIndex = ref<number | undefined>()
  const allLanguages = languagesItems
  const codeAnswer = ref<string | undefined>(undefined)
  const languagesSupportedByAssignment = languagesItems.filter(
    (lang: ILanguage) => lang.isSupportedInAssignments == true
  )

  // ====== For Attend/Preview/Evaluate Questions ======
  const initialQuestionForm = reactive({
    assQuestionId: '',
    question: '',
    questionType: '',
    language: {},
    versionIndex: null,
    markForCorrectAnswer: null,
    optionLevelMarking: false,
    negativeMark: null,
    correctAnswerOption: null,
    options: [] as IMultipleChoiceOption_ForAssignment[],
    testCases: [],
    args: '',
    stdin: '',
    output: '',
    openTrySection: false,
    isCodeExecuting: false,
    executionTime: null,
    memory: 0,
    cpuTime: 0,
    isMarkedForReview: false,
    answer: undefined,
    evaluated: false,
    autoCorrectNotes: '',
    minLength: null,
    maxLength: null,
    mark: null,
    comment: null,

    questionIdToSave: undefined as undefined | number | string,
    reachedReview: undefined
  })

  const currentSavedAnswers = ref<ISubmission_Question[]>()

  // ======= Functions =======

  /**
   * set assignment time and date
   * @param date - assignment date
   * @param time - assignment time
   */
  function setTimeandDate(date: string, time: string) {
    assignmentStartDate.value = date
    assignmentStartTime.value = time
  }

  /**
   * Set stored subscription info from service
   * @param subscriptionInfo IInstituteSubscriptionInfo Object
   */
  function setSubscriptionInfo(subscriptionInfo: IInstituteSubscriptionInfo) {
    instituteSubscriptionInfo.value = subscriptionInfo
  }

  /**
   * Set stored assignments to compute limitations
   * @param assignments array of IAssignmentMini
   */
  function setAssignments(assignments: IAssignmentMini[]) {
    instituteAssignments.value = assignments
  }

  /**
   * Set stored current assignment to compute limitations
   * @param assignment array of IAssignmentMini
   */
  function setCurrentAssignment(assignment: IAssignmentDetailed | null) {
    currentAssignment.value = assignment
    setAssignmentLanguage(assignment)
  }

  // ********** Assignment related **********
  /**
   * Identifies the language of an assigment using the saved strng
   * @param question IQuestion_ForAssignment object
   */
  function setAssignmentLanguageFromRef(question: IQuestion_ForAssignment) {
    if (question) {
      assignmentDefaultLanguage.value = question.language || 'none'
      assignmentTryLanguage.value = null
      assignmentDefaultLanguageVersionIndex.value = (question.versionIndex as number) || 0
    }
  }
  /**
   * Identifies the language of an assigment using the saved strng
   * @param language string
   */
  function setAssignmentTryLanguage(language: string) {
    if (language) {
      assignmentTryLanguage.value = language
      assignmentDefaultLanguageVersionIndex.value = 0
    }
  }

  /**
   * Identifies the language of an assigment using the saved strng
   * @param assignment IAssignment object
   */
  function setAssignmentLanguage(assignment: IAssignmentDetailed | IEvaluationData | null) {
    if (!assignment) {
      assignmentDefaultLanguage.value = undefined
    }

    //If evaluation data
    if ((assignment as IEvaluationData).assignment) {
      assignmentDefaultLanguage.value = (assignment as IEvaluationData).assignment.defaultLanguage
      assignmentDefaultLanguageVersionIndex.value = (
        assignment as IEvaluationData
      ).assignment.versionIndex
    } else {
      //If IAssignmentDetailed
      if (assignment) {
        assignmentDefaultLanguage.value = (assignment as IAssignmentDetailed).defaultLanguage
        assignmentDefaultLanguageVersionIndex.value = (
          assignment as IAssignmentDetailed
        ).versionIndex
      }
    }
  }

  /**
   * @param code - sets code answer
   */
  function setCodeAnswer(code: string) {
    codeAnswer.value = code
  }

  /**
   * Get language displayname of default language
   * @returns language displayname
   */
  function getCurrentAssignmentLanguageDisplayName() {
    return allLanguages.find((lang: ILanguage) => lang.language == assignmentDefaultLanguage.value)
      ?.displayName
  }

  /**
   * Get assignment's language version
   * @returns version displayname
   */
  function getCurrentAssignmentLanguageVersion() {
    const versions = allLanguages.find(
      (lang: ILanguage) => lang.language == assignmentDefaultLanguage.value
    )?.versions

    if (versions && assignmentDefaultLanguageVersionIndex.value != undefined) {
      return versions[assignmentDefaultLanguageVersionIndex.value]
    }
  }

  /**
   * @param questionType - questiontype
   * @returns - returns boolean
   */
  function isSingleChoiceQuestion(questionType: number | undefined) {
    return questionType === TEACH_CONSTANTS.QUESTION_TYPES[0].value
  }

  /**
   * @param questionType - questiontype
   * @returns - returns boolean
   */
  function isMultipleChoiceQuestion(questionType: number | undefined) {
    return questionType === TEACH_CONSTANTS.QUESTION_TYPES[1].value
  }

  /**
   * @param questionType - questionstype
   * @returns - returns boolean
   */
  function isChoiceQuestion(questionType: number | undefined) {
    return isSingleChoiceQuestion(questionType) || isMultipleChoiceQuestion(questionType)
  }

  /**
   * @param questionType - questiontype
   * @returns - returns boolean
   */
  function isOpenEndedQuestion(questionType: number | undefined) {
    return questionType === TEACH_CONSTANTS.QUESTION_TYPES[2].value
  }

  /**
   * @param questionType - questiontype
   * @returns - returns boolean
   */
  function isProgramQuestion(questionType: number | undefined) {
    return questionType === TEACH_CONSTANTS.QUESTION_TYPES[3].value
  }

  /**
   * @param questionType - questiontype
   * @returns - returns boolean
   */
  function isTrueOrFalseQuestion(questionType: number | undefined) {
    return questionType == TEACH_CONSTANTS.QUESTION_TYPES[4].value
  }

  // ********** Evaluation related **********
  /**
   * @param student - sets student under evalation using object as a parameter
   */
  function setStudentUnderEvaluation(student: IStudentInAssignment) {
    studentUnderEvaluation.value = student
  }

  /**
   * Select testcase object to prefill
   * @param testCase testcase obj
   */
  function setTestCaseToEdit(testCase: ITestCase | undefined) {
    testCaseToEdit.value = testCase
  }

  /**
   * Select testcase object to prefill
   * @param testCase testcase obj
   */
  function setTestCaseToEdit_Unmodified(testCase: ITestCase | undefined) {
    testCaseToEdit_unmodified.value = testCase
  }

  /**
   * Sets mode to add or edit
   * @param action change testcase mode
   */
  function setTestCaseMode(action: TEST_CASE_ACTIONS | undefined) {
    testCaseMode.value = action
  }

  return {
    // === Subscription ===
    instituteSubscriptionInfo,
    setSubscriptionInfo,
    instituteAssignments,
    setAssignments,
    currentAssignment,
    setCurrentAssignment,
    draftLimitReached,
    activeAssignmentLimitReached,
    questionsLimitReached,
    draftLimitMessage,

    // == Assignments ==
    assignmentStartDate,
    assignmentStartTime,
    questionToEdit,
    showQuestionForm,
    currentSavedAnswers,
    testCaseToEdit,
    testCaseToEdit_unmodified,
    setTestCaseToEdit,
    setTestCaseToEdit_Unmodified,
    testCaseMode,
    setTestCaseMode,

    //Evaluation
    studentUnderEvaluation,
    setStudentUnderEvaluation,
    initialQuestionForm,

    //languages
    assignmentDefaultLanguage,
    assignmentTryLanguage,
    assignmentDefaultLanguageVersionIndex,
    allLanguages,
    codeAnswer,
    languagesSupportedByAssignment,
    getCurrentAssignmentLanguageDisplayName,
    getCurrentAssignmentLanguageVersion,

    //functions
    setCodeAnswer,
    isSingleChoiceQuestion,
    isMultipleChoiceQuestion,
    isChoiceQuestion,
    isOpenEndedQuestion,
    isProgramQuestion,
    isTrueOrFalseQuestion,
    setAssignmentLanguage,
    setAssignmentLanguageFromRef,
    setAssignmentTryLanguage,
    setTimeandDate
  }
})
