import ideService from '@/services/ide/ide.service'
import executeService from '@/services/ide/languages/terminal/execute.service'
import loadScriptInBody from '@/services/loadScriptInBody.service'
import { useIdeStore } from '@/stores/ide.store'
import { IDECONSTANT } from '@/utils/ide'
import $ from 'jquery'

/**
 * Initialize the terminal when router changes
 */
const initTernimal = () => {
  loadScriptInBody.loadScriptInBody('/assets/javascript/jquery-terminal.js').then(() => {
    if (useIdeStore().terminal) {
      useIdeStore().terminal.destroy()
    }
    const terminal = $(`#${IDECONSTANT.TERMIANL_EDITOR}`) as any
    terminal.terminal(
      (cmd: any, term: any) => {
        executeService.process(cmd, term)
      },
      {
        prompt: '>',
        name: 'jdoodle-terminal',
        greetings:
          'Welcome to JDoodle - online ' +
          useIdeStore().isTerminal +
          ' Terminal, Starting ' +
          useIdeStore().isTerminal +
          ' Terminal, Please wait...',
        onInit: ideService.initTernimal
      }
    )
  })
}
/**
 * Eject the terminal when router changes
 */
const ejectTerminal = () => {
  loadScriptInBody.unloadScriptInBody('/assets/javascript/jquery-terminal.js')
}
export default {
  initTernimal,
  ejectTerminal
}
