export enum USER_ORG_PRIVILEGE {
  ADMIN = 'admin'
}

export enum USER_EMBED_PRIVILEGE {
  ADMIN = 'admin',
  VIEWER = 'viewer'
}

export enum USER_API_PRIVILEGE {
  ADMIN = 'admin',
  VIEWER = 'viewer'
}

export enum USER_ASSESSMENT_PRIVILEGE {
  ADMIN = 'admin',
  TEACHER = 'teacher',
  STUDENT = 'student'
}

export enum USER_IDE_LICENSE {
  FREE = 'free',
  PREMIUM = 'premium'
}

export enum ORGANISATION_USER_ROLE {
  ACCOUNT_OWNER = 'Account Owner',
  INSTITUTE_ADMIN = 'Institute Admin',
  TEACHER = 'Teacher',
  STUDENT = 'Student'
}

export enum USER_STATUS {
  PENDING_USER_ACCEPTANCE = 'Pending User Acceptance',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  REJECT = 'Rejected',
  EXITED = 'Exited'
}

export enum USER_TYPE {
  TEACHER = 'teacher-active',
  STUDENT_ACTIVE = 'student-active',
  STUDENT = 'student',
  STAFF = 'staff',
  STAFF_ACTIVE = 'staff-active',
  STUDENT_GROUP = 'PvtStudentFromGroup'
}

//Actions
export enum ORG_STUDENT_GROUP_ACTION {
  ADD = 'add',
  EDIT = 'edit',
  REMOVE = 'remove',
  VIEW = 'view'
}

export enum ORG_USER_ACTION {
  ADD = 'add',
  EDIT = 'edit',
  DELETE = 'delete',
  VIEW = 'view'
}

export enum USER_ACTIVATION {
  ACTIVATE = 'activate',
  DEACTIVATE = 'deactivate'
}

export enum USER_TYPE {
  ADD_USER = 'add-user',
  BULK_INVITE = 'bulk-invite'
}
