<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from 'vue'
import TutorialItem from '@/components/content-app/tutorials/ModulePage/TutorialItem.vue'
import { useRoute, useRouter } from 'vue-router'
import type ITutorialModule from '@/components/content-app/interface/tutorials/ITutorialModule'
import LevelPill from '@/components/content-app/tutorials/LevelPill.vue'
import TutorialsService from '@/services/content-app/tutorials.service'
import TutorialUtilsService from '@/utils/content-app/tutorial.utils'
import type ITutorial from '@/components/content-app/interface/tutorials/ITutorial'
import Breadcrumb from '@/components/content-app/shared/ContentAppBreadcrumb.vue'
import RelatedModulesSection from '@/components/content-app/tutorials/ModulePage//RelatedModulesSection.vue'
import DocSocialSharing from '@/components/content-app/docs/DocSocialSharing.vue'
const route = useRoute()
const router = useRouter()
const _tutorialsService = new TutorialsService()
const _tutorialsUtilsService = new TutorialUtilsService()
const languageUrl = route.params.languageUrl as string
const moduleUrl = computed(() => route.params.moduleUrl as string)
const dynamicValues = reactive({
  levelString: '',
  totalTime: ''
})

const moduleData = ref(null as ITutorialModule | null)

onMounted(async () => {
  await fetchModuleData()
})

watch(
  () => moduleUrl.value,
  async () => {
    await fetchModuleData()
  }
)

/**
 * Retrieves module data
 */
async function fetchModuleData() {
  const mData = await _tutorialsService.getOneModule(languageUrl, moduleUrl.value)
  moduleData.value = mData

  if (moduleData.value) {
    dynamicValues.levelString = moduleData.value?.attributes.Level
    dynamicValues.totalTime = _tutorialsUtilsService.getTotalTime(
      moduleData.value?.attributes.tutorials?.data as ITutorial[]
    )
  }
}

/**
 * Goes to the first tutorial in module
 */
function navigateToFirstTutorial() {
  const firstTutorial: ITutorial | undefined = moduleData.value?.attributes.tutorials?.data![0]

  router.push({
    path: `/tutorials/${languageUrl}/${moduleUrl.value}/${firstTutorial?.attributes.Url}`
  })
}
</script>

<template>
  <div class="view-container">
    <breadcrumb></breadcrumb>
    <div class="py-12 sm:py-16">
      <div class="view-container flex flex-col gap-5 md:flex-row md:gap-10">
        <div class="hidden max-w-[280px] md:block md:w-fit">
          <img
            :src="moduleData?.attributes.language?.data.attributes.Thumbnail?.data.attributes.url"
            class="h-full object-contain object-top"
          />
        </div>
        <div class="w-full shrink">
          <h1 class="heading-large mb-2">{{ moduleData?.attributes.Title }}</h1>
          <p class="text-md mb-2">{{ moduleData?.attributes.Description }}</p>
          <div class="mb-2 flex items-center gap-6">
            <level-pill :level="dynamicValues.levelString" class="my-4"></level-pill>
            <span class="rounded-full border px-3 py-1 text-sm"
              >{{ moduleData?.attributes.tutorials?.data.length }} Tutorials</span
            >
          </div>
          <span class="mb-4 block text-sm">Approximate time: {{ dynamicValues.totalTime }}</span>
          <button @click="navigateToFirstTutorial" type="button" class="btn btn-primary w-fit">
            Start Module
          </button>
          <doc-social-sharing class="mt-5" />
        </div>
      </div>
    </div>
    <div class="body-max-width mb-24 mt-12">
      <div class="mb-5 flex flex items-center gap-5">
        <h2 class="heading-medium">Tutorials in this Module</h2>
        <button @click="navigateToFirstTutorial" type="button" class="btn btn-primary w-20">
          Start
        </button>
      </div>
      <TutorialItem
        v-for="(unit, index) in moduleData?.attributes.tutorials?.data"
        :language-url="languageUrl"
        :module-url="moduleUrl"
        :key="unit.id"
        :tutorial="unit"
        :index="index"
      ></TutorialItem>
    </div>
    <div>
      <related-modules-section
        :current-module-url="moduleData?.attributes.Url"
        class="pb-20"
      ></related-modules-section>
    </div>
  </div>
</template>
