declare global {
  interface Window {
    Blockly: any
  }
}
import editorService from '@/services/ide/editor.service'
import loadScriptInBody from '@/services/loadScriptInBody.service'
import { useIdeStore } from '@/stores/ide.store'
import { usePluginStore } from '@/stores/plugin.store'
import { BLOCKLANGUAGELIST, BLOCKLIST, IDECONSTANT } from '@/utils/ide'

type BlockType = {
  kind: string
  type: string
  message0: string
  args0: { type: string; name: string; check?: string }[]
  output?: string
  colour?: number
  tooltip?: string
  previousStatement?: string
  nextStatement?: string
  extensions?: string[]
}

type Category = {
  kind: string
  name: string
  contents: BlockType[]
}

type Toolbox = {
  kind: string
  contents: Category[]
}
/**
 * Resize the blockly workspace
 */
const resizeBlockly = async () => {
  if (!window.Blockly) {
    return
  }
  await new Promise((resolve) => setTimeout(resolve, 100))
  window.Blockly.svgResize(useIdeStore().isBlocklyWorkspace)
}
/**
 * Set the sample script/ reset
 * @param script - The script
 */
const setSampleScript = (script: string) => {
  openBlockly(script)
  onBlocklyUpdate()
}
/**
 *  Get the blockly script
 * @returns The blockly script
 */
const getBlocklyScript = () => {
  const workspace = useIdeStore().isBlocklyWorkspace
  return window.Blockly.serialization.workspaces.save(workspace)
}
/**
 * Open the code with a script
 * @param script - The script
 */
const openBlockly = (script: string) => {
  const workspace = useIdeStore().isBlocklyWorkspace
  window.Blockly.serialization.workspaces.load(JSON.parse(script), workspace, false)
}
/**
 * Update the code editor with the blockly workspace code
 */
const onBlocklyUpdate = () => {
  const versionIndex = useIdeStore().versionIndex
  const workspace = useIdeStore().isBlocklyWorkspace
  const code = useIdeStore().isBlocklyEditorLanguages[versionIndex].workspaceToCode(workspace)
  editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, code)
  useIdeStore().setCodeUpdated(true)
}
/**
 * Change the blockly language
 */
const changeBlocklyLanguage = () => {
  if (!useIdeStore().isBlocklyWorkspace) return
  const versionIndex = useIdeStore().versionIndex
  const aceLanguageCode = BLOCKLANGUAGELIST[versionIndex].isAceLanguageCode
  editorService.codeEditorChangeLanguage(aceLanguageCode as string)
  onBlocklyUpdate()
}
/**
 * Initialize the blockly workspace
 * @param toolbox  - The blockly toolbox
 * @param count - The number of times the function has been called
 * @returns  null if the blockly workspace is not initialized
 */
const initBlockly = async (toolbox: Toolbox, count: number = 0) => {
  if (!window.Blockly) {
    if (count > 10) {
      return null
    } else {
      await new Promise((resolve) => setTimeout(resolve, 600))
      initBlockly(toolbox, count + 1)
    }
  } else {
    const blocklyWorkspace = window.Blockly.inject(IDECONSTANT.BLOCKLY_EDITOR, {
      toolbox: toolbox
    })
    blocklyWorkspace.addChangeListener(onBlocklyUpdate)
    const blocklyEditorLanguages: any[] = BLOCKLANGUAGELIST.map((lang) => {
      return window.Blockly[lang.language]
    })
    useIdeStore().setBlocklyEditorLanguages(blocklyEditorLanguages)
    useIdeStore().setBlocklyWorkspace(blocklyWorkspace)
    if (useIdeStore().isEmbedded || usePluginStore().isPlugin) {
      window.Blockly.serialization.workspaces.load(
        JSON.parse(useIdeStore().project.script || '{}'),
        useIdeStore().isBlocklyWorkspace,
        false
      )
    } else if (useIdeStore().isShared) {
      const sampleScript = useIdeStore().isProject.script
      setSampleScript(sampleScript as string)
    } else {
      const sampleScript = useIdeStore().routeMeta?.sampleScript
      setSampleScript(sampleScript as string)
    }
  }
  useIdeStore().setCodeUpdated(false)
}

/**
 * Get the blockly toolbox
 * @returns The blockly toolbox
 */
const getBlocklyToolbox = (): Toolbox => {
  const toolbox: Toolbox = {
    kind: 'categoryToolbox',
    contents: [] as Category[]
  }

  for (const category in BLOCKLIST.categories) {
    const newCategory: Category = {
      kind: 'category',
      name: BLOCKLIST.categories[category],
      contents: [] as BlockType[]
    }

    for (const blk in BLOCKLIST.blockTypes[category]) {
      newCategory.contents.push({
        kind: 'block',
        type: BLOCKLIST.blockTypes[category][blk]
      } as BlockType)
    }

    toolbox.contents.push(newCategory)
  }
  return toolbox
}
/**
 * Load the blockly scripts
 */
const loadBlockly = () => {
  if (window.Blockly) {
    unloadBlockly()
    if (useIdeStore().isBlocklyWorkspace) {
      useIdeStore().isBlocklyWorkspace.dispose()
      useIdeStore().setBlocklyWorkspace(null)
    }
  }
  loadScriptInBody.loadScriptInBody('/assets/javascript/blockly/blockly_compressed.js').then(() => {
    loadScriptInBody.loadScriptInBody('/assets/javascript/blockly/msg/js/en.js').then(() => {
      loadScriptInBody
        .loadScriptInBody('/assets/javascript/blockly/blocks_compressed.js')
        .then(async () => {
          loadScriptInBody.loadScriptInBody('/assets/javascript/blockly/javascript_compressed.js')
          loadScriptInBody.loadScriptInBody('/assets/javascript/blockly/lua_compressed.js')
          loadScriptInBody.loadScriptInBody('/assets/javascript/blockly/dart_compressed.js')
          loadScriptInBody.loadScriptInBody('/assets/javascript/blockly/php_compressed.js')
          loadScriptInBody.loadScriptInBody('/assets/javascript/blockly/python_compressed.js')
          await new Promise((resolve) => setTimeout(resolve, 500))
          const toolbox = getBlocklyToolbox()
          initBlockly(toolbox)
        })
    })
  })
}
/**
 * Unload the blockly scripts
 */
const unloadBlockly = () => {
  loadScriptInBody.unloadScriptInBody('/assets/javascript/blockly/blockly_compressed.js')
  loadScriptInBody.unloadScriptInBody('/assets/javascript/blockly/msg/js/en.js')
  loadScriptInBody.unloadScriptInBody('/assets/javascript/blockly/blocks_compressed.js')
  loadScriptInBody.unloadScriptInBody('/assets/javascript/blockly/javascript_compressed.js')
  loadScriptInBody.unloadScriptInBody('/assets/javascript/blockly/lua_compressed.js')
  loadScriptInBody.unloadScriptInBody('/assets/javascript/blockly/dart_compressed.js')
  loadScriptInBody.unloadScriptInBody('/assets/javascript/blockly/php_compressed.js')
  loadScriptInBody.unloadScriptInBody('/assets/javascript/blockly/python_compressed.js')
}

export default {
  setSampleScript,
  loadBlockly,
  openBlockly,
  getBlocklyScript,
  changeBlocklyLanguage,
  unloadBlockly,
  resizeBlockly
}
