<script setup lang="ts">
import { onMounted, ref } from 'vue'
import TopModuleCard from '@/components/content-app/tutorials/LanguagePage/TopModuleCard.vue'
import ModuleCard from '@/components/content-app/tutorials/LanguagePage/ModuleCard.vue'
import { useRoute } from 'vue-router'
import type ILanguage from '@/components/content-app/interface/tutorials/ILanguage'
import type ITutorialModule from '@/components/content-app/interface/tutorials/ITutorialModule'
import TutorialsService from '@/services/content-app/tutorials.service'
import Breadcrumb from '@/components/content-app/shared/ContentAppBreadcrumb.vue'

const route = useRoute()
const _tutorialsService = new TutorialsService()
const languageUrl = route.params.language as string

const langData = ref<ILanguage | undefined>(undefined)
const popularModules = ref<ITutorialModule[] | undefined>(undefined)
const otherModules = ref([] as ITutorialModule[] | undefined)

const allLangauges = ref(undefined as ILanguage[] | undefined)

onMounted(async () => {
  //All languages
  const allLangData = await _tutorialsService.getAllLanguages()
  allLangauges.value = allLangData

  //Current Language
  const lData = await _tutorialsService.getOneLanguage(languageUrl)
  langData.value = lData as ILanguage

  if (langData.value) {
    popularModules.value = langData.value?.attributes.modules?.data.filter(
      (module: ITutorialModule) => {
        // return module.metadata.isMostPopular
        return module.attributes.Order < 4
      }
    )

    otherModules.value = langData.value?.attributes.modules?.data.filter(
      (module: ITutorialModule) => {
        // return !module.metadata.isMostPopular
        return module.attributes.Order >= 4
      }
    )
  }
})

/* This is used IF marketing team requests to add TOP vs NORMAL modules */
// const topModules = currentLang.value?.modules?.filter(
//   (module: ITutorialModule) => module.metadata.isMostPopular
// )

// const allOtherModules = currentLang.value?.modules?.filter(
//   (module: ITutorialModule) => !module.metadata.isMostPopular
// )
</script>

<template>
  <div class="view-container">
    <breadcrumb></breadcrumb>
    <div class="pb-10">
      <div class="py-12 sm:py-16">
        <div class="body-max-width flex flex-col gap-6 sm:flex-row sm:gap-10">
          <div class="hidden h-auto max-h-40 sm:block sm:w-1/4">
            <img
              :src="langData?.attributes.Thumbnail?.data.attributes.url"
              class="h-full w-full object-contain"
            />
          </div>
          <div class="w-full sm:w-3/4">
            <h1 class="heading-large mb-2">{{ langData?.attributes.Name }}</h1>
            <p class="text-md mb-5">{{ langData?.attributes.Description }}</p>
            <span class="rounded-full border px-3 py-2 text-sm"
              >{{ langData?.attributes.modules?.data.length }} Modules</span
            >
          </div>
        </div>
      </div>
      <div class="">
        <div class="my-10">
          <h2 class="heading-medium mb-10">Popular Tutorial Modules</h2>
          <div class="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
            <top-module-card
              v-for="tutorialModule in popularModules"
              :key="tutorialModule.id"
              :module="tutorialModule"
            ></top-module-card>
          </div>
        </div>
        <div class="my-10" v-if="otherModules && otherModules?.length > 0">
          <h2 class="heading-medium mb-10 mb-5">All Other Modules</h2>
          <!-- Grid -->
          <div class="grid gap-4 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
            <module-card
              v-for="tutorialModule in otherModules"
              :key="tutorialModule.id"
              :module="tutorialModule"
              :languageUrl="(langData?.attributes.Url as string)"
            ></module-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
