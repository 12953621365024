// import gaService from '@/services/ga.service'
import { useIdeStore } from '@/stores/ide.store'
import axios from 'axios'

export interface IProcessTerminalRequest {
  lang: string | null
  command: string
}
/**
 * Execute the command in terminal
 * @param cmd - The command to be executed
 * @param terminal - The terminal instance
 */
const process = async (cmd: string, terminal: any) => {
  useIdeStore().setCodeUpdated(true)
  // const startTime: number = gaService.getCurrentTime()

  const processTerminalRequest: IProcessTerminalRequest = {
    lang: useIdeStore().isTerminal,
    command: cmd
  }

  await axios
    .post('/api/doodle/executeInTerminal', processTerminalRequest)
    .then((response: { data: { message: string } }) => {
      terminal.echo(response.data.message)
    })
    .catch((error: any) => {
      terminal.error(error)
    })
  // .finally(() => {
  //   gaService.calculateAndSendExecuteEndTime(startTime, useIdeStore().isTerminal, 'execute')
  // })
}
export default { process }
