declare global {
  interface Window {
    pym: any
  }
}

import editorService from '@/services/ide/editor.service'
import blocklyService from '@/services/ide/languages/blockly/blockly.service'
import functionalFeaturesService from '@/services/ide/plugin/functionalFeatures.service'
import loadScriptInBody from '@/services/loadScriptInBody.service'
import { useIdeStore } from '@/stores/ide.store'
import { usePluginStore } from '@/stores/plugin.store'
import { IDECONSTANT } from '@/utils/ide'
import { META } from '@/utils/meta'
import { compact, isNumber } from 'lodash-es'

export interface IShareIndexRequest {
  shareId: string
  isInstant: boolean
}
/**
 * on change output editor
 */
const onChangeOutputEditor = () => {
  if (usePluginStore().isEditable) return
  useIdeStore()
    .outputEditor.getSession()
    .on('change', async () => {
      await new Promise((resolve) => setTimeout(resolve, 100))
      useIdeStore().pymChild.sendHeight()
    })
}
/**
 * On keyup code editor
 */
const onKeyupCodeEditor = () => {
  if (usePluginStore().isEditable) return
  editorService
    .getEditor(IDECONSTANT.CODE_EDITOR)
    .textInput.getElement()
    .addEventListener('keyup', async () => {
      await new Promise((resolve) => setTimeout(resolve, 100))
      useIdeStore().pymChild.sendHeight()
    })
}
/**
 * Set the version index
 */
const setVersionIndex = () => {
  if (usePluginStore().isCusomPlugin && usePluginStore().defautlVersionIndex) {
    useIdeStore().setVersionIndex(usePluginStore().defautlVersionIndex as number)
  } else if (useIdeStore().project.versionIndex || isNumber(useIdeStore().project.versionIndex)) {
    useIdeStore().setVersionIndex(parseInt(useIdeStore().project.versionIndex))
  } else {
    // @ts-ignore-next-line
    const versionIndex = useIdeStore().ideMeta?.versions?.length - 1 || 0
    useIdeStore().setVersionIndex(versionIndex)
  }
}

/**
 * When the language is changed
 */
const onLanguageChange = async () => {
  useIdeStore().setOutputFiles([])
  useIdeStore().setIdeMeta(META[useIdeStore().project.language])
  setVersionIndex()
  if (useIdeStore().project?.libraries)
    useIdeStore().libraries = compact(useIdeStore().project.libraries.split(' '))

  if (useIdeStore().project.language !== 'blockly') {
    editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, useIdeStore().project.script)
    editorService.codeEditorChangeLanguage(useIdeStore().isAceLanguageCode as string)
  } else {
    await blocklyService.loadBlockly()
    await new Promise((resolve) => setTimeout(resolve, 1000))
    useIdeStore().pymChild.sendHeight()
    if (usePluginStore().defaultScript) {
      blocklyService.setSampleScript(JSON.stringify(usePluginStore().defaultScript))
    }
    blocklyService.changeBlocklyLanguage()
  }
}
/**
 * when the plugin is loaded and setScript is called
 * @param data - The data from the setScript call
 * @returns The data
 */
const initCusomPlugin = (data: any) => {
  const pluginResponse = usePluginStore().isPluginResponse
  if (pluginResponse && pluginResponse?.type === 'BASIC') {
    if (!data.language) {
      data.language = usePluginStore().isDefaultLanguageAceCode
    }
  } else {
    const libs = pluginResponse.pluginProjectData?.libraries
    data = {
      ...data,
      ...pluginResponse.pluginProjectData,
      libs: libs
    }
  }
  return data
}
/**
 * when the plugin is loaded set the functional features
 */
const initFunctionalFeatures = () => {
  functionalFeaturesService.initGetCode()
  functionalFeaturesService.initSetCode()
  functionalFeaturesService.initExecute()
  functionalFeaturesService.initGetLanguageList()
  functionalFeaturesService.initSetLanguage()
  functionalFeaturesService.initGetVersionList()
  functionalFeaturesService.initSetVersion()
  functionalFeaturesService.initInteractiveMode()
  functionalFeaturesService.initSetInputArgs()
  functionalFeaturesService.initSetStdinInput()
  functionalFeaturesService.initClearCodeEditor()
  functionalFeaturesService.initClearOutputEditor()
  functionalFeaturesService.initOnCodeEditorUpdate()
  functionalFeaturesService.initOnExecuteComplete()
  functionalFeaturesService.initPostToJDoodle()
  functionalFeaturesService.initStopExecute()
}
/**
 * when the plugin is loaded and setScript is called
 * @param data - The data from the setScript call
 */
const initPluginScripts = async (data: any) => {
  const pluginData: any = usePluginStore().isCusomPlugin ? initCusomPlugin(data) : data
  useIdeStore().setProject(pluginData)
  functionalFeaturesService.initGetFiles(pluginData)

  if (useIdeStore().project.libs) {
    useIdeStore().libraries = useIdeStore().project.libs.split(',')
  }
  setVersionIndex()
  if (!useIdeStore().project.script || useIdeStore().project.script < 1) {
    usePluginStore().setPluginRunDiabled(true)
  }
  onLanguageChange()
  useIdeStore().setCodeUpdated(false)
}
/**
 * Post action for the embed ace
 * @param count The count
 */
const postPluginAceAction = async (count: number = 0) => {
  if (!useIdeStore().isWindowAce || !useIdeStore().codeEditor) {
    if (count > 20) {
      useIdeStore().setShareNotFound(true)
      useIdeStore().setShareNotFoundHttpError('Unable to load the IDE. Please try again later.')
    } else {
      await new Promise((resolve) => setTimeout(resolve, 600))
      postPluginAceAction(count + 1)
    }
  } else {
    useIdeStore().setPymChild(new window.pym.Child())
    initFunctionalFeatures()
    useIdeStore().pymChild.onMessage('setScript', (data: any) => {
      if (usePluginStore().isCusomPlugin) {
        const pymdata = JSON.parse(data)
        const customPluginData = {
          id: usePluginStore().isPymKey,
          script: (usePluginStore().defaultScript as string) || (usePluginStore().script as string),
          hasFiles: pymdata.hasFiles || false,
          files: pymdata.files || null
        }
        initPluginScripts(customPluginData)
      } else {
        data = JSON.parse(data)
        if (data.id === usePluginStore().isPymKey) {
          initPluginScripts(data)
        }
      }
    })
    if (usePluginStore().isCusomPlugin && usePluginStore().isEditable) {
      const data = {
        id: usePluginStore().isPymKey,
        script: (usePluginStore().defaultScript as string) || (usePluginStore().script as string)
      }
      initPluginScripts(data)
    } else {
      useIdeStore().pymChild.sendMessage(
        'getScript',
        JSON.stringify({ id: usePluginStore().isPymKey })
      )
    }
    onKeyupCodeEditor()
    onChangeOutputEditor()
    await new Promise((resolve) => setTimeout(resolve, 100))
    useIdeStore().pymChild.sendHeight()
    useIdeStore().setCodeUpdated(false)
  }
}
/**
 * Post action for the embed pym
 * @param count - The count
 */
const postPluginPymAction = async (count: number = 0) => {
  if (!window.pym) {
    if (count > 20) {
      useIdeStore().setShareNotFound(true)
      useIdeStore().setShareNotFoundHttpError('Unable to load the IDE. Please try again later.')
    } else {
      await new Promise((resolve) => setTimeout(resolve, 600))
      postPluginPymAction(count + 1)
    }
  } else {
    window.pym.Child({ polling: 500 })
    await new Promise((resolve) => setTimeout(resolve, 100))
    postPluginAceAction()
  }
}
/**
 * Initialize the embed plugin
 */
const initEmbedPlugin = async () => {
  const language = usePluginStore().isDefaultLanguageAceCode || 'java'
  useIdeStore().setIdeMeta(META[language])
  await new Promise((resolve) => setTimeout(resolve, 500))
  editorService.splitAndInit()
  await new Promise((resolve) => setTimeout(resolve, 600))
  postPluginPymAction()
}
/**
 * inject the pym script
 */
const initOnRouterChange = () => {
  loadScriptInBody.loadScriptInBody('/assets/jdoodle-pym.min.js')
}
/**
 * Clean the plugin store
 */
const cleanPluginStore = () => {
  usePluginStore().$reset()
}
export default { initOnRouterChange, initEmbedPlugin, onLanguageChange, cleanPluginStore }
