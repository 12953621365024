<script setup lang="ts">
import type ITutorialModule from '@/components/content-app/interface/tutorials/ITutorialModule'
import { type PropType, computed } from 'vue'
import LevelPill from '@/components/content-app/tutorials/LevelPill.vue'

const props = defineProps({
  module: {
    type: Object as PropType<ITutorialModule>,
    required: true
  },
  languageUrl: {
    type: String,
    required: false
  }
})
const languageUrl = computed<string | undefined>(() => {
  return props.languageUrl
})
</script>

<template>
  <!-- Card -->
  <router-link
    :to="{ path: `${languageUrl}/${props.module.attributes.Url}` }"
    class="group col-span-2 flex flex-col rounded-xl border border-text-secondary shadow-sm transition hover:shadow-md"
  >
    <div class="grow p-4 md:p-5">
      <div class="flex h-full grow">
        <div class="h-2/4 w-1/3">
          <img
            :src="props.module.attributes.Thumbnail?.data.attributes.url"
            class="h-full w-full object-cover"
          />
        </div>

        <div class="ml-5 flex h-2/4 h-full w-full flex-col">
          <level-pill :level="props.module.attributes.Level" class="mb-1"></level-pill>
          <h4 class="text-primary mb-2 font-semibold">
            {{ props.module.attributes.Title }}
          </h4>
          <p class="text-secondary grow break-normal text-sm">
            {{ props.module.attributes.Description }}
          </p>
        </div>
      </div>
    </div>
  </router-link>
  <!-- End Card -->
</template>
