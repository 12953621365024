import { useAuthStore } from '@/stores/auth.store'
import { useIdeStore } from '@/stores/ide.store'

import axios from 'axios'

let controller: AbortController | null = null

export interface IGenerateCodeRequest {
  inputs?: string | undefined
  outputs?: string | undefined
  source_language?: string | undefined
  language?: string | undefined
  task: GENERATE_CODE_TASK_TYPE
  // parameters?: { temperature: number; max_new_tokens: number }
}
export enum GENERATE_CODE_TASK_TYPE {
  GENERATE = 'generate',
  COMPLETE = 'complete',
  EXPLAIN = 'explain',
  OPTIMIZE = 'optimize',
  DEBUG = 'debug',
  COMMENT = 'comment',
  TRANSLATE = 'translate',
  CORRECT = 'correct',
  INSTRUCT = 'instruct',
  CHAT = 'chat',
  GENERIC = 'generic',
  IDENTIFY_LANGUAGE = 'identify_language',
  EXPLAIN_ERROR = 'explain_error'
}

/**
 * Wait for the sync to finish
 * @param inputs - code inputs
 * @param output - error output from execution
 * @param source_language - ide source_language
 * @param task TASK TYEP ENUM
 * @returns string output
 */
const executeTaskWithAi = async (
  inputs: string | undefined,
  output: string | undefined,
  source_language: string,
  task: GENERATE_CODE_TASK_TYPE
): Promise<string> => {
  controller = new AbortController()
  useIdeStore().aiIsLoading = true

  const reqObj: IGenerateCodeRequest = {
    inputs: inputs,
    outputs: output,
    task: task,
    source_language: source_language,
    language: source_language
    // parameters: { temperature: 0, max_new_tokens: 1000 }
  }

  return await axios
    .post('/api/compiler-ai/generate-code-with-session', JSON.stringify(reqObj), {
      signal: controller?.signal,
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then((response: { data: any }) => {
      // console.log('*** Generate Explaination - Output', response.data)
      useIdeStore().aiResponse = response.data
      return response.data
    })
    .catch((error: { response: { status: number } }) => {
      if (error?.response?.status === 403) {
        useAuthStore().clearRobotCheck()
        useIdeStore().aiResponse = 'Please login to access JDroid AI features.'
      } else if (error?.response?.status === 429) {
        useIdeStore().aiResponse =
          'You have reached the maximum number of JDroid credits for today. Please upgrade your subscription to get more AI access.'
      }
    })
    .finally(() => {
      useIdeStore().aiIsLoading = false
    })
}
/**
 * Stop the debugging and interrupt the request
 */
const stopDebugging = () => {
  if (controller) {
    controller.abort()
    controller = null
  }
  useIdeStore().aiIsLoading = false
  useIdeStore().setHasErrorAfterExecute(false)
}

export { executeTaskWithAi, stopDebugging }
