<script lang="ts" setup>
import { useAuthStore } from '@/stores/auth.store'
import { MOBILEIDENAVIGATIONIDS } from '@/utils/ide'
import { computed, type PropType } from 'vue'

const props = defineProps({
  currentName: {
    type: String as PropType<string | null>,
    required: false
  },
  widthAndHeightClass: {
    type: String as PropType<string>,
    required: false
  },
  initialFontSize: {
    type: String as PropType<string>,
    required: false
  },
  isAddOrganisationPic: {
    type: Boolean,
    required: false
  },
  isUserProfileImage: {
    type: Boolean,
    required: false,
    default: false
  },
  showPersonalProfilePic: {
    type: Boolean,
    required: false,
    default: false
  }
})
const authStore = useAuthStore()

const profilePictureURL = computed(() => {
  return authStore.profilePictureUrl
})
const initials = computed(() => {
  return (props.currentName as string)?.charAt(0).toUpperCase()
})
const isUserloggedIn = computed(() => {
  return authStore.isUserloggedIn
})
</script>

<template>
  <div>
    <div
      :id="MOBILEIDENAVIGATIONIDS.PROFILEPICID"
      class="flex items-center justify-center rounded-full border-[3px]"
      :class="[
        props.widthAndHeightClass ? props.widthAndHeightClass : 'h-[35px] w-[35px]',
        !isUserloggedIn ? ' border-new-secondary-600' : 'border-new-secondary-200',
        props.isAddOrganisationPic
          ? 'bg-new-secondary-200'
          : 'background-quaternary stroke-primary-default'
      ]"
    >
      <span v-if="!isUserloggedIn" class="flex items-center justify-center text-[48px]">
        <FontAwesomeIcon icon="fa-user" class="color-secondary-700 h-5 w-5" />
      </span>
      <!-- Add Org icon -->
      <span
        :id="MOBILEIDENAVIGATIONIDS.PROFILEPICID"
        v-else-if="props.isAddOrganisationPic"
        class="flex items-center justify-center text-[48px]"
      >
        <FontAwesomeIcon icon="fa-plus" class="color-secondary-700" />
      </span>
      <!-- Org Initial -->
      <div v-else>
        <img
          :id="MOBILEIDENAVIGATIONIDS.PROFILEPICID"
          v-if="profilePictureURL && props.isUserProfileImage && props.showPersonalProfilePic"
          :src="profilePictureURL"
          alt="user profile pic"
          class="rounded-full text-xs"
        />
        <span
          :id="MOBILEIDENAVIGATIONIDS.PROFILEPICID"
          v-else
          class="font-regular"
          :class="props.initialFontSize ? props.initialFontSize : 'text-sm'"
          >{{ initials }}</span
        >
      </div>
    </div>
  </div>
</template>
