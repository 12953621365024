import editorService from '@/services/ide/editor.service'
import blocklyService from '@/services/ide/languages/blockly/blockly.service'
import { useIdeStore, type TExecutionHistory, type THtmlExecutionHistory } from '@/stores/ide.store'
import { IDECONSTANT } from '@/utils/ide'
import { slice } from 'lodash-es'

/**
 * Load the selected execution history to the editor
 * @param project the selected execution history
 */
const loadProject = (project: TExecutionHistory | THtmlExecutionHistory) => {
  useIdeStore().setSelectedExecutionHistory(project)
  if (useIdeStore().isHtml) {
    const htmlProject = useIdeStore().isSelectedExecutionHistory as THtmlExecutionHistory
    const script = htmlProject.htmlCode
    editorService.setEditorSession(IDECONSTANT.EXECUTE_CODE_EDITOR, script)
    const textArea = document.getElementById('executionOutputTextArea') as HTMLTextAreaElement
    textArea.value = script
    const form = document.getElementById('executionOutputForm') as HTMLFormElement
    form.setAttribute('target', 'executionOutput')
    form.submit()
  } else {
    const codeProject = useIdeStore().isSelectedExecutionHistory as TExecutionHistory
    const script = codeProject.script || ''
    const output = codeProject.output || ''
    editorService.setEditorSession(IDECONSTANT.EXECUTE_CODE_EDITOR, script)
    editorService.setEditorSession(IDECONSTANT.EXECUTE_OUTPUT_EDITOR, output)
  }
  useIdeStore().setCodeUpdated(true)
}
/**
 * add the recent execution to the local or session storage
 * @param lastExecution the last execution
 */
const addToHistory = (lastExecution: TExecutionHistory | THtmlExecutionHistory) => {
  let recent = JSON.parse(useIdeStore().isStorage.getItem(IDECONSTANT.STOTAGE_KEY))
  if (!recent) {
    recent = {}
  }

  if (!recent[useIdeStore().isLanguage]) {
    recent[useIdeStore().isLanguage] = []
  }

  recent[useIdeStore().isLanguage].unshift(lastExecution)
  if (recent[useIdeStore().isLanguage].length > 50) {
    recent[useIdeStore().isLanguage] = slice(recent[useIdeStore().isLanguage], 0, 50)
  }
  useIdeStore().isStorage.setItem(IDECONSTANT.STOTAGE_KEY, JSON.stringify(recent))
  loadHistory()
}
/**
 * add the recent execution to the local or session storage
 */
const addToRecentHtml = () => {
  if (useIdeStore().isEmbedded || useIdeStore().isStorage === null) return
  const lastExecution: THtmlExecutionHistory = editorService.htmlGetLastExecution()
  addToHistory(lastExecution)
}
/**
 * add the recent execution to the local or session storage
 */
const addToRecent = () => {
  if (useIdeStore().isEmbedded || useIdeStore().isAdvanced || useIdeStore().isStorage === null)
    return
  let script: string | null = null
  if (useIdeStore().isBlockly) {
    script = JSON.stringify(blocklyService.getBlocklyScript())
  } else {
    script = editorService.getEditorSession(IDECONSTANT.CODE_EDITOR).getValue()
  }

  const lastExecution: TExecutionHistory = {
    script: script as string,
    args: useIdeStore().args,
    stdin: useIdeStore().stdin,
    libs: useIdeStore().libraries,
    output: useIdeStore().outputHistory,
    executedAt: new Date().getTime(),
    versionIndex: useIdeStore().versionIndex
  }
  addToHistory(lastExecution)
}
/**
 * Check if local storage is enabled. if not, use session storage
 */
const checkStorage = () => {
  if (localStorage.getItem(IDECONSTANT.STOTAGE_KEY)) {
    useIdeStore().setIsLocalStoreEnabled(true)
    useIdeStore().setStotage(localStorage)
  } else {
    useIdeStore().setStotage(sessionStorage)
  }
}
/**
 * Load the history from the local or session storage
 */
const loadHistory = () => {
  if (!useIdeStore().isStorage) checkStorage()
  useIdeStore().setSelectedExecutionHistory(null)
  useIdeStore().setExecutionHistory(null)
  const recents = useIdeStore().isStorage?.getItem(IDECONSTANT.STOTAGE_KEY)
  if (recents && recents !== 'null' && JSON.parse(recents)[useIdeStore().isLanguage]) {
    useIdeStore().setExecutionHistory(JSON.parse(recents)[useIdeStore().isLanguage])
  } else {
    useIdeStore().setExecutionHistory(null)
  }
}
/**
 * Initialize the file editor
 */
const initHistoryEditors = async () => {
  if (!useIdeStore().isAdvanced) {
    if (!useIdeStore().executeCodeEditor) {
      useIdeStore().executeCodeEditor = editorService.initAceEditor(IDECONSTANT.EXECUTE_CODE_EDITOR)
      useIdeStore().executeCodeEditor.renderer.setShowGutter(true)
      useIdeStore().executeCodeEditor.setReadOnly(true)
    }
    if (!useIdeStore().executeOutputEditor) {
      useIdeStore().executeOutputEditor = editorService.initAceEditor(
        IDECONSTANT.EXECUTE_OUTPUT_EDITOR
      )
      useIdeStore().executeOutputEditor.renderer.setShowGutter(false)
      useIdeStore().executeOutputEditor.setReadOnly(true)
      useIdeStore().executeOutputEditor.renderer.setPadding(20)
      useIdeStore().executeOutputEditor.renderer.setScrollMargin(20, 20)
      useIdeStore().executeOutputEditor.setDisplayIndentGuides(false)
      useIdeStore().executeOutputEditor.setHighlightActiveLine(false)
    }

    editorService.codeEditorsSetTheme()
  }
}
/**
 * Refresh when model opens
 */
const refresh = () => {
  useIdeStore().setSelectedExecutionHistory(null)
  initHistoryEditors()
  loadHistory()
}

/**
 * Destroy History Execution Editor
 */
const destroyHistoryEditors = () => {
  if (useIdeStore().executeCodeEditor) {
    useIdeStore().executeCodeEditor.destroy()
    useIdeStore().executeCodeEditor = null
  }

  if (useIdeStore().executeOutputEditor) {
    useIdeStore().executeOutputEditor.destroy()
    useIdeStore().executeOutputEditor = null
  }
}
export default {
  refresh,
  loadProject,
  addToRecent,
  addToRecentHtml,
  checkStorage,
  loadHistory,
  initHistoryEditors,
  destroyHistoryEditors
}
