<script setup lang="ts">
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

interface IShareItems {
  icon: string
  link: string
  title: string
  color: string
}

const copiedToClip = ref<boolean>(false)
const showCopiedMessageText = ref<string>('')
const copySuccess = ref<boolean>(false)
const currentUrl = ref<string>(window.location.origin + route.fullPath)
const timeOut = ref<NodeJS.Timeout | number | null>(null)

const shareItems: IShareItems[] = [
  {
    icon: 'fa-brands fa-facebook',
    link: `https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fsharer%2Fsharer.php%3Fu%3D${currentUrl.value}&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=en_GB`,
    title: 'Facebook',
    color: 'text-blue-600'
  },
  {
    icon: 'fa-brands fa-x-twitter',
    link: `https://twitter.com/intent/tweet/?text=Hey,%20found%20this%20guide%20from%20JDoodle%20super%20helpful.%20Sharing%20the%20wealth!&url=${currentUrl.value}`,
    title: 'Twitter',
    color: 'text-blue-400'
  },
  {
    icon: 'fa-envelope',
    link: `mailto:?subject=Hey,%20found%20this%20guide%20from%20JDoodle%20super%20helpful.%20Sharing%20the%20wealth!&body=${currentUrl.value}`,
    title: 'Email',
    color: 'text-gray-600'
  },
  {
    icon: 'fa-brands fa-linkedin',
    link: `https://www.linkedin.com/shareArticle?mini=true&url=${currentUrl.value}&title=Hey,%20found%20this%20guide%20from%20JDoodle%20super%20helpful.%20Sharing%20the%20wealth!&summary=Hey,%20found%20this%20guide%20from%20JDoodle%20super%20helpful.%20Sharing%20the%20wealth!&source=${currentUrl.value}`,
    title: 'LinkedIn',
    color: 'text-blue-600'
  }
]

watch(
  () => route.fullPath,
  () => {
    currentUrl.value = window.location.origin + route.fullPath
  }
)

/**
 * Copy to clipboard
 */
const copyToClipboard = async () => {
  window.navigator.clipboard.writeText(currentUrl.value).then(
    () => {
      showCopiedMessageText.value = 'Copied to clipboard!'
      copySuccess.value = true
      copiedToClip.value = true
    },
    () => {
      showCopiedMessageText.value = 'Copy to clipboard failed!'
      copySuccess.value = false
      copiedToClip.value = true
    }
  )
  if (timeOut.value) clearTimeout(timeOut.value)
  timeOut.value = setTimeout(() => {
    copiedToClip.value = false
    copySuccess.value = false
    showCopiedMessageText.value = ''
  }, 5000)
}
</script>

<template>
  <div class="w-full">
    <!-- Card -->
    <div class="section-secondary rounded-lg px-2 py-1">
      <div class="">
        <div class="flex w-full flex-wrap justify-between">
          <h2 class="mb-2 inline-block flex w-full items-center text-sm md:mb-0 md:w-fit">
            Insightful? Share the wealth with other coders!
          </h2>
          <div class="flex w-auto gap-4">
            <div v-for="(item, index) in shareItems" :key="index" class="flex justify-start gap-5">
              <a
                :href="item.link"
                target="_blank"
                rel="nofollow"
                :aria-label="item.title"
                class="flex w-fit flex-col items-center justify-start gap-2 rounded-full p-1 text-sm hover:text-btn-primary-hover"
              >
                <FontAwesomeIcon
                  :icon="item.icon"
                  :class="[
                    'h-6',
                    'w-fit',
                    'rounded-full',
                    'section-primary',
                    'p-0',
                    {
                      [item.color]: true
                    }
                  ]"
                />
              </a>
            </div>
            <!-- Copy URL here -->
            <div class="relative flex max-w-sm flex-col items-center justify-center">
              <FontAwesomeIcon
                icon="fa-link"
                class="text-primary"
                role="button"
                @click="copyToClipboard"
              />
              <p
                v-if="copiedToClip"
                :class="[
                  'text-[10px]',
                  'max-w-[50px]',
                  '',
                  { success: copySuccess, error: !copySuccess }
                ]"
              >
                {{ showCopiedMessageText }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
