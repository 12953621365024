import editorService from '@/services/ide/editor.service'
import ideService from '@/services/ide/ide.service'
import jdroidService from '@/services/ide/jdroid.service'
import blocklyService from '@/services/ide/languages/blockly/blockly.service'
import liveCodingService from '@/services/ide/liveCoding.service'
import projectTreeService from '@/services/ide/projectTree.service'
import settingService from '@/services/ide/settings/setting.service'
import utilModelsService from '@/services/util.models.service'
import { useAuthStore } from '@/stores/auth.store'
import { useIdeStore, type THtmlExecutionHistory } from '@/stores/ide.store'
import { useJdroidStore } from '@/stores/jdroid.store'
import { useProjectManager } from '@/stores/projectManager.store'
import { ADVANCEDIDETYPE, IDECONSTANT, SYNC_ERROR } from '@/utils/ide'
import { IDEVIEWMODELS } from '@/utils/models'
import { languagesItems, type ILanguage } from '@/utils/sharedData/languages'
import axios from 'axios'
import { cloneDeep, compact, merge, remove } from 'lodash-es'

export interface IDubplicateProjectNameRequest {
  filename: string
  isMultiFile?: boolean
  lang: string
}
export interface IModelProject {
  id: string
  name: string
  language: string
}
export interface IAutoSaveRequest {
  script: string | null
  libs: string | null
  versionIndex: number
  projectKey?: string | boolean | number
  isMultiFile?: boolean
  filename: string
  id: number
  lang: string
}
export interface IsaveProjectActualRequest extends Partial<IAutoSaveRequest> {
  filename?: string
  id?: number
  lang?: string
  chatId?: string | null
}
export interface IProject {
  id?: string
  multipleFile?: boolean
  language?: string | null
  readOnly?: boolean
  isOwner?: boolean
}
export interface ILoadProjectsRequest {
  id?: string
  lang?: string
  language?: string
  isMultiFile: boolean | null
  createdAtFrom?: string | null
  updatedAtFrom?: string | null
  createdAtTo?: string | null
  updatedAtTo?: string | null
  name?: string | null
  email?: string | null
}
export interface IDeleteProjectsRequest {
  id?: string
  lang: string
  isMultiFile: boolean
}

export interface IInviteCollaborationUsers {
  email: string
  readOnly?: boolean
  projectId: string
  language: string
  projectName?: string
  id?: string
}

export interface IHttpStatus {
  status: String
  message: String
}

export enum GENERIC_TYPE {
  ADD = 'add',
  FETCH = 'fetch',
  DELETE = 'delete',
  ERROR = 'error',
  SUCCESS = 'success'
}

/**
 * Check for duplicate project name
 * @param projectName - The project name
 * @returns The project response object
 */
const checkForDubplicateProjectName = async (projectName: string) => {
  const requestData: IDubplicateProjectNameRequest = {
    filename: projectName,
    isMultiFile: useIdeStore().isAdvanced,
    lang: useIdeStore().isLanguage
  }
  return await axios
    .post('/api/doodle/checkFileName', requestData)
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}
/**
 * Get the sanitized script
 * @param root - the root
 */
const getSanitizedScriptIt = (root: any) => {
  if (root.content) {
    root.content = null
  }

  if (root.codeChanged) {
    root.codeChanged = false
  }

  if (root.children) {
    for (const child of root.children) {
      getSanitizedScriptIt(child)
    }
  }
}
/**
 * Save the project to the server
 * @param requestData - The request data
 * @param isSaveAs - Is save as
 * @returns The project response object
 */
const saveLogic = async (requestData: IsaveProjectActualRequest) => {
  requestData.chatId = useJdroidStore().chatID
  return await axios
    .post('/api/doodle/save', requestData)
    .then(async (response: { data: { project: any }; status: number }) => {
      // logic is for both save and also save as
      let newProject = null
      if (useIdeStore().isAdvanced) {
        newProject = merge(useIdeStore().isProject, response?.data?.project)
        useIdeStore().setProject(null)
        newProject.isOwner = true
        useIdeStore().setProject(newProject)
      } else {
        newProject = response?.data?.project
        newProject.isOwner = true
        useIdeStore().setProject(newProject)
      }
      useProjectManager().setProjectPermissionWithId(newProject)

      await loadProjects()
      useIdeStore().setCodeUpdated(false)

      if (useIdeStore().isLiveCodingActive) liveCodingService.closeSharedFile(requestData)
      return response
    })
    .catch((error) => {
      throw error
    })
}
/**
 * Wait for the sync to finish
 * @param requestData - the request data
 * @param isSaveAs - Is save as
 * @param count - the count
 * @returns The project response object
 */
const multifileWaitForSync = async (
  requestData: IsaveProjectActualRequest,
  isSaveAs: boolean = false,
  count: number = 0
) => {
  if (projectTreeService.isSyncSuccess() === false) {
    if (count > 9) {
      throw new Error(SYNC_ERROR)
    } else {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      await multifileWaitForSync(requestData, isSaveAs, count + 1)
    }
  } else {
    return await saveLogic(requestData)
  }
}
/**
 * Save the project to the server
 * @param isSaveAs - Is save as
 * @param projectName - The project name
 * @returns The project response object
 */
const saveProjectActual = async (isSaveAs: boolean = false, projectName: string = '') => {
  let requestData: IsaveProjectActualRequest = {} as IsaveProjectActualRequest
  const libs = useIdeStore().libraries ? useIdeStore().libraries.join(' ') : ''
  if (useIdeStore().isLanguage === 'blockly') {
    requestData = {
      script: JSON.stringify(blocklyService.getBlocklyScript()),
      versionIndex: useIdeStore().versionIndex,
      isMultiFile: false
    }
  } else if (useIdeStore().isAdvanced) {
    const root = cloneDeep(useIdeStore().project)
    getSanitizedScriptIt(root.treeData)
    const script = JSON.stringify({ home: root.home, treeData: root.treeData })
    requestData = {
      script: script,
      libs: libs,
      versionIndex: useIdeStore().versionIndex,
      projectKey: useIdeStore().projectKey,
      isMultiFile: true
    }
  } else {
    requestData = {
      script: editorService.getEditorSession(IDECONSTANT.CODE_EDITOR).getValue(),
      libs: libs,
      versionIndex: useIdeStore().versionIndex,
      isMultiFile: false
    }
  }
  if (isSaveAs || !useIdeStore().isProject || !useIdeStore().isProjectId) {
    requestData.filename = projectName
    requestData.id = -1
  } else {
    requestData.filename = useIdeStore().isProject.name
    requestData.id = useIdeStore().isProjectId
  }
  requestData.lang = useIdeStore().isLanguage

  if (useIdeStore().isAdvanced) {
    await projectTreeService.syncBeforeExecute()
    return multifileWaitForSync(requestData, isSaveAs)
  } else {
    return await saveLogic(requestData)
  }
}
/**
 * Auto save the code
 */
const autoSave = async () => {
  if (useIdeStore().isAutoSaveOn && useIdeStore().isProject && useIdeStore().isProjectId) {
    let requestData: IAutoSaveRequest | IsaveProjectActualRequest | null = null
    const libs = useIdeStore().libraries ? useIdeStore().libraries.join(' ') : ''

    if (useIdeStore().isAdvanced) {
      const root = cloneDeep(useIdeStore().project)
      getSanitizedScriptIt(root.treeData)
      const script = JSON.stringify({ home: root.home, treeData: root.treeData })
      const libs = useIdeStore().libraries ? useIdeStore().libraries.join(' ') : ''
      requestData = {
        script: script,
        libs: libs,
        versionIndex: useIdeStore().versionIndex,
        projectKey: useIdeStore().projectKey,
        isMultiFile: true,
        filename: useIdeStore().project.name,
        id: useIdeStore().isProjectId,
        lang: useIdeStore().isLanguage
      }
    } else {
      requestData = {
        script: editorService.getEditorSession(IDECONSTANT.CODE_EDITOR).getValue(),
        libs: libs,
        versionIndex: useIdeStore().versionIndex,
        isMultiFile: false,
        filename: useIdeStore().project.name,
        id: useIdeStore().isProjectId,
        lang: useIdeStore().isLanguage
      }
    }

    await axios.post('/api/doodle/save', requestData)
    useIdeStore().setCodeUpdated(false)
  }
}
/**
 * Clear the current project
 */
const clearProject = () => {
  useIdeStore().setProject(null)
  editorService.resetCodeEditor()
  if (useIdeStore().isAdvanced) {
    ideService.initAdvancedIde(ADVANCEDIDETYPE.CLEAR)
  }
}
/**
 * Delete the project from the server
 * @returns The project response object
 */
const deleteProject = async () => {
  if (!useIdeStore().isSelectedProject) return

  const deleteProjectsRequest: IDeleteProjectsRequest = {
    id: useIdeStore().isSelectedProject.id,
    lang: useIdeStore().isLanguage,
    isMultiFile: useIdeStore().isAdvanced
  }

  return await axios
    .post('/api/doodle/deletefile', deleteProjectsRequest)
    .then(() => {
      if (useIdeStore().isProjectId === useIdeStore().isSelectedProject.id) {
        clearProject()
      }
      remove(useIdeStore().isProjects, { id: useIdeStore().isSelectedProject.id })
      if (!useIdeStore().isAdvanced) {
        editorService.setEditorSession(IDECONSTANT.PROJECT_EDITOR, '')
      }
      useIdeStore().setSelectedProject(null)
    })
    .catch((error) => {
      throw error
    })
}
/**
 * Mark the children of a node as yet to sync
 * So that the children can be synced with the server
 * @param root - The root node
 */
const markChildrenYetToSync = (root: any) => {
  for (const node of root.children) {
    if (!node.children) {
      node.yetToSync = true
    } else {
      markChildrenYetToSync(node)
    }
  }
}

/**
 * Open the project in the IDE
 * @param data - The project data
 * @param awaitsOpenFlag - The flag to wait for the project to open
 * @param count - The count
 * @returns The project response object
 */
const openProject = async (data: any, awaitsOpenFlag: boolean = false, count: number = 0) => {
  if (awaitsOpenFlag && useProjectManager().isAwaitsOpenFlag) {
    if (count > 10) {
      return null
    } else {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      openProject(data, awaitsOpenFlag, count + 1)
    }
  } else {
    useIdeStore().setProject(null)
    if (awaitsOpenFlag) await new Promise((resolve) => setTimeout(resolve, 500))
    editorService.resetCodeEditor()
    if (useIdeStore().isAdvanced) {
      data.treeData = data.script.treeData
      data.home = data.script.home
      data.script = null
    }
    useIdeStore().setProject(cloneDeep(data))
    if (useIdeStore().isProject.libraries) {
      useIdeStore().libraries = useIdeStore().isProject.libraries
    }
    if (useIdeStore().isAdvanced) {
      ideService.initAdvancedIde(ADVANCEDIDETYPE.OPEN)
      useIdeStore().setActiveItem()
      for (const node of useIdeStore().isProject.treeData.children) {
        if (!node.children) {
          if ('/' + node.name !== useIdeStore().isProject.home) {
            node.yetToSync = true
          }
        } else {
          markChildrenYetToSync(node)
        }
      }
    } else {
      if (useIdeStore().isBlockly) {
        blocklyService.openBlockly(data.script)
      } else {
        if (useIdeStore().isBlockly) {
          blocklyService.openBlockly(data.script)
        } else {
          if (liveCodingService.isLiveCodingNoActive()) {
            editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, data?.script)
          }
        }
      }
    }
    useIdeStore().setVersionIndex(data.versionIndex)
    if (useIdeStore().isBlockly) blocklyService.changeBlocklyLanguage()

    useIdeStore().setCodeUpdated(false)
    useProjectManager().setIsAwaitsOpenFlag(false)
    const chatId =
      useProjectManager().isSelectedProject?.chat?.id ||
      useIdeStore().isSelectedProject?.chat?.id ||
      data?.chat?.id ||
      null
    if (!useIdeStore().isHtml && chatId) {
      jdroidService.clearChat()
      await jdroidService.getChatList(chatId)
    }
  }
}
/**
 * Fetch the project from the server
 * @param project The project to fetch
 * @param lang - selected langugae
 * @returns The project response object
 */
const loadProject = async (project: IProject, lang: string | null = null) => {
  if (useIdeStore().isSelectedProject && project.id === useIdeStore().isSelectedProject.id) {
    return
  }

  useIdeStore().isDefaultIde && useProjectManager().setSelectedProject(null)
  useIdeStore().setSelectedProject(null)
  const isAdvanced = project.multipleFile ? true : false

  if (!isAdvanced) await initProjectEditor()

  if (!isAdvanced) {
    editorService.setEditorSession(IDECONSTANT.PROJECT_EDITOR, '')
  }
  const loadProjectRequest: ILoadProjectsRequest = {
    id: project.id,
    lang: lang ? lang : useIdeStore().isHtml ? 'html' : useIdeStore().isLanguage,
    isMultiFile: isAdvanced
  }
  return await axios
    .post('/api/doodle/file', loadProjectRequest)
    .then((response: { data: any }) => {
      const data = response.data
      if (response.data.project.libraries) {
        data.project.libraries = compact(data.project.libraries.split(' '))
      }

      if (isAdvanced) {
        data.project.script = JSON.parse(data.project.script)
      }

      useIdeStore().setSelectedProject(data.project)
      useIdeStore().isDefaultIde && useProjectManager().setSelectedProject(data.project)

      let script = ''
      if (!isAdvanced) {
        script = useIdeStore().isDefaultIde
          ? useProjectManager().isSelectedProject.script
          : useIdeStore().isSelectedProject.script
      }

      if (!isAdvanced) {
        editorService.setEditorSession(IDECONSTANT.PROJECT_EDITOR, script)
      }
    })
    .catch((error) => {
      throw error
    })
}
/**
 * Fetch the projects from the server
 * @param lang - selected langugae
 * @param showAll - show all project
 * @returns The projects response object
 */
const loadProjects = async (lang: string | null = null, showAll: boolean = false) => {
  useIdeStore().setSelectedProject(null)
  if (!useAuthStore().isUserloggedIn) return

  const loadProjectsRequest: ILoadProjectsRequest = {
    lang: lang ? lang : useIdeStore().isLanguage,
    isMultiFile: showAll ? null : useIdeStore().isAdvanced
  }
  return await axios
    .post('/api/doodle/myfiles', loadProjectsRequest)
    .then((response: { data: { files: any[] } }) => {
      useIdeStore().setProjects(response.data.files)
      response?.data?.files.forEach((file: IProject) => {
        file.isOwner = true
      })

      return response
    })
    .catch((error) => {
      throw error
    })
}

/**
 * wait for app initiated because we want to check the environment
 * @param count count
 * @returns app initiated or not
 */
const postElement = async (count: number = 0): Promise<boolean> => {
  const element = document.getElementById(IDECONSTANT.PROJECT_EDITOR) || null
  if (!element) {
    if (count < 200) {
      await new Promise((resolve) => setTimeout(resolve, 100))
      return postElement(count + 1)
    } else {
      return false
    }
  } else {
    return true
  }
}

/**
 * Initialize the file editor
 */
const initProjectEditor = async () => {
  await destroyProjectEditor()
  await postElement().then(() => {
    useIdeStore().projectEditor = editorService.initAceEditor(IDECONSTANT.PROJECT_EDITOR)

    useIdeStore().projectEditor.renderer.setShowGutter(true)
    useIdeStore().projectEditor.setReadOnly(true)
    if (useIdeStore().isDefaultIde) {
      useIdeStore().projectEditor.setTheme('ace/theme/xcode')
    } else editorService.codeEditorsSetTheme()
  })
}

/**
 * Destroys Output editor if exists
 */
const destroyProjectEditor = () => {
  if (useIdeStore().projectEditor) {
    useIdeStore().projectEditor.destroy()
    useIdeStore().projectEditor = null
  }
}

/**
 * Refresh when model opens
 */
const refresh = () => {
  useIdeStore().setSelectedProject(null)
  initProjectEditor()
}
/**
 * open a project when router changes
 * @param count - the count
 * @returns The project response object
 */
const initOnRouterChange = async (count: number = 0) => {
  if (!useIdeStore().openProjectID) return
  if (!window['ace'] && useIdeStore().isBlockly && !window['Blockly']) {
    if (count > 30) {
      useIdeStore().setOpenProjectID(null)
      return null
    } else {
      await new Promise((resolve) => setTimeout(resolve, 100))
      initOnRouterChange(count + 1)
    }
  } else {
    const project: IProject = {
      id: useIdeStore().openProjectID as string
    }
    await loadProject(project).then(async () => {
      await openProject(useIdeStore().isSelectedProject)
      useIdeStore().setOpenProjectID(null)
    })
  }
}
/**
 * Fetch the projects from the server
 * @param loadProjectsRequest - selected langugae
 * @returns The projects response object
 */
const loadProjectsForProjectManager = async (loadProjectsRequest: Object) => {
  useIdeStore().setSelectedProject(null)
  useProjectManager().setSelectedProject(null)
  if (!useAuthStore().isUserloggedIn) return

  return await axios
    .post('/api/doodle/searchFiles', loadProjectsRequest)
    .then((response: { data: any[] }) => {
      useIdeStore().setProjects(response.data)
      return response
    })
    .catch((error) => {
      throw error
    })
}

/**
 * @description post project load
 * @param count number
 */
const postProjectLoad = async (count: number = 0) => {
  if (count > 10) return
  if (!useIdeStore().isProject && useIdeStore().isAdvanced) {
    await new Promise((resolve) => setTimeout(resolve, 1000))
    postProjectLoad(count + 1)
  } else {
    useProjectManager().setIsAwaitsOpenFlag(false)
  }
}

/**
 *  @description -  Closing the project manager modal and opening the Project modal
 */
const toggledModal = () => {
  const defaultLanguage =
    useIdeStore().isProject?.language &&
    languagesItems.find(
      (language: ILanguage) => language.language === useIdeStore().isProject.language
    )

  if (defaultLanguage) {
    useJdroidStore().setStartCodingLanguage(defaultLanguage)
  } else utilModelsService.openModal(IDEVIEWMODELS.STARTCODING)
  settingService.closePopup(IDEVIEWMODELS.MYPROJECTS)
}

/**
 * @description - getting list of collaborator
 * @param projectValue - gets the selected project data for collab list
 * @returns - returns the list of collaborator added for respective project
 */
const listOfUsers = async (projectValue: IInviteCollaborationUsers) => {
  const paramProjectId = projectValue.id ? projectValue.id : useIdeStore().isProjectId
  const paramProjectLang = projectValue.language ? projectValue.language : useIdeStore().isLanguage
  return await axios
    .get(
      `/api/collaboration/findNotInvitedMembers/project/${paramProjectId}/lang/${paramProjectLang} `
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}
/**
 * @param projectValue - gets the selected project data for collab list
 * @returns - returns the list of collaborator added for respective project
 * @description - getting the collaborator for respective project
 */
const listOfCollaborator = async (projectValue: IProject) => {
  const paramProjectId = projectValue.id ? projectValue.id : useIdeStore().isProjectId
  const paramProjectLang = projectValue.language ? projectValue.language : useIdeStore().isLanguage
  return await axios
    .get(`/api/collaboration/list/project/${paramProjectId}/lang/${paramProjectLang}`)
    .then((response) => {
      useProjectManager().setCollaboratorList(response.data)
      return response
    })
    .catch((error) => {
      throw error
    })
}

/**
 * @param inviteCollabReqBody - request body for adding the collaborator
 * @returns - return the added object as response
 * @description - adding the collaborator for respective project
 */
const addCollaboratorInProject = async (inviteCollabReqBody: IInviteCollaborationUsers) => {
  return await axios
    .post('/api/collaboration/invite', inviteCollabReqBody)
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

/**
 * @param deleteCollabReqBody - request body for deleting the collaborator
 * @returns - return the deleted object as response
 * @description - deleting the collaborator for respective project
 */
const deleteCollaboratorInProject = async (deleteCollabReqBody: any) => {
  return await axios
    .delete('/api/collaboration/uninvite', { data: deleteCollabReqBody })
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

export default {
  checkForDubplicateProjectName,
  saveProjectActual,
  autoSave,
  clearProject,
  refresh,
  deleteProject,
  openProject,
  loadProject,
  loadProjects,
  destroyProjectEditor,
  initProjectEditor,
  initOnRouterChange,
  markChildrenYetToSync,
  loadProjectsForProjectManager,
  postProjectLoad,
  toggledModal,
  listOfCollaborator,
  addCollaboratorInProject,
  deleteCollaboratorInProject,
  listOfUsers
}
