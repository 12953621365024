import authService from '@/services/auth.service'
import { useAuthStore } from '@/stores/auth.store'
import { MetaTagName } from '@/utils/meta'
import { type RouteRecordNormalized } from 'vue-router'

/**
 * Remove tags
 * @param tagName og tag name to remove
 */
function removeExistingOgTags(tagName: string) {
  const existingOgTag = document.querySelector(`meta[property="og:${tagName}"]`)
  if (existingOgTag) {
    existingOgTag.remove()
  }
}

/**
 * @param router - get the router to render
 * @param router.beforeEach - get the router
 * @description This function is responsible for setting the page title and meta tags and other routing logic
 */
const routerLogic = (router: {
  beforeEach: (arg0: {
    (to: any, from: any, next: any): any
    (to: any, from: any, next: any): Promise<void>
  }) => void
}) => {
  router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched
      .slice()
      .reverse()
      .find((r: { meta: { title: any } }) => r.meta && r.meta.title)

    // Find the nearest route element with meta tags.
    const nearestWithMeta: RouteRecordNormalized | undefined = to.matched
      .slice()
      .reverse()
      .find((r: { meta: { metaTags: any } }) => r.meta && r.meta.metaTags)
    // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    const isAdvanced = !!nearestWithTitle?.meta?.isAdvanced

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle)
      document.title = !isAdvanced
        ? (nearestWithTitle.meta.title as string)
        : (nearestWithTitle.meta?.advanceTitle as string) ?? (nearestWithTitle.meta.title as string)

    // Remove any stale meta tags from the document using the key attribute we set below.
    const elements: NodeListOf<HTMLElement> = document.querySelectorAll(
      '[data-vue-router-controlled]'
    )
    Array.from(elements).map((el) => {
      const node: any = el.parentNode
      node.removeChild(el)
    })

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next()

    let metaTags = [...(nearestWithMeta.meta.metaTags as any[])]

    if (isAdvanced) {
      metaTags = metaTags
        .filter((tag) => {
          const hasAdvanceDescription = metaTags.some(
            (t) => t.name === MetaTagName.AdvanceDescription
          )
          if (tag.name === MetaTagName.Description && !hasAdvanceDescription) {
            return true
          }

          return tag.name !== MetaTagName.Description
        })
        .map((tag) => {
          if (tag.name === MetaTagName.AdvanceDescription) {
            return { ...tag, name: MetaTagName.Description }
          }
          return tag
        })
    } else {
      metaTags = metaTags.filter((tag) => tag.name !== MetaTagName.AdvanceDescription)
    }
    // TODO:dhruv remove console log after testing
    // console.log({
    //   [!isAdvanced || !nearestWithTitle?.meta?.advanceTitle ? 'title' : 'advanceTitle']:
    //     nearestWithTitle
    //       ? !isAdvanced
    //         ? (nearestWithTitle.meta.title as string)
    //         : (nearestWithTitle.meta?.advanceTitle as string) ??
    //           (nearestWithTitle.meta.title as string)
    //       : null,
    //   [!isAdvanced ||
    //   !(nearestWithMeta?.meta?.metaTags as any[])?.some(
    //     (tag: any) => tag.name === MetaTagName.AdvanceDescription
    //   )
    //     ? 'metaTags'
    //     : 'advanceMetaTags']: metaTags
    // })

    metaTags
      .map((tagDef: any[]) => {
        const tag = document.createElement('meta')

        Object.keys(tagDef).forEach((key: any) => {
          tag.setAttribute(key, tagDef[key])
        })

        // We use this to track which meta tags we create, so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '')
        return tag
      })
      // Add the meta tags to the document head.
      .forEach((tag: any) => document.head.appendChild(tag))

    const ogTags = [
      {
        name: 'title',
        content: document.title
      },
      ...metaTags
    ]

    ogTags.forEach((tagDef: any) => {
      if (tagDef.name === 'description' || tagDef.name === 'title') {
        removeExistingOgTags(tagDef.name)
        const ogTag = document.createElement('meta')
        ogTag.setAttribute('property', `og:${tagDef.name}`)
        ogTag.setAttribute('content', tagDef.content)
        ogTag.setAttribute('data-vue-router-controlled', '')
        document.head.appendChild(ogTag)
      }
    })

    next()
  })
}

/**
 * @param router - get the router to render
 * @param router.beforeEach - get the router
 * @description This function is responsible for setting the page title and meta tags and price routing logic
 */
const pricingRouterLogic = (router: {
  beforeEach: (arg0: (to: any, from: any, next: any) => Promise<void>) => void
}) => {
  router.beforeEach(async (to, from, next) => {
    if (to.name == 'payment') {
      await authService.initAuth().then(() => {
        if (useAuthStore().isUserloggedIn) return next()
        else next({ name: 'pricing' })
      })
    } else {
      next()
    }
  })
}

export default { routerLogic, pricingRouterLogic }
